import { useIsFocused, useNavigation } from '@react-navigation/native';
import {
  Text,
  useStyleSheet,
  StyleService,
  Button,
  Spinner,
  CheckBox,
} from '@ui-kitten/components';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, Platform } from 'react-native';

import AddressInputs from '../../components/Address/AddressInputs';
import ContainerScrollView from '../../components/Common/ContainerScrollView';
import LabeledInput from '../../components/Common/LabeledInput';
import { useAutoScroll } from '../../hooks';
import { AppStackNavigation } from '../../navigation';
import { Props } from '../../types/ConfirmInfo';
import {
  onGoToConsumerDeposit,
  onGoToEsignConsent,
  onGoToDebitCardholder,
  onGoToRewards,
  onGoToSweepstakes,
} from '../../utils/externalLink';
import ErrorMessage from '../Common/ErrorMessage';

const LoadingIndicator = (_props: any) => (
  <Spinner status="basic" size="small" />
);

const ConfirmInfo: React.FC<Props> = ({
  onNext,
  onPersonalInfoEdit,
  onContactInfoEdit,
  personalInfo,
  contactInfo,
  loading,
  errorMessage = '',
  isShippingChanged,
  selectedIndex,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const [check, setCheck] = useState(false);
  const { scrollRef, scrollToTop } = useAutoScroll();
  const isFocused = useIsFocused();
  const navigation = useNavigation<AppStackNavigation>();

  useEffect(() => {
    if (errorMessage && errorMessage !== '' && isFocused) {
      scrollToTop();
    }
  }, [errorMessage, isFocused]);

  const renderCallingCode = () => (
    <View style={styles.callingCodeContainer}>
      <Text style={styles.callingCode}>{`+${contactInfo.countryCode}`}</Text>
    </View>
  );

  const formatNumber = (number: string) => {
    number = number.replace(/[^\d]/g, '');
    if (number) {
      const length = number.length;
      if (length >= 4 && length < 7) {
        number = `${number.slice(0, 3)}-${number.slice(3)}`;
      } else if (length >= 7) {
        number = `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(
          6,
          10,
        )}`;
      }
    }
    return number;
  };

  if (selectedIndex !== 4) {
    return <View />;
  }

  return (
    <ContainerScrollView ref={scrollRef} testID="ConfirmInfoScrollView">
      <View style={styles.headerContainer}>
        <Text style={styles.header}>
          {t<string>('card_application.confirm_information.title')}
        </Text>
        <Text style={styles.headerSub}>
          {t<string>('card_application.confirm_information.subtitle')}
        </Text>
      </View>
      <ErrorMessage errorMessage={errorMessage} />
      <View style={styles.form}>
        <View style={styles.sectionHeaderRow}>
          <Text style={styles.sectionHeader}>
            {t<string>('card_application.confirm_information.second_title')}
          </Text>
          <TouchableOpacity
            onPress={onPersonalInfoEdit}
            testID="ConfirmInfoEditPersonalInfoButton"
          >
            <Text style={styles.sectionEdit}>
              {t<string>('card_application.confirm_information.edit')}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.row}>
          <View style={styles.columnFirstName}>
            <LabeledInput
              label={t<string>('card_application.personal_information.name')}
              value={personalInfo.firstName}
              disabled
            />
          </View>
          <View style={styles.columnLastName}>
            <LabeledInput
              label={t<string>(
                'card_application.personal_information.last_name',
              )}
              value={personalInfo.lastName}
              disabled
            />
          </View>
        </View>
        <LabeledInput
          label={t<string>('card_application.personal_information.birth')}
          value={format(personalInfo.birthday, 'MM/dd/yyyy')}
          disabled
        />
        <LabeledInput
          label={t<string>('card_application.personal_information.ssn')}
          value={`•••-••-${personalInfo.securityNumber.substring(
            personalInfo.securityNumber.length - 4,
            personalInfo.securityNumber.length,
          )}`}
          disabled
        />
        <LabeledInput
          label={t<string>('card_application.personal_information.country')}
          value="United States"
          disabled
        />
      </View>
      <View style={styles.form}>
        <View style={styles.sectionHeaderRow}>
          <Text style={styles.sectionHeader}>
            {t<string>('card_application.confirm_information.third_title')}
          </Text>
          <TouchableOpacity
            onPress={onContactInfoEdit}
            testID="ConfirmInfoEditContactInfoButton"
          >
            <Text style={styles.sectionEdit}>
              {t<string>('card_application.confirm_information.edit')}
            </Text>
          </TouchableOpacity>
        </View>
        <AddressInputs
          street1={contactInfo.street}
          street2={contactInfo.street2}
          city={contactInfo.city}
          state={contactInfo.state}
          postalCode={contactInfo.postalCode}
          ugamiCardApp
          verifyForm
          setState={() => {}}
          setCity={() => {}}
          setStreet1={() => {}}
          setStreet2={() => {}}
          setPostalCode={() => {}}
          setPostalCodeError={(_: string) => {}}
        />
        <LabeledInput label="Email" value={contactInfo.email} disabled />
        <LabeledInput
          label={t<string>('card_application.personal_information.phone')}
          value={formatNumber(contactInfo.phoneNumber)}
          accessoryLeft={renderCallingCode}
          disabled
        />
      </View>
      {isShippingChanged && contactInfo.shippingAddress && (
        <View style={styles.form}>
          <View style={styles.sectionHeaderRow}>
            <Text style={styles.sectionHeader}>
              {t<string>(
                'card_application.contact_information.card_shipping_address',
              )}
            </Text>
            <TouchableOpacity
              onPress={onContactInfoEdit}
              testID="ConfirmInfoEditCardShippingButton"
            >
              <Text style={styles.sectionEdit}>
                {t<string>('card_application.confirm_information.edit')}
              </Text>
            </TouchableOpacity>
          </View>
          <AddressInputs
            street1={contactInfo.shippingAddress.street}
            street2={contactInfo.shippingAddress.street2}
            city={contactInfo.shippingAddress.city}
            state={contactInfo.shippingAddress.state}
            postalCode={contactInfo.shippingAddress.postalCode}
            ugamiCardApp
            verifyForm
            setState={() => {}}
            setCity={() => {}}
            setStreet1={() => {}}
            setStreet2={() => {}}
            setPostalCode={() => {}}
            setPostalCodeError={(_: string) => {}}
          />
        </View>
      )}
      <View style={styles.disclaimerContainer}>
        <CheckBox
          status="basic"
          checked={check}
          onChange={(nextChecked) => setCheck(nextChecked)}
        />
        <View style={styles.textWithLink}>
          <Text style={styles.disclaimer}>
            {t<string>('card_application.confirm_information.agreement.p1')}
            <TouchableOpacity
              style={[Platform.OS === 'android' ? { height: 15 } : {}]}
              onPress={onGoToEsignConsent}
            >
              <Text style={styles.disclaimerRed}>
                {t<string>(
                  'card_application.confirm_information.agreement.disclosure',
                )}
              </Text>
            </TouchableOpacity>
            ,{' '}
            <TouchableOpacity
              style={[Platform.OS === 'android' ? { height: 15 } : {}]}
              onPress={onGoToConsumerDeposit}
            >
              <Text style={styles.disclaimerRed}>
                {t<string>(
                  'card_application.confirm_information.agreement.consumer',
                )}
              </Text>
            </TouchableOpacity>
            {', '}
            <TouchableOpacity
              style={[Platform.OS === 'android' ? { height: 15 } : {}]}
              onPress={onGoToDebitCardholder}
            >
              <Text style={styles.disclaimerRed}>
                {t<string>(
                  'card_application.confirm_information.agreement.cardholder',
                )}
              </Text>
            </TouchableOpacity>
            ,{' '}
            <TouchableOpacity
              style={[Platform.OS === 'android' ? { height: 15 } : {}]}
              onPress={onGoToRewards}
            >
              <Text style={styles.disclaimerRed}>
                {t<string>(
                  'card_application.confirm_information.agreement.rewards',
                )}
              </Text>
            </TouchableOpacity>
            {t<string>('card_application.confirm_information.agreement.p2')}
            <TouchableOpacity
              style={[Platform.OS === 'android' ? { height: 15 } : {}]}
              onPress={onGoToSweepstakes}
            >
              <Text style={styles.disclaimerRed}>
                {t<string>(
                  'card_application.confirm_information.agreement.sweepstakes',
                )}
              </Text>
            </TouchableOpacity>{' '}
            {t<string>('card_application.confirm_information.agreement.p3')}
          </Text>
        </View>
      </View>
      <Button
        size="large"
        style={styles.submitButton}
        onPress={onNext}
        disabled={loading || !check}
        accessoryLeft={loading ? LoadingIndicator : undefined}
        testID="ConfirmInfoSubmitButton"
      >
        {t<string>('card_application.confirm_information.submit_button')}
      </Button>
    </ContainerScrollView>
  );
};

const themedStyles = StyleService.create({
  inputAccessory: {
    marginRight: 8,
  },
  button: {
    alignSelf: 'stretch',
  },
  row: {
    flexDirection: 'row',
  },
  columnFirstName: {
    flex: 1,
    marginRight: 8,
  },
  columnLastName: {
    flex: 1,
    marginLeft: 8,
  },
  form: {
    marginBottom: 36,
  },
  submitButton: {
    flex: 1,
  },
  buttonContainer: {
    margin: 16,
  },
  container: {
    flex: 1,
    backgroundColor: 'app-background',
  },
  headerContainer: {
    marginHorizontal: 16,
    marginTop: 20,
    marginBottom: 48,
  },
  header: {
    fontSize: 23,
    lineHeight: 32,
    fontFamily: 'Jura_700Bold',
    color: 'text-primary',
    textAlign: 'center',
    marginBottom: 16,
  },
  headerSub: {
    fontSize: 16,
    lineHeight: 22.5,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'text-primary',
    textAlign: 'center',
  },
  sectionHeaderRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  sectionHeader: {
    fontSize: 23,
    lineHeight: 32,
    fontFamily: 'Jura_700Bold',
    color: 'text-primary',
    textAlign: 'left',
  },
  sectionEdit: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: 'SourceSansPro_600SemiBold',
    color: 'red-03',
    textAlign: 'center',
    marginLeft: 8,
  },
  imageBg: {
    flex: 1,
  },
  scrollContainer: {
    flex: 1,
  },
  spacer: {
    margin: 32,
  },
  addressForm: {
    marginTop: 0,
  },
  callingCodeContainer: {
    padding: 8,
    marginRight: 8,
    marginLeft: -8,
    backgroundColor: 'rgba(196, 196, 196, 0.15)',
    borderRadius: 4,
  },
  callingCode: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
  },
  disclaimerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 16,
    marginBottom: 24,
  },
  disclaimer: {
    fontSize: 14,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
  },
  disclaimerRed: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_700Bold',
    color: 'red-03',
  },
  textWithLink: {
    flex: 1,
    paddingLeft: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
});

export default observer(ConfirmInfo);
