import { useStyleSheet, StyleService } from '@ui-kitten/components';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import AvatarSelector from '../../components/Common/AvatarSelector';
import SecondaryContainerView from '../../components/Common/SecondaryContainerView';
import StepProgressBar from '../../components/Common/StepProgressBar';
import ViewPager from '../../components/Common/ViewPager';
import AccountInfo from '../../components/SignUp/AccountInfo';
import { useStore } from '../../stores';
import { logEventAF, track } from '../../utils/analytics';
import { getErrors } from '../../utils/errors';
import { convertPhoto, formatPhoto } from '../../utils/photos';

type Props = object;

export type FormData = {
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  gender: string;
  gamerTag: string;
  password1: string;
  password2: string;
  referrerCode: string;
};

const SignUp: React.FC<Props> = (_props) => {
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const styles = useStyleSheet(themedStyles);
  const steps = 100;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [accountInfo, setAccountInfo] = useState<FormData>();
  const [photo, setPhoto] = useState<any>();
  const [avatar, setAvatar] = useState<{ name: string; image: number } | null>(
    null,
  );
  const [emailError, setEmailError] = useState<boolean>(false);
  const store = useStore();
  const { authStore, userStore, showToastMessage } = store;
  const onSelectPhoto = useCallback(async (image: any) => {
    const photo = await convertPhoto(formatPhoto(image));
    setPhoto(photo);
  }, []);

  const onNext = () => {
    setSelectedIndex(selectedIndex + 1);
  };

  const onGoBack = () => {
    if (loading) {
      return;
    }
    setSelectedIndex(selectedIndex - 1);
  };

  const createAccount = useCallback(async () => {
    if (loading || !accountInfo) {
      return;
    }
    setLoading(true);
    setEmailError(false);
    let result;

    if (!authStore.token || !authStore.user) {
      result = await authStore.signUp(accountInfo);

      if (!result.ok) {
        const error = getErrors(result.errors);
        if (error.includes('A user with this email address already exists.')) {
          setEmailError(true);
        }
        showToastMessage(true, {
          message: error,
          error: true,
        });
        setLoading(false);
        setSelectedIndex(0);
        return;
      }
      if (photo) {
        await userStore.updateMePhoto(photo);
      } else if (avatar) {
        await userStore.updateMePhotoUsingDefaultAvatar(avatar.name);
      }
    }

    store.setIsBiometricEnabled(false);
    store.setHasBiometricDetails(true);
    track('Created Account / Registration Completed');
    logEventAF('Completed Sign Up');
    store.setIsSignUpComplete(true);
  }, [loading, accountInfo, photo, avatar]);

  return (
    <SecondaryContainerView
      title={t<string>('sign_up.header_title')}
      headerLeftButtonIconTestID="SignUpHeaderLeftButton"
      onGoBack={selectedIndex === 0 ? undefined : onGoBack}
      isChildStack={selectedIndex !== 0}
      gradientVisible={false}
    >
      <View style={isMobile ? { flex: 1 } : styles.container}>
        <StepProgressBar
          steps={steps}
          maxTab={2}
          selectedIndex={selectedIndex}
        />
        <View>
          <ViewPager
            style={styles.pager}
            selectedIndex={selectedIndex}
            swipeEnabled={false}
          >
            <View style={styles.pager}>
              <AccountInfo
                onNext={onNext}
                setAccountInfo={setAccountInfo}
                selectedIndex={selectedIndex}
                emailError={emailError}
              />
            </View>
            <View style={styles.pager}>
              <AvatarSelector
                onBack={onGoBack}
                onNext={createAccount}
                photo={photo}
                setPhoto={setPhoto}
                avatar={avatar}
                setAvatar={setAvatar}
                onSelectPhoto={onSelectPhoto}
                buttonTitle={t<string>('sign_up.create_account_button')}
                isSignUp
                loading={loading}
                selectedIndex={selectedIndex}
              />
            </View>
          </ViewPager>
        </View>
      </View>
    </SecondaryContainerView>
  );
};

const themedStyles = StyleService.create({
  container: {
    overflow: 'hidden',
    width: 575,
    background: '#292828',
    borderRadius: 5,
    marginBottom: 40,
  },
  containerMobile: {
    flex: 1,
    overflow: 'hidden',
  },
  box: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pager: {
    flex: 1,
    width: '100%',
  },
});

export default SignUp;
