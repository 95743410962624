import config from '../config';

export const onGoToPrivacyPolicy = () => {
  window.open(`${config.ugamiSiteAddress}/app/ugami-privacy-policy/`);
};

export const onGoToTermsOfUse = () => {
  window.open(`${config.ugamiSiteAddress}/app/ugami-terms-of-use/`);
};

export const onGoToEsignConsent = () => {
  window.open(
    `${config.ugamiSiteAddress}/app/electronic-disclosure-and-consent/`,
  );
};

export const onGoToConsumerDeposit = () => {
  window.open(
    `${config.ugamiSiteAddress}/app/ugami-consumer-deposit-account-agreement/`,
  );
};

export const onGoToDebitCardholder = () => {
  window.open(
    `${config.ugamiSiteAddress}/app/ugami-visa-debit-cardholder-agreement/`,
  );
};

export const onGoToRewards = () => {
  window.open(`${config.ugamiSiteAddress}/app/ugami-rewards-terms-conditions/`);
};

export const onGoToSweepstakes = () => {
  window.open(`${config.ugamiSiteAddress}/app/ugami-ugipoints-sweepstakes/`);
};
