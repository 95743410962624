import React from 'react';
import { SafeAreaView, ScrollView } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import OnboardingDoneDesktop from '../../components/OnboardingDone/OnboardingDoneDesktop';
import OnboardingDoneMobile from '../../components/OnboardingDone/OnboardingDoneMobile';

type Props = object;
const OnboardingDone: React.FC<Props> = (_props) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  return (
    <SafeAreaView style={{ padding: 10, flex: 1 }}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        {isMobile ? <OnboardingDoneMobile /> : <OnboardingDoneDesktop />}
      </ScrollView>
    </SafeAreaView>
  );
};

export default OnboardingDone;
