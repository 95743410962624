import { useNavigation } from '@react-navigation/native';
import {
  useStyleSheet,
  StyleService,
  Button,
  Text,
} from '@ui-kitten/components';
import { ResizeMode, Video } from 'expo-av';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  Image,
  StyleProp,
  ImageStyle,
  BackHandler,
  TouchableOpacity,
  ScrollView,
  LayoutChangeEvent,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';

import Celebration from '../../assets/videos/celebration_animation.m4v';
import Header from '../../components/Header';
import { PostRegistrationStackNavigation } from '../../navigation';
import { useStore } from '../../stores';

type Props = object;

const CreateAccountComplete: React.FC<Props> = (_props) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<PostRegistrationStackNavigation>();
  const { authStore, statusStore } = store;
  const user = authStore.user;
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const [width, setWidth] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      await statusStore.fetchStatus();
    })();
    const backAction = () => {
      store.setIsSignInComplete(true);
      store.setIsSignUpComplete(false);
      return true;
    };

    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );

    return () => backHandler.remove();
  }, []);

  const goToDashboard = () => {
    store.setFirstTimeNavigation(true);
    store.setIsSignInComplete(true);
    store.setIsSignUpComplete(false);
  };

  const onLearnMore = () => {
    navigation.navigate('LearnMore', { isAccountCreated: true });
  };

  if (!user) {
    return null;
  }

  const onLayout = (e: LayoutChangeEvent) => {
    if (width === 0) {
      setWidth(e.nativeEvent.layout.width);
    }
  };

  return (
    <ScrollView style={{ flexGrow: 1 }}>
      <View style={isMobile ? {} : { alignItems: 'center' }}>
        <View style={styles.container} onLayout={onLayout}>
          <Video
            isMuted
            resizeMode={ResizeMode.COVER}
            shouldPlay
            source={Celebration}
            style={isMobile ? [styles.container, { width }] : styles.video}
          />
          <View style={isMobile ? styles.overlayMobile : styles.overlay}>
            <Header title="" isChildStack={false} />
            <View style={styles.content}>
              <View style={styles.formContainer}>
                {!user || user?.profileImage === '' ? (
                  <View style={styles.avatar} />
                ) : (
                  <Image
                    style={styles.avatar as StyleProp<ImageStyle>}
                    source={{ uri: user?.profileImage }}
                  />
                )}
                <Text style={styles.gamertag}>
                  {user?.gamerTag || ''}
                  <Text style={styles.number}>{` #${
                    user?.gamerTagNumber || ''
                  }`}</Text>
                </Text>
                <Text style={styles.title}>
                  {t<string>('sign_up.account_created.title')}
                </Text>
                <Text style={styles.message}>
                  {t<string>('sign_up.account_created.description')}
                </Text>
                <View style={styles.spacer} />
                <Button
                  size="large"
                  style={styles.next}
                  onPress={goToDashboard}
                  testID="GoToDashboardButton"
                >
                  {t<string>('sign_up.account_created.next_button')}
                </Button>
                <View
                  style={[styles.row, { marginBottom: insets.bottom + 20 }]}
                >
                  <Text style={styles.text}>
                    {t<string>('sign_up.account_created.more_info')}{' '}
                  </Text>
                  <TouchableOpacity
                    onPress={onLearnMore}
                    testID="AccountCreatedGoToLearnMoreButton"
                  >
                    <Text style={styles.learnMore}>
                      {t<string>('sign_up.account_created.learn_more_button')}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingTop: '10%',
  },
  video: {
    width: 570,
    top: '10%',
  },
  overlay: {
    position: 'absolute',
    backgroundColor: 'transparent',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    marginTop: '25%',
  },
  overlayMobile: {
    position: 'absolute',
    backgroundColor: 'transparent',
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  imageBg: {
    flex: 1,
    resizeMode: 'cover',
  },
  formContainer: {
    flex: 1,
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontFamily: 'Jura_600SemiBold',
    paddingTop: 30,
    paddingBottom: 12,
  },
  message: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
    textAlign: 'center',
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  next: {
    alignSelf: 'stretch',
    marginHorizontal: 16,
    marginBottom: 28,
  },
  avatar: {
    width: 170,
    height: 170,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: 'gray-02',
    backgroundColor: 'brand-field-background',
    marginBottom: 16,
  },
  gamertag: {
    fontSize: 18,
    fontFamily: 'SourceSansPro_700Bold',
  },
  number: {
    fontSize: 18,
    fontFamily: 'SourceSansPro_400Regular',
    paddingBottom: 60,
    opacity: 0.5,
  },
  spacer: {
    flex: 1,
    marginTop: 40,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
  },
  learnMore: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_700Bold',
    color: 'red-03',
    paddingHorizontal: 4,
  },
});

export default observer(CreateAccountComplete);
