import { useStyleSheet, StyleService, Text } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import Background from '../../assets/images/Background.svg';
import Glow from '../../assets/images/Glow.svg';
import LogoUgami from '../../assets/images/Logo_ugami.svg';
import UgamiAppandCards from '../../assets/images/Ugami_App_and_Cards.svg';
import { Props } from '../../types/Object';
import SvgIcon from '../Common/SvgIcon';
import PlayStore from '../PlayStore';

const OnboardingDoneMobile: React.FC<Props> = (_props) => {
  const styles = useStyleSheet(themedStyles);
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.subContainerTwo}>
        <SvgIcon>
          <LogoUgami style={styles.logoUgami} />
        </SvgIcon>
        <Text style={styles.title_1} category="h1">
          {t<string>('sign_up.onboarding_done.title.text_1')}
        </Text>
        <View style={{ height: 280, width: 338 }}>
          <SvgIcon>
            <Glow style={styles.UgamiAppandCards} />
          </SvgIcon>
          <SvgIcon>
            <UgamiAppandCards style={styles.UgamiAppandCards} />
          </SvgIcon>
        </View>
      </View>
      <View style={styles.subContainerOne}>
        <View style={styles.titleBox_2}>
          <Text style={styles.title_2} category="h1">
            {t<string>('sign_up.onboarding_done.title.text_2')}
          </Text>
        </View>
        <View style={styles.titleBox_3}>
          <Text style={styles.title_3} category="s2">
            {t<string>('sign_up.onboarding_done.subtitle.text_1')}
          </Text>
          <Text style={styles.title_3} category="s2">
            {t<string>('sign_up.onboarding_done.subtitle.text_2')}
          </Text>
          <Text style={styles.title_3} category="s2">
            {t<string>('sign_up.onboarding_done.subtitle.text_3')}
          </Text>
        </View>
      </View>
      <View style={styles.playstore}>
        <PlayStore device="mobil" />
      </View>
      <View style={styles.titleBox_4}>
        <Text style={styles.title_4} category="s2">
          {t<string>('sign_up.onboarding_done.footer.text_1')}
        </Text>
        <Text style={styles.title_bold} category="s2">
          {t<string>('sign_up.onboarding_done.footer.text_2')}
        </Text>
        <Text style={styles.title_4} category="s2">
          {t<string>('sign_up.onboarding_done.footer.text_3')}
        </Text>
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignContent: 'center',
    background: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  subContainerOne: {
    alignContent: 'center',
    padding: 20,
  },
  subContainerTwo: {
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
  },
  playstore: {
    alignItems: 'center',
  },
  ugamiImage: {
    resizeMode: 'contain',
    flex: 6,
    aspectRatio: 1,
  },
  title_1: {
    fontFamily: 'Rajdhani_SemiBold',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 46,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 15,
  },
  title_2: {
    fontFamily: 'Rajdhani_SemiBold',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 46,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 20,
    flex: 1,
  },
  title_3: {
    fontFamily: 'Rajdhani_Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 5,
    color: '#979797',
  },
  title_4: {
    fontFamily: 'Rajdhani_Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 5,
    color: '#979797',
  },
  title_bold: {
    fontFamily: 'Rajdhani_Bold',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 5,
    color: '#979797',
  },
  titleBox_1: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  titleBox_2: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  titleBox_3: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  titleBox_4: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 15,
  },
  UgamiAppandCards: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  logoUgami: {
    width: '45%',
    marginTop: '10%',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  titleBox: {
    marginBottom: 20,
  },
  subTitle: {
    textAlign: 'center',
  },
});

export default OnboardingDoneMobile;
