import { useNavigation, useRoute } from '@react-navigation/native';
import { Text, useStyleSheet, StyleService, Icon } from '@ui-kitten/components';
import React, { useState } from 'react';
import { TouchableOpacity, View, Keyboard } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Logo from '../../assets/images/logo.svg';
import { AppStackNavigation } from '../../navigation';
import { HeaderProps } from '../../types/HeaderProps';
import BackIcon from '../Common/Icons/BackIcon';
import MenuPopup from '../Common/MenuPopup';
import SvgIcon from '../Common/SvgIcon';

const Header: React.FC<HeaderProps> = ({
  buttonStyle,
  style,
  title,
  isChildStack = true,
  onGoBack,
  onLayout,
  showLogo = false,
  leftButtonIcon = <BackIcon />,
  leftButtonColor = '#FFFFFF',
  leftButtonSize = 24,
  leftButtonTestId = 'HeaderLeftActionButton',
  leftButtonOnPress,
  leftButtonMenu,
  leftButtonDisabled = false,
  rightButtonIcon,
  rightButtonColor = '#FFFFFF',
  rightButtonSize = 24,
  rightButtonTestId = 'HeaderRightActionButton',
  rightButtonOnPress,
  rightButtonMenu,
  rightPopupTestId,
}) => {
  const insets = useSafeAreaInsets();
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation<AppStackNavigation>();
  const route = useRoute();
  const [showLeftMenu, setShowLeftMenu] = useState(false);
  const [showRightMenu, setShowRightMenu] = useState(false);

  const onBack = () => {
    Keyboard.dismiss();
    if (onGoBack) {
      onGoBack();
    } else {
      navigation.goBack();
    }
  };

  // The final right or left action is computed below.
  let onLeftButtonPress: () => void;
  if (leftButtonMenu && leftButtonMenu.length > 0) {
    onLeftButtonPress = () => setShowLeftMenu(true);
  } else {
    onLeftButtonPress = leftButtonOnPress ?? onBack;
  }

  let onRightButtonPress: (() => void) | undefined;
  if (rightButtonMenu && rightButtonMenu.length > 0) {
    onRightButtonPress = () => setShowRightMenu(true);
  } else {
    onRightButtonPress = rightButtonOnPress;
  }

  return (
    <View
      style={[
        styles.container,
        { paddingTop: insets.top + 15, zIndex: 10 },
        style,
      ]}
      onLayout={onLayout}
    >
      {showLogo && (
        <View style={styles.logo}>
          <SvgIcon>
            <Logo />
          </SvgIcon>
        </View>
      )}
      {isChildStack ? (
        <MenuPopup
          anchor={() => (
            <TouchableOpacity
              testID={leftButtonTestId}
              style={[styles.button, buttonStyle]}
              onPress={onLeftButtonPress}
              disabled={leftButtonDisabled}
            >
              {typeof leftButtonIcon === 'string' ? (
                <Icon
                  name={leftButtonIcon}
                  width={leftButtonSize}
                  height={leftButtonSize}
                  fill={leftButtonColor}
                />
              ) : (
                leftButtonIcon
              )}
            </TouchableOpacity>
          )}
          visible={showLeftMenu}
          data={leftButtonMenu}
          onHide={() => setShowLeftMenu(false)}
          placement="bottom start"
        />
      ) : (
        <View style={styles.spacer} />
      )}
      {showLogo ? (
        <View style={styles.middleSpace} />
      ) : (
        <Text style={styles.title} category="h5">
          {title ?? route.name}
        </Text>
      )}

      {rightButtonIcon ? (
        !rightButtonOnPress && typeof rightButtonIcon !== 'string' ? (
          <View testID={rightButtonTestId} style={[styles.button, buttonStyle]}>
            {rightButtonIcon}
          </View>
        ) : rightButtonOnPress ? (
          <MenuPopup
            PopupTestId={rightPopupTestId}
            anchor={() => (
              <TouchableOpacity
                testID={rightButtonTestId}
                style={[styles.button, buttonStyle]}
                onPress={onRightButtonPress}
              >
                {typeof rightButtonIcon === 'string' ? (
                  <Icon
                    name={rightButtonIcon}
                    width={rightButtonSize}
                    height={rightButtonSize}
                    fill={rightButtonColor}
                  />
                ) : (
                  rightButtonIcon
                )}
              </TouchableOpacity>
            )}
            visible={showRightMenu}
            data={rightButtonMenu}
            onHide={() => setShowRightMenu(false)}
            placement="bottom end"
          />
        ) : (
          <View style={styles.spacer} />
        )
      ) : (
        <View style={styles.spacer} />
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: 16,
  },
  title: {
    flex: 1,
    fontSize: 20,
    fontFamily: 'SourceSansPro_400Regular',
    textAlign: 'center',
  },
  logo: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    bottom: 0,
    left: 0,
  },
  button: {
    padding: 8,
    minHeight: 40,
    minWidth: 40,
  },
  closeIcon: {
    width: 28,
    height: 28,
  },
  spacer: {
    width: 40,
    height: 40,
  },
  middleSpace: {
    flex: 1,
  },
});

export default Header;
