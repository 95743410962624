import React, { useEffect, useRef } from 'react';
import { ScrollView, View } from 'react-native';

type ScrollTrackerProps = {
  field: string;
};

export const useAutoScroll = () => {
  const scrollRef = useRef<ScrollView>(null);
  const scrollPosition = useRef<number>(0);
  const scrollTrackerPositions = useRef<{ [key: string]: number }>({});

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    // @ts-ignore
    scrollRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => {
      scrollPosition.current = pageY;
    });
  }, [scrollRef.current]);

  const ScrollTracker: React.FC<ScrollTrackerProps> = ({ field }) => {
    const ref = useRef<View>(null);

    return (
      <View
        ref={ref}
        onLayout={() => {
          if (!ref.current) {
            return;
          }
          ref.current.measure((_x, _y, _width, _height, _pageX, pageY) => {
            scrollTrackerPositions.current[field] = pageY;
          });
        }}
      />
    );
  };

  const scrollToField = (
    field: string,
    animated: boolean = true,
    margin: number = 16,
  ) => {
    if (
      !scrollRef.current ||
      !scrollTrackerPositions.current ||
      !scrollTrackerPositions.current[field]
    ) {
      return;
    }
    const y =
      scrollTrackerPositions.current[field] - scrollPosition.current - margin;
    scrollRef.current.scrollTo({ y, animated });
  };

  const scrollToTop = (animated: boolean = true) => {
    if (!scrollRef.current) {
      return;
    }
    scrollRef.current.scrollTo({ y: 0, animated });
  };

  return {
    scrollRef,
    ScrollTracker,
    scrollToField,
    scrollToTop,
  };
};
