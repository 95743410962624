import { useStyleSheet, StyleService, Text } from '@ui-kitten/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

import Check from '../../assets/images/check.svg';
import { AccountType } from '../../constants/AccountType';
import { RIGHT_MARGIN, TYPE_WIDTH } from '../../constants/Table';
import { Props } from '../../types/Table';
import SvgIcon from '../Common/SvgIcon';

const Table: React.FC<Props> = ({ accountType, setAccountType }) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const right = useSharedValue(RIGHT_MARGIN);

  useEffect(() => {
    switch (accountType) {
      case AccountType.DEBIT:
        setRightPosValue(TYPE_WIDTH + RIGHT_MARGIN);
        break;
      case AccountType.DEBIT_BOOSTED:
        setRightPosValue(RIGHT_MARGIN);
        break;
      default:
        setRightPosValue(RIGHT_MARGIN);
        break;
    }
  }, [accountType]);

  const setRightPosValue = (value: number) => {
    right.value = withTiming(value, {
      duration: 200,
    });
  };

  const animatedStyle = useAnimatedStyle(() => {
    return {
      right: right.value,
    };
  });

  return (
    <View style={styles.container}>
      <View style={styles.tableHeader}>
        <Text style={styles.thTitleWithOpacity}>
          {t<string>('card_application.table.headers.available')}
        </Text>
        <Text style={styles.thTitle}>
          {t<string>('card_application.table.headers.debit')}
        </Text>
        <Text style={styles.thTitle}>
          {t<string>('card_application.table.headers.boosted')}
        </Text>
      </View>
      <View style={styles.evenRow}>
        <Text style={styles.rowText}>
          {t<string>('card_application.table.available.text_1.p1')}
          <Text style={styles.ugipoints}>
            ugipoints
            <Text style={styles.up}> (UP) </Text>
          </Text>
          {t<string>('card_application.table.available.text_1.p2')}
        </Text>
        <View style={styles.iconContainer}>
          <SvgIcon>
            <Check />
          </SvgIcon>
        </View>
        <View style={styles.iconContainer}>
          <SvgIcon>
            <Check />
          </SvgIcon>
        </View>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowTextBold}>
          {t<string>('card_application.table.available.text_2.p1')}
          <Text style={styles.rowText}>
            {t<string>('card_application.table.available.text_2.p2')}
          </Text>
        </Text>
        <View style={styles.iconContainer}>
          <Text style={styles.multiplier}>x3</Text>
        </View>
        <View style={styles.iconContainer}>
          <Text style={styles.multiplier}>x4</Text>
        </View>
      </View>
      <View style={styles.evenRow}>
        <Text style={styles.rowTextBold}>
          {t<string>('card_application.table.available.text_3.p1')}{' '}
          <Text style={styles.rowText}>
            {t<string>('card_application.table.available.text_3.p2')}
          </Text>
        </Text>
        <View style={styles.iconContainer}>
          <Text style={styles.multiplier}>x2</Text>
        </View>
        <View style={styles.iconContainer}>
          <Text style={styles.multiplier}>x4</Text>
        </View>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowTextBold}>
          {t<string>('card_application.table.available.text_4.p1')}
          <Text style={styles.rowText}>
            {t<string>('card_application.table.available.text_4.p2')}
          </Text>
        </Text>
        <View style={styles.iconContainer}>
          <Text style={styles.multiplier}>x1</Text>
        </View>
        <View style={styles.iconContainer}>
          <Text style={styles.multiplier}>x4</Text>
        </View>
      </View>
      <View style={styles.evenRow}>
        <Text style={styles.rowTextBold}>
          {t<string>('card_application.table.available.text_5.p1')}
          <Text style={styles.rowText}>
            {t<string>('card_application.table.available.text_5.p2')}
          </Text>
        </Text>
        <View style={styles.iconContainer}>
          <SvgIcon>
            <Check />
          </SvgIcon>
        </View>
        <View style={styles.iconContainer}>
          <SvgIcon>
            <Check />
          </SvgIcon>
        </View>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowText}>
          {t<string>('card_application.table.available.text_6')}
        </Text>
        <View style={styles.iconContainer}>
          <SvgIcon>
            <Check />
          </SvgIcon>
        </View>
        <View style={styles.iconContainer}>
          <SvgIcon>
            <Check />
          </SvgIcon>
        </View>
      </View>
      <View style={styles.evenRow}>
        <Text style={styles.rowText}>
          {t<string>('card_application.table.available.text_7')}
        </Text>
        <View style={styles.iconContainer}>
          <SvgIcon>
            <Check />
          </SvgIcon>
        </View>
        <View style={styles.iconContainer}>
          <SvgIcon>
            <Check />
          </SvgIcon>
        </View>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowText}>
          {t<string>('card_application.table.available.text_9')}
        </Text>
        <View style={styles.iconContainer}>
          <Text style={styles.charge}>
            {t<string>('card_application.table.debit.free')}
          </Text>
        </View>
        <View style={styles.iconContainer}>
          <Text style={styles.charge}>
            {t<string>('card_application.table.boosted.date')}
          </Text>
          <Text
            style={[
              {
                textDecorationLine: 'line-through',
              },
              styles.chargeSubText,
            ]}
          >
            {t<string>('card_application.table.boosted.price_before')}
          </Text>
        </View>
      </View>
      <View style={{ paddingBottom: 20 }} />
      <Animated.View style={[styles.selected, animatedStyle]} />
      <TouchableOpacity
        style={[styles.column, { right: RIGHT_MARGIN }]}
        onPress={() => setAccountType(AccountType.DEBIT_BOOSTED)}
      />
      <TouchableOpacity
        style={[styles.column, { right: TYPE_WIDTH + RIGHT_MARGIN }]}
        onPress={() => setAccountType(AccountType.DEBIT)}
      />
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 12,
  },
  tableHeader: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    paddingBottom: 16,
    borderBottomWidth: 1,
    paddingLeft: 2,
    paddingRight: 4,
    borderColor: '#1E1E1E',
  },
  thTitleWithOpacity: {
    flex: 1,
    fontSize: 14,
    fontFamily: 'SourceSansPro_600SemiBold',
    opacity: 0.5,
    marginLeft: 16,
    marginRight: 4,
  },
  thTitle: {
    fontSize: 12,
    marginTop: 8,
    fontFamily: 'SourceSansPro_600SemiBold',
    textAlign: 'center',
    paddingHorizontal: 2,
    width: TYPE_WIDTH,
    textTransform: 'capitalize',
  },
  row: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: '#1E1E1E',
    paddingLeft: 2,
    marginRight: 4,
  },
  evenRow: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: '#1E1E1E',
    paddingLeft: 2,
    marginRight: 4,
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  rowText: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    fontFamily: 'SourceSansPro_400Regular',
    marginLeft: 16,
    marginRight: 4,
    paddingVertical: 12,
  },
  rowTextBold: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    fontFamily: 'SourceSansPro_600SemiBold',
    marginLeft: 16,
    marginRight: 4,
    paddingVertical: 12,
  },
  iconContainer: {
    paddingHorizontal: 2,
    width: TYPE_WIDTH,
    paddingVertical: 24,
    alignItems: 'center',
    textAlign: 'center',
  },
  multiplier: {
    fontSize: 18,
    fontFamily: 'SourceSansPro_700Bold',
  },
  charge: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_700Bold',
    marginBottom: -2,
    textAlign: 'center',
  },
  chargeSubText: {
    fontSize: 12,
    fontFamily: 'SourceSansPro_700Bold',
    color: 'gray-02',
  },
  selected: {
    flex: 1,
    width: TYPE_WIDTH,
    height: '100%',
    borderWidth: 1,
    borderColor: 'red-03',
    borderRadius: 4,
    position: 'absolute',
  },
  column: {
    flex: 1,
    width: TYPE_WIDTH,
    height: '100%',
    borderRadius: 4,
    position: 'absolute',
    top: 0,
  },
  ugipoints: {
    fontFamily: 'Neuropolitical_Ugami',
    fontSize: 12,
  },
  up: {
    fontFamily: 'Neuropolitical_Ugami',
    fontSize: 10,
  },
});

export default Table;
