export default {
  meta: {},
  appearances: {
    default: {
      mapping: {
        paddingHorizontal: 12,
        paddingVertical: 16,
        backgroundColor: '#232425',
        textFontSize: 16,
        textFontFamily: 'SourceSansPro_400Regular',
      },
    },
  },
};
