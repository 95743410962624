import { Layout, useStyleSheet, StyleService } from '@ui-kitten/components';
import React, { forwardRef, Ref } from 'react';
import { ImageBackground, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';

import { Props } from '../../types/SecondaryContainerView';
import Header from '../Header';
import Navbar from '../Navbar';
import ConditionalWrapper from './ConditionalWrapper';

const SecondaryContainerView = forwardRef<ScrollView, Props>(
  (props: Props, ref?: Ref<ScrollView>) => {
    const {
      showHeader = true,
      scrollEnabled = false,
      isChildStack,
      onGoBack,
      headerLeftButtonIcon,
      headerLeftButtonColor,
      headerLeftButtonSize,
      headerLeftButtonOnPress,
      headerLeftButtonMenu,
      headerRightButtonIcon,
      headerRightButtonColor,
      headerRightButtonSize,
      headerRightButtonOnPress,
      headerRightButtonMenu,
      headerOnLayout,
      title,
      showLogo,
      scrollStyle,
      gradientVisible = true,
      children,
      headerLeftButtonIconTestID,
      scrollViewTestID,
      toast,
      headerStyle,
    } = props;

    const styles = useStyleSheet(themedStyles);
    const insets = useSafeAreaInsets();
    const isMobile = useMediaQuery({ maxWidth: 700 });
    return (
      <ScrollView style={{ flexGrow: 1 }}>
        <Layout style={styles.container}>
          <ImageBackground
            source={require('../../assets/images/background.png')}
            style={isMobile ? styles.imageBgMobile : styles.imageBg}
            resizeMode="repeat"
          >
            <Navbar />
            {showHeader && (
              <Header
                isChildStack={isChildStack}
                onGoBack={onGoBack}
                leftButtonIcon={headerLeftButtonIcon}
                leftButtonColor={headerLeftButtonColor}
                leftButtonSize={headerLeftButtonSize}
                leftButtonOnPress={headerLeftButtonOnPress}
                leftButtonMenu={headerLeftButtonMenu}
                leftButtonTestId={headerLeftButtonIconTestID}
                rightButtonIcon={headerRightButtonIcon}
                rightButtonColor={headerRightButtonColor}
                rightButtonSize={headerRightButtonSize}
                rightButtonOnPress={headerRightButtonOnPress}
                rightButtonMenu={headerRightButtonMenu}
                onLayout={headerOnLayout}
                title={title}
                showLogo={showLogo}
                style={headerStyle}
              />
            )}
            <ConditionalWrapper
              condition={gradientVisible}
              wrapper={(children: any) => <>{children}</>}
            >
              <ConditionalWrapper
                condition={scrollEnabled}
                wrapper={(children: any) => (
                  <ScrollView
                    ref={ref}
                    style={styles.container}
                    contentContainerStyle={[
                      styles.scroll,
                      { paddingBottom: insets.bottom + 32 },
                      scrollStyle,
                    ]}
                    keyboardShouldPersistTaps="handled"
                    testID={scrollViewTestID}
                    keyboardDismissMode="on-drag"
                  >
                    {children}
                  </ScrollView>
                )}
              >
                {children}
              </ConditionalWrapper>
            </ConditionalWrapper>
          </ImageBackground>
          {toast}
        </Layout>
      </ScrollView>
    );
  },
);

const themedStyles = StyleService.create({
  container: {
    flexGrow: 1,
    minHeight: '100vh',
  },
  imageBg: {
    flex: 1,
    resizeMode: 'cover',
    alignItems: 'center',
  },
  imageBgMobile: {
    flex: 1,
    resizeMode: 'cover',
  },
  scroll: {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingTop: 16,
  },
});

export default SecondaryContainerView;
