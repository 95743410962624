import Button from './components/Button';
import CheckBox from './components/CheckBox';
import Input from './components/Input';
import Layout from './components/Layout';
import Popover from './components/Popover';
import Select from './components/Select';
import SelectOption from './components/SelectOption';
import Spinner from './components/Spinner';
import Tooltip from './components/Tooltip';

export default {
  strict: {},
  components: {
    Layout,
    Button,
    Input,
    CheckBox,
    Tooltip,
    Select,
    Spinner,
    Popover,
    SelectOption,
  },
} as any;
