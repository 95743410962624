import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { StatusEnum } from '../constants/Status';

@model('ugami-app/Status')
export default class Status extends Model({
  currentStatus: prop<StatusEnum>(StatusEnum.BEGINNER),
  nextStatus: prop<StatusEnum>(StatusEnum.APPRENTICE),
  achievements: prop<number>(0),
  achievementsTotalItem: prop<number>(0),
  earnedPoints: prop<number>(0),
  pointsUntilNextLevel: prop<number>(0),
  currentLevel: prop<number>(0),
  nextLevel: prop<number>(0),
  totalEarnedPoints: prop<number>(0),
}) {
  @modelAction
  update(data: ModelCreationData<Status>) {
    Object.assign(this, data);
  }
}
