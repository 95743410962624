import { format } from 'date-fns';
import { Base64 } from 'js-base64';
import snakeCaseKeys from 'snakecase-keys';
import utf8 from 'utf8';

import config from '../../config';
import { FormData } from '../../containers/SignUp';
import { callApi, callApiWithToken } from './base';

export const signUp = async (data: FormData) => {
  const userData = snakeCaseKeys({
    ...data,
    dateOfBirth: format(new Date(data.dateOfBirth), 'yyyy-MM-dd'),
  });
  return callApi(
    config.urls.signUp,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(userData),
  );
};

export const signIn = async (data: {
  email: string;
  password: string;
  isBiometrics: boolean;
}) =>
  callApi(
    config.urls.signIn,
    'POST',
    {
      'Content-Type': 'application/json',
      Authorization: `Basic ${Base64.encode(
        utf8.encode(`${data.email}:${data.password}`),
      )}`,
    },
    JSON.stringify(snakeCaseKeys({ isBiometrics: data.isBiometrics })),
  );

export const saveAppLaunchHistory = async (token: string) =>
  callApiWithToken(config.urls.launchHistory, token, 'POST');

export const logOut = async (token: string) =>
  callApiWithToken(config.urls.logOut, token, 'POST');

export const signInWithGoogle = async (data: {
  accessToken: string;
  idToken: string;
}) =>
  callApi(
    config.urls.signInWithGoogle,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const signInWithApple = async (data: {
  accessToken: string;
  idToken: string;
}) =>
  callApi(
    config.urls.signInWithApple,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const signInWithFacebook = async (data: { accessToken: string }) =>
  callApi(
    config.urls.signInWithFacebook,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const changePassword = async (
  token: string,
  data: { oldPassword: string; newPassword1: string; newPassword2: string },
) =>
  callApiWithToken(
    config.urls.changePassword,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const forgotPassword = async (data: { email: string }) =>
  callApi(
    config.urls.forgotPassword,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const verifyResetPasswordCode = async (data: { sixDigitCode: string }) =>
  callApi(
    config.urls.verifyResetPasswordCode,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const resetPasswordWithCode = async (data: {
  sixDigitCode: string;
  password: string;
}) =>
  callApi(
    config.urls.resetPassword,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const userExists = async (email: string) =>
  callApi(
    config.urls.userExists,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ email }),
  );

export const getAppVersion = async (version: string) =>
  callApi(
    config.urls.version,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ version }),
  );

export const getAppFeedbackUrl = async () =>
  callApi(config.urls.feedback, 'GET');
