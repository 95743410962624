import { parse, format, getHours, isSameDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import config from '../config';
import { STATE_CODES } from '../constants/Address';
import { UgamiCardApplicationStatus } from '../constants/UgamiCardApplicationStatus';
import {
  DepositAccountFormData,
  DebitCardFormData,
} from '../constants/UgamiCardFormData';
import { Store } from '../stores';
import { getErrors } from './errors';

export const getDebitCardData = (
  store: Store,
): DebitCardFormData | undefined => {
  const { ugamiCardStore } = store;
  const { cardApplication, cardShippingAddress } = ugamiCardStore;
  if (
    cardApplication &&
    cardApplication.attributes &&
    cardApplication.attributes.status === UgamiCardApplicationStatus.APPROVED
  ) {
    const country = 'United States';
    if (cardShippingAddress) {
      return {
        applicationAddress: {
          title: cardShippingAddress.title,
          street1: cardShippingAddress.street1,
          street2: cardShippingAddress.street2,
          city: cardShippingAddress.city,
          region: cardShippingAddress.region,
          postalCode: cardShippingAddress.postalCode,
          country: cardShippingAddress.country,
        },
      };
    } else {
      return {
        applicationAddress: {
          title: 'Ugami Card Shipping Address',
          street1: cardApplication.attributes.address.street,
          street2: cardApplication.attributes.address.street2,
          city: cardApplication.attributes.address.city,
          region: STATE_CODES[cardApplication.attributes.address.state],
          postalCode: cardApplication.attributes.address.postalCode,
          country,
        },
      };
    }
  }
  return undefined;
};

export const createUgamiCards = async (
  store: Store,
  debitCardData: DebitCardFormData,
): Promise<string | undefined> => {
  const { ugamiCardStore } = store;

  if (!ugamiCardStore.isVirtualCardExist()) {
    const result = await ugamiCardStore.createVirtualDebitCard({});
    if (!result.ok) {
      return getErrors(result.errors);
    }
  }

  if (!ugamiCardStore.isPhysicalCardExist()) {
    const result = await ugamiCardStore.createDebitCard(debitCardData);
    if (!result.ok) {
      return getErrors(result.errors);
    }
  }

  return undefined;
};

export const createUgamiDepositAccount = async (
  store: Store,
  depositAccountData: DepositAccountFormData,
  debitCardData: DebitCardFormData,
): Promise<string | undefined> => {
  const { ugamiCardStore, authStore } = store;
  if (!authStore.user) {
    return undefined;
  }
  if (!authStore.user.unitDepositAccountId) {
    const result = await ugamiCardStore.createDepositAccount(
      depositAccountData,
    );
    if (!result.ok) {
      return getErrors(result.errors);
    }
  }

  return await createUgamiCards(store, debitCardData);
};

export const createUgamiDepositAccountUponDocumentVerification = async (
  store: Store,
): Promise<string | undefined> => {
  const { authStore } = store;
  const user = authStore.user;
  const debitCardData = getDebitCardData(store);
  if (user && debitCardData) {
    const depositAccountData: DepositAccountFormData = {
      depositProduct:
        config.env === 'production' ? user.unitDepositAccountType : 'checking',
    };

    return await createUgamiDepositAccount(
      store,
      depositAccountData,
      debitCardData,
    );
  }

  return undefined;
};

export const unitReviewEstimate = (
  store: Store,
): { isHours: boolean; text: string } => {
  const { authStore } = store;
  if (
    !authStore.user ||
    (authStore.user?.unitDocumentUploadTime || '') === ''
  ) {
    return { isHours: false, text: '9 AM EST tomorrow' };
  }
  const uploadTime = parse(
    authStore.user?.unitDocumentUploadTime,
    'MM-dd-yyyy HH:mm:ss',
    new Date(),
  );
  // check if date is between 8:00 AM to 8:00 PM
  const estTimeNow = parse(
    format(
      utcToZonedTime(new Date(), 'America/New_York'),
      'MM-dd-yyyy HH:mm:ss',
    ),
    'MM-dd-yyyy HH:mm:ss',
    new Date(),
  );
  const from = parse('08:00:00', 'HH:mm:ss', new Date());
  const to = parse('20:00:00', 'HH:mm:ss', new Date());
  if (uploadTime > from && uploadTime < to) {
    return {
      isHours: true,
      text:
        getHours(estTimeNow) - getHours(uploadTime) >= 1 ? '1 hour' : '2 hours',
    };
  }
  return {
    isHours: false,
    text: isSameDay(estTimeNow, uploadTime) ? '9 AM EST tomorrow' : '9 AM EST',
  };
};
