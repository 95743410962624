const card_application = {
  header_title: "Aplicar por la tarjeta Ugami",
  table: {
    headers: {
      available: "Tarjetas disponibles",
      debit: "Débito",
      boosted: "Débito boosted",
    },
    available: {
      text_1: {
        p1: "Gana ",
        p2: "con tus compras",
      },
      text_2: {
        p1: "Multiplicador de Ugipoints: ",
        p2: "Compras en la tienda, juegos y streaming",
      },
      text_3: {
        p1: "Multiplicador de Ugipoints: ",
        p2: "Entrega de comida",
      },
      text_4: {
        p1: "Multiplicador de Ugipoints: ",
        p2: "Cualquier otra compra",
      },
      text_5: {
        p1: "Premios diarios y semanales: ",
        p2: "Giros, rachas y misiones",
      },
      text_6: "Tarjeta virtual y física",
      text_7: "No se requiere verificación de crédito",
      text_8: {
        p1: "Sprints hiperimpulsados ",
        p2: "Bonos de Ugipoints de hasta:",
      },
      text_9: "Costo mensual",
    },
    debit: {
      free: "Gratis",
    },
    boosted: {
      date: "¡Gratis hasta principios de 2023!",
      price_before: "$9.99 por mes",
    },
  },
  card_selector: {
    title: "Selecciona tu tarjeta Ugami",
    you_selected: `Has seleccionado`,
    debit_type: {
      debit: "Débito",
      boosted_debit: "Débito boosted",
    },
    description:
      "Con dos tipos diferentes de tarjetas Ugami, ¡puedes encontrar la tarjeta perfecta para ti!",
    thanks: "¡Gracias por unirte a Ugami!",
    boosted_free: {
      text_1: "¡Tu cuenta será ",
      text_2: "Mejorada",
      text_3: " ",
      text_4: "GRATIS",
      text_5: " hasta ",
      date: "principios de 2023",
    },
    apply_debit_button: "SOLICITAR DÉBITO",
    apply_boosted_button: "SOLICITAR DÉBITO BOOSTED",
  },
  personal_information: {
    title: "Información personal",
    subtitle:
      "Para crear la solicitud, necesitaremos cierta información básica acerca de tí.",
    name: "Nombre",
    last_name: "Apellido",
    birth: "Fecha de nacimiento",
    ssn: "Número de seguro social",
    ssn_tooltip: `Para verificar tu identidad, necesitaremos saber tu número de Seguro Social completo.`,
    country: "País de residencia",
    email: "Email",
    phone: "Número telefónico",
    next_button: "SIGUIENTE",
  },
  contact_information: {
    title: "Información de contacto",
    subtitle:
      "A continuación, necesitamos saber cómo ponernos en contacto contigo si surge la necesidad.",
    check_box: `Marque esta casilla para enviar la tarjeta a una dirección diferente. (Se te pedirá que ingreses esa dirección a continuación).`,
    card_shipping_address: "Dirección de envío de tarjeta",
    shipping_subtitle: `Ingresa la dirección a la que deseas que se envíe tu tarjeta una vez que haya sido aprobada.`,
  },
  verify_phone: {
    title: "Verificar número telefónico",
    title_sms: "Verificación de SMS",
    subtitle:
      "Hemos enviado un mensaje de texto con un código de verificación de 6 dígitos a: ",
    resend: "Reenviar código de verificación",
    verify_button: "VERIFICAR",
    submit_button: "ENVIAR",
  },
  confirm_information: {
    title: "Confirma tu información",
    subtitle:
      "Antes de enviar tu solicitud, por favor confirma que toda tu información es correcta.",
    second_title: "Información personal",
    third_title: "Información de contacto",
    edit: "Editar",
    agreement: {
      p1: "He leído y acepto el",
      disclosure: "Consentimiento de divulgación electrónica",
      consumer: "Acuerdo de Depósito del Consumidor",
      cardholder: "Acuerdo del titular de la tarjeta de débito",
      rewards: "Condiciones de las recompensas",
      p2: " y ",
      sweepstakes: "Reglas del sorteo",
      p3: "y doy mi consentimiento para el uso de registros electrónicos en relación con esta aplicación.",
    },
    submit_button: "PRESENTAR SOLICITUD",
  },
  document_status: {
    required: "Documento faltante",
    received_back: "Falta el anverso del documento",
    received_front: "Falta el reverso del documento",
    invalid: "Rechazado",
    approved: "Aprovado",
    pending_review: "Esperando revisión",
    in_review_1: {
      text_1: "Tu solicitud esta en revisión, esto no debería tomar más de ",
      text_2: " para esta lista",
    },
    in_review_2: "Tu solicitud esta en revisión. Ésto debería estar listo en ",
    upload: "Cargar documento de ",
    reupload: "Cargar de nuevo documento de ",
    documentation: "",
  },
  denied: {
    title: "Revisión completada",
    subtitle:
      "No podemos aceptar tu solicitud en este momento. Revisa los errores detectados en tu solicitud para volver a aplicar.",
    not_approved_button: "¿POR QUÉ NO FUI APROBADO?",
    review_button: "REVISAR SOLICITUD",
  },
};

export { card_application };
