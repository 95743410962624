import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import {
  AppStackNavigation,
  AuthStackNavigation,
  PostRegistrationStackNavigation,
} from '../../navigation';
import { useStore } from '../../stores';

const NotFound: React.FC = () => {
  const styles = useStyleSheet(themedStyles);

  const appNavigation = useNavigation<AppStackNavigation>();
  const authNavigation = useNavigation<AuthStackNavigation>();
  const postAuthNavigation = useNavigation<PostRegistrationStackNavigation>();

  const { authStore, isSignInComplete, isSignUpComplete } = useStore();

  useEffect(() => {
    if (authStore.user) {
      const user = authStore.user;
      if (isSignInComplete) {
        if (user.unitApplicationId) {
          appNavigation.navigate('OnboardingDone');
        } else {
          appNavigation.navigate('Apply for Ugami Card');
        }
        return;
      }
      if (user.hasVerifiedEmail) {
        postAuthNavigation.navigate('CreateAccountComplete');
      } else {
        postAuthNavigation.navigate('ConfirmEmail');
      }
    } else {
      authNavigation.navigate('Create Your Account');
    }
  }, [authStore.user, isSignInComplete, isSignUpComplete]);

  return <Layout style={styles.container} />;
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
});

export default observer(NotFound);
