const card_application = {
  header_title: "Apply for Ugami Card",
  table: {
    headers: {
      available: "Available Cards",
      debit: "Debit",
      boosted: "Boosted Debit",
    },
    available: {
      text_1: {
        p1: "Earn ",
        p2: "On purchases",
      },
      text_2: {
        p1: "Ugipoint Multiplier: ",
        p2: "In-Store, Gaming and Streaming Purchases",
      },
      text_3: {
        p1: "Ugipoint Multiplier: ",
        p2: "Food Delivery",
      },
      text_4: {
        p1: "Ugipoint Multiplier: ",
        p2: "All Other Purchases",
      },
      text_5: {
        p1: "Daily & Weekly Rewards: ",
        p2: "Spins, Sweeps and Streaks",
      },
      text_6: "Virtual & Physical Card",
      text_7: "No Credit Check Required",
      text_8: {
        p1: "Hyper Boosted Sprints ",
        p2: "Ugipoint Bonus up to:",
      },
      text_9: "Monthly Charge",
    },
    debit: {
      free: "Free",
    },
    boosted: {
      date: "Free Until Early 2023!",
      price_before: "$9.99 per month",
    },
  },
  card_selector: {
    title: "Select Your Ugami Card",
    you_selected: `You've selected`,
    debit_type: {
      debit: "Debit",
      boosted_debit: "Boosted Debit",
    },
    description:
      "With two different types of Ugami cards, you can find the perfect card for you!",
    thanks: "Thanks for joining Ugami!",
    boosted_free: {
      text_1: "Your account will be ",
      text_2: "Boosted",
      text_3: " for ",
      text_4: "FREE",
      text_5: " until ",
      date: "Early 2023",
    },
    apply_debit_button: "APPLY FOR DEBIT",
    apply_boosted_button: "APPLY FOR BOOSTED DEBIT",
  },
  personal_information: {
    title: "Personal Information",
    subtitle:
      "To create your application, we’ll need some basic information about you.",
    name: "First Name",
    last_name: "Last Name",
    birth: "Date of Birth",
    ssn: "Social Security Number",
    ssn_tooltip: `To verify your identity, we'll need to know your full Social Security number.`,
    country: "Country of Residence",
    email: "Email",
    phone: "Phone Number",
    next_button: "NEXT",
  },
  contact_information: {
    title: "Contact Information",
    subtitle:
      "Next, we need to know how to get in contact with you if the need ever arises.",
    check_box: `Check this box to have your card shipped to a different address. (You’ll be asked to enter that address next.)`,
    card_shipping_address: "Card Shipping Address",
    shipping_subtitle: `Enter the address you'd like your card to be shipped to once you've been approved.`,
  },
  verify_phone: {
    title: "Verify Phone Number",
    title_sms: "Sms Verification",
    subtitle: "We’ve sent a text message with a 6 digit verification code to: ",
    resend: "Resend Verification Code",
    verify_button: "VERIFY",
    submit_button: "SUBMIT",
  },
  confirm_information: {
    title: "Confirm Your Information",
    subtitle:
      "Before submitting your application, please confirm that all your information is correct.",
    second_title: "Personal Information",
    third_title: "Contact information",
    edit: "Edit",
    agreement: {
      p1: "I have read and accepted the ",
      disclosure: "Electronic Disclosure and Consent",
      consumer: "Consumer Deposit Agreement",
      cardholder: "Debit Cardholder Agreement",
      rewards: "Rewards Terms",
      p2: " and ",
      sweepstakes: "Sweepstakes Rules",
      p3: "and I consent to the use of electronic records in connection with this application.",
    },
    submit_button: "SUBMIT APPLICATION",
  },
  document_status: {
    required: "Missing Document",
    received_back: "Missing Document Front-side",
    received_front: "Missing Document Back-side",
    invalid: "Rejected",
    approved: "Approved",
    pending_review: "Awaiting Review",
    in_review_1: {
      text_1: "Your application is in review, this shouldn't take longer than ",
      text_2: " to complete",
    },
    in_review_2: "Your application is in review. This should be completed by ",
    upload: "Upload",
    reupload: "Reupload",
    documentation: "Documentation",
  },
  denied: {
    title: "Review Complete",
    subtitle:
      "We are unable to accept your application at this time. Please review the errors detected on your application to reapply.",
    not_approved_button: "WHY WASN'T I APPROVED",
    review_button: "REVIEW APPLICATION",
  },
};

export { card_application };
