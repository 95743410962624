import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('ugami-app/BankAccount')
export default class BankAccount extends Model({
  id: prop<string>(),
  nickname: prop<string>(''),
  accountName: prop<string>(''),
  accountMask: prop<string>(''),
  accountSubtype: prop<string>(''),
  accountType: prop<string>(''),
  institutionName: prop<string>(''),
  unitCounterPartyId: prop<string>(''),
}) {
  @modelAction
  update(data: ModelCreationData<BankAccount>) {
    Object.assign(this, data);
  }
}
