const sign_up = {
  header_title: "Create Your Account",
  account_information: "Account Information",
  email: "Email",
  first_name: "First Name",
  last_name: "Last Name",
  gamertag: "Gamertag",
  gamertag_info:
    "Your Gamertag is your publicly visible username for Ugami. Feel free to choose whatever you like",
  date_of_birth: "Date Of Birth",
  referrer_code: "Referrer Code",
  gender: "Gender",
  genders: ["Male", "Female", "Prefer not to say"],
  gender_placeholder: "Gender your identity",
  password: "Password",
  confirm_password: "Confirm Password",
  password_strength: "Password Strength",
  password_details:
    "Your password must be at least 8 characters long, have one capital letter, one lower case letter, one number, and one symbol",
  checking_box: {
    text_1: "By checking this box, you agree to our ",
    terms_of_service: "Terms of service",
    and: " and ",
    privacy_policy: "Privacy Policy",
    text_2: ". You also confirm you are 13 years or older",
  },
  next_button: "NEXT",

  create_avatar: "Create Your Avatar",
  upload_avatar: "Upload Your Own",
  select_one: "or select one below",
  avatar_tooltip:
    "Your Profile Picture will be publicly visible to all users of the Ugami App.",
  what_do_you_play: "What do you play?",
  mostly_play: "Mostly Play",
  often_play: "Often Play",
  sometimes_play: "Sometimes Play",
  where_do_you_play: "Where do you play?",
  others: "Others",
  specify: "(please specify)",
  skip_button: "SKIP THIS",
  create_account_button: "CREATE ACCOUNT",
  verify_email: {
    title: "Verify Your Email",
    description: {
      text_1: "An email has been sent to ",
      text_2: ". Please check your inbox and verify your email to continue.",
    },
    check_email_button: "CHECK YOUR EMAIL",
    close_button: "CLOSE",
    not_received: "Did not receive an email?",
    resend: "Resend Email",
  },
  update_email: {
    title: "Update Email",
    description:
      "A new verification link will be sent to this email address. Please check your inbox after you confirm your update.",
    save_changes_button: "SAVE CHANGES",
  },
  account_created: {
    title: "You're almost there",
    second_title: "You're all set!",
    message: {
      text_1: "Your email ",
      text_2: "has been succesfully verified.",
    },
    description:
      "Now that your account has been created, it's time to select and apply for an Ugami debit card tier. This application will not affect your credit score.",
    next_button: "NEXT",
    home_button: "GO TO HOME",
    more_info: "Need More Info?",
    learn_more_button: "Learn More",
  },
  accepted: {
    title: "Your application has been approved!",
    subtitle:
      "Here is your physical card. It is currently being shipped - and you can expect it to arrive in the next 5-7 business days. However, your virtual card has been activated and is ready to use!",
    next_button: "NEXT",
  },
  submitted: {
    title: "Your application has been submitted!",
    subtitle: {
      text_1:
        "We will review your information and get back to you soon. A confirmation email has been sent to ",
      text_2:
        ". You can now review your application status on the home screen.",
    },
    next_button: "NEXT",
    go_home_button: "GO TO HOME",
  },
  onboarding_done: {
    title: {
      text_1: "The #1 Debit Card for Gamers",
      text_2: "Your Application is Under Review",
    },
    subtitle: {
      text_1: "Download the Ugami app",
      text_2: "Sign-in using your email and password",
      text_3: "Check your application status",
    },
    footer: {
      text_1: "Contact ",
      text_2: "support@ugami.com ",
      text_3: "if you run into any issues!",
    }
  },
};

export { sign_up };
