import React from 'react';

import Back from '../../../assets/images/back-arrow.svg';
import SvgIcon from '../SvgIcon/index';

const BackIcon = () => {
  return (
    <SvgIcon>
      <Back />
    </SvgIcon>
  );
};

export default BackIcon;
