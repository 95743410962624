import React from 'react';

import { Props } from '../../types/ConditionalWrapper';

const ConditionalWrapper: React.FC<Props> = ({
  condition,
  wrapper,
  children,
}) => {
  return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
