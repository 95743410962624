import React from 'react';

import AppStore from '../../../assets/images/AppStore.svg';
import SvgIcon from '../SvgIcon/index';

const AppStoreIcon = ({ style }) => {
  return (
    <SvgIcon>
      <AppStore style={style} />
    </SvgIcon>
  );
};

export default AppStoreIcon;
