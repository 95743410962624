import {
  Text,
  useStyleSheet,
  StyleService,
  Input,
} from '@ui-kitten/components';
import { subYears, parseISO, format, differenceInYears } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import ContainerScrollView from '../../components/Common/ContainerScrollView';
import LabeledInput from '../../components/Common/LabeledInput';
import NextButton from '../../components/UgamiCard/NextButton';
import { useStore } from '../../stores';
import { PersonalInfoData, Props } from '../../types/PersonalInfo';
import { track } from '../../utils/analytics';
import TooltipInfoVisible from '../TooltipInfoVisible';

const PersonalInfo: React.FC<Props> = ({ onNext, onSubmit, selectedIndex }) => {
  const styles = useStyleSheet(themedStyles);
  const defaultCountry = 'United States';
  const { authStore } = useStore();
  const user = authStore.user;

  const [securityNumber, setSecurityNumber] = useState('');
  const [temporarySsn, setTemporarySsn] = useState('');

  const firstNameRef = useRef<Input>(null);
  const lastNameRef = useRef<Input>(null);
  const birthDayRef = useRef<Input>(null);
  const securityNumberRef = useRef<Input>(null);
  const { t } = useTranslation();
  const tooltipInfoText = t<string>(
    'card_application.personal_information.ssn_tooltip',
  );

  const {
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<PersonalInfoData>();

  useEffect(() => {
    register('firstName', {
      required: true,
    });
    register('lastName', { required: true });
    register('birthday', {
      required: true,
    });
    register('securityNumber', {
      required: true,
    });
    setValue('firstName', user ? user.firstName : '');
    setValue('lastName', user ? user.lastName : '');
    setValue(
      'birthday',
      !user || !user.dateOfBirth || user.dateOfBirth === ''
        ? subYears(new Date(), 18)
        : parseISO(user.dateOfBirth),
    );
  }, [register]);

  useEffect(() => {
    setValue('securityNumber', securityNumber);
  }, [securityNumber]);

  useEffect(() => {
    clearErrors('birthday');
    if (watch('birthday')) {
      const age = differenceInYears(new Date(), watch('birthday'));
      if (age < 18) {
        setError('birthday', {
          message: 'you must be at least 18 to apply',
        });
      }
    }
  }, [watch]);

  const onSubmitEditingFirstName = useCallback(() => {
    lastNameRef.current?.focus();
  }, []);

  const onSubmitEditingLastName = useCallback(() => {
    birthDayRef.current?.focus();
  }, []);

  const onSubmitEditingBirthday = useCallback(() => {
    securityNumberRef.current?.focus();
  }, []);

  const onCalendarFocus = useCallback(() => {
    birthDayRef.current?.blur();
  }, []);

  const onSubmitPressed = () => {
    track('Completed Personal Information');
    onSubmit({
      firstName: watch('firstName'),
      lastName: watch('lastName'),
      birthday: watch('birthday'),
      securityNumber,
    });
    onNext();
  };

  const formatSsn = (text: string) => {
    if (text) {
      const length = text.length;
      const ssnFirstHalf = text.substring(0, 5).replace(/\d/g, '•');
      const secondHalf = text.substring(5, 9);

      text = ssnFirstHalf + secondHalf;

      if (length > 3 && length <= 5) {
        text = `${text.slice(0, 3)}-${text.slice(3, 5)}`;
      } else if (length >= 6) {
        text = `${text.slice(0, 3)}-${text.slice(3, 5)}-${text.slice(5, 9)}`;
      }
    }
    return text;
  };

  const onChangeSsn = (text: string) => {
    const ssn = text.replace(/[^0-9•]/g, '');
    const formattedSsn = formatSsn(ssn);
    const tempSsnLength = ssn.length;
    const ssnLength = securityNumber.length;
    const lastChar = text[text.length - 1];
    let newSsn = '';
    if (tempSsnLength < ssnLength) {
      newSsn = securityNumber.substring(0, tempSsnLength);
    } else {
      newSsn = securityNumber + lastChar;
    }
    setSecurityNumber(newSsn.replace(/[^0-9]/g, ''));
    setTemporarySsn(formattedSsn);
  };

  const disabled =
    watch('firstName')?.trim() === '' ||
    watch('lastName')?.trim() === '' ||
    watch('birthday') === null ||
    securityNumber === '' ||
    securityNumber.length !== 9 ||
    differenceInYears(new Date(), watch('birthday')) < 18;

  if (selectedIndex !== 1) {
    return <View />;
  }

  return (
    <ContainerScrollView testID="PersonalInfoScrollView">
      <Text style={styles.header}>
        {t<string>('card_application.personal_information.title')}
      </Text>
      <Text style={styles.helperText}>
        {t<string>('card_application.personal_information.subtitle')}
      </Text>
      <View>
        <View style={styles.row}>
          <View style={styles.columnFirstName}>
            <LabeledInput
              ref={firstNameRef}
              label={t<string>('card_application.personal_information.name')}
              value={watch('firstName') || ''}
              placeholder="John"
              autoCapitalize="words"
              autoCorrect={false}
              returnKeyType="next"
              onSubmitEditing={onSubmitEditingFirstName}
              onChangeText={(value) => {
                setValue('firstName', value);
              }}
              testID="UgamiCardApplicationFirstName"
            />
          </View>
          <View style={styles.columnLastName}>
            <LabeledInput
              ref={lastNameRef}
              label={t<string>(
                'card_application.personal_information.last_name',
              )}
              value={watch('lastName') || ''}
              placeholder="Smith"
              autoCapitalize="words"
              autoCorrect={false}
              returnKeyType="next"
              onSubmitEditing={onSubmitEditingLastName}
              onChangeText={(value) => {
                setValue('lastName', value);
              }}
              testID="UgamiCardApplicationLastName"
            />
          </View>
        </View>
        <LabeledInput
          ref={birthDayRef}
          label={t<string>('card_application.personal_information.birth')}
          value={
            watch('birthday')
              ? format(watch('birthday'), 'MM/dd/yyyy') || ''
              : ''
          }
          returnKeyType="next"
          onSubmitEditing={onSubmitEditingBirthday}
          onSelect={(value) => {
            setValue('birthday', value);
          }}
          onFocus={onCalendarFocus}
          date={watch('birthday')}
          min={new Date('1900-01-01')}
          max={new Date()}
          isDate
          error={errors.birthday}
          testID="UgamiCardApplicationBirthday"
        />
        <LabeledInput
          ref={securityNumberRef}
          label={t<string>('card_application.personal_information.ssn')}
          placeholder="555-55-5555"
          keyboardType="number-pad"
          testID="UgamiCardApplicationSocialSecurityNumber"
          returnKeyType="done"
          value={temporarySsn || ''}
          accessoryRight={() => (
            <TooltipInfoVisible
              text={tooltipInfoText}
              testID="GamertagToolTip"
              touchableOpacityStyles="gamertag"
            />
          )}
          maxLength={11}
          onChangeText={onChangeSsn}
          onPressIn={onSubmitEditingBirthday}
        />
        <LabeledInput
          label={t<string>('card_application.personal_information.country')}
          value={defaultCountry || ''}
          disabled
        />
        <NextButton
          disabled={disabled}
          onNextPressed={onSubmitPressed}
          testID="PersonalInfoNextButton"
        />
      </View>
    </ContainerScrollView>
  );
};

const themedStyles = StyleService.create({
  nextButton: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 100,
  },
  nextButtonMobile: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 36,
  },
  row: {
    flexDirection: 'row',
  },
  columnFirstName: {
    flex: 1,
    marginRight: 8,
  },
  columnLastName: {
    flex: 1,
    marginLeft: 8,
  },
  container: {
    flex: 1,
    backgroundColor: 'app-background',
  },
  header: {
    fontSize: 23,
    lineHeight: 32,
    fontFamily: 'Jura_700Bold',
    color: 'text-primary',
    textAlign: 'left',
  },
  imageBg: {
    flex: 1,
  },
  ImageButton: {
    width: 360,
    height: 200,
  },
  scrollContainer: {
    flex: 1,
  },
  birthdateContainer: {
    flex: 1,
    alignItems: 'center',
    minHeight: 48,
  },
  input: {
    flex: 1,
  },
  focus: {
    flex: 1,
    borderColor: 'transparent',
    backgroundColor: '#1C1E21',
    borderRadius: 2,
  },
  tooltip: {
    maxWidth: 220,
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    lineHeight: 24,
    color: 'text-dark-primary',
  },
  tooltipContainer: {
    marginLeft: 16,
  },
  helperText: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    lineHeight: 24,
    marginTop: 12,
  },
  ssn: {
    fontSize: 13,
    fontFamily: 'SourceSansPro_400Regular',
    letterSpacing: 1,
  },
  popOver: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    padding: 0,
    marginRight: 10,
  },
});

export default observer(PersonalInfo);
