export default {
  meta: {},
  appearances: {
    filled: {
      mapping: {
        textFontFamily: 'SourceSansPro_600SemiBold',
      },
      variantGroups: {
        status: {
          basic: {
            borderColor: 'transparent',
            backgroundColor: 'blue-gray-02',
            textColor: 'app-background',
          },
          primary: {
            borderColor: 'transparent',
            backgroundColor: 'blue-gray-02',
            textColor: 'app-background',
            state: {
              focused: {
                borderColor: 'transparent',
                backgroundColor: 'blue-gray-05',
              },
              active: {
                borderColor: 'transparent',
                backgroundColor: 'blue-gray-05',
              },
              hover: {
                borderColor: 'transparent',
                backgroundColor: 'blue-gray-05',
              },
              disabled: {
                borderColor: 'transparent',
                backgroundColor: 'button-disabled',
                textColor: 'app-background',
              },
            },
          },
        },
        size: {
          small: {
            borderRadius: 2,
            borderWidth: 'border-width',
            paddingHorizontal: 8,
            paddingVertical: 8,
            textMarginHorizontal: 2,
            textFontSize: 12,
          },
          medium: {
            borderRadius: 2,
          },
        },
      },
    },
    outline: {
      mapping: {
        textFontFamily: 'SourceSansPro_600SemiBold',
      },
      variantGroups: {
        status: {
          basic: {
            borderColor: 'blue-gray-07',
            backgroundColor: 'brand-field-background',
            textColor: 'white',
            state: {
              focused: {
                borderColor: 'outline-button-border-hover',
                backgroundColor: 'brand-field-background-hover',
                textColor: 'text-light-secondary',
              },
              hover: {
                borderColor: 'outline-button-border-hover',
                backgroundColor: 'brand-field-background-hover',
                textColor: 'text-light-secondary',
              },
              active: {
                borderColor: 'outline-button-border-hover',
                backgroundColor: 'brand-field-background-hover',
                textColor: 'text-light-secondary',
              },
              disabled: {
                borderColor: 'outline-button-border-disabled',
                backgroundColor: 'disabled-brand-field-background',
                textColor: 'text-light-secondary',
              },
            },
          },
          primary: {
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            textColor: 'white',
            state: {
              focused: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: 'white',
              },
              hover: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: 'text-hint-color',
              },
              active: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: 'white',
              },
              disabled: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                textColor: 'text-light-secondary',
              },
            },
          },
        },
      },
    },
  },
};
