import { isArray } from 'lodash';

export const getErrors = (errors: any): string =>
  Object.values(errors)
    .map((error) => {
      return typeof error == 'object'
        ? getErrors(error)
        : isArray(error)
        ? error.join('\n')
        : error;
    })
    .join('\n');
