import * as eva from '@eva-design/eva';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import WebSocket from './components/WebSocket';
import Colors from './constants/Colors';
import Mapping from './constants/Mapping/index';
import useColorScheme from './hooks/useColorScheme';
import useInit from './hooks/useInit';
import Navigation from './navigation';
import { StoreProvider } from './stores';
import { initAnalytics } from './utils/analytics';
import './App.css';

export default function App() {
  const { store, ready } = useInit();
  const colorScheme = useColorScheme();
  const statusBar = colorScheme === 'dark' ? 'light' : 'dark';

  if (!ready || !store) {
    return <View />;
  }

  initAnalytics();

  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      <StatusBar style={statusBar} />
      <ApplicationProvider
        mapping={eva.mapping}
        customMapping={Mapping}
        theme={Colors[colorScheme]}
      >
        <StoreProvider value={store}>
          <SafeAreaProvider>
            <Navigation colorScheme={colorScheme} />
            <WebSocket />
          </SafeAreaProvider>
        </StoreProvider>
      </ApplicationProvider>
    </>
  );
}
