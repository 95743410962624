import { isAfter, parse, parseISO, startOfDay, subDays } from 'date-fns';
// import * as AppleAuthentication from 'expo-apple-authentication';
// import * as GoogleSignIn from 'expo-google-sign-in';
import { observable } from 'mobx';
import {
  model,
  Model,
  prop,
  _async,
  _await,
  modelAction,
  modelFlow,
  getRoot,
} from 'mobx-keystone';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

import config from '../config';
import { FormData } from '../containers/SignUp';
import User from '../models/User';
import * as api from '../services/api';
import { StorageService } from '../services/storage/storageService';
import { onUserLogin, trackWithProperties } from '../utils/analytics';
import { getError, getSuccess } from '../utils/models';
import Store from './Store';

@model('ugami-app/AuthStore')
export default class AuthStore extends Model({
  token: prop<string | null>(null),
  user: prop<User | null>(null),
  feedbackUrl: prop<string>(''),
}) {
  storageService!: StorageService;

  @observable
  loading = false;

  @modelAction
  setUser = (user: User | null) => {
    this.user = user;
    if (this.user) {
      Sentry.Browser.setUser({ email: this.user.email });
    } else {
      Sentry.Browser.setUser(null);
    }
  };

  @modelAction
  updateHasVerifiedEmail() {
    if (this.user) {
      this.user.updateHasVerifiedEmail();
    }
  }

  @modelAction
  updateUserUnitApplicationAccountId(unitApplicationId: string) {
    if (this.user) {
      this.user.updateUnitApplicationAccountId(unitApplicationId);
    }
  }

  @modelAction
  updateUserUnitAccountId(unitDepositAccountId: string) {
    if (this.user) {
      this.user.updateUnitDepositAccountId(unitDepositAccountId);
    }
  }

  @modelAction
  updateUserUnitAccountType(unitDepositAccountType: string) {
    if (this.user) {
      this.user.updateUnitDepositAccountType(unitDepositAccountType);
    }
  }

  @modelAction
  updateUserUnitDocumentUploadTime(unitDocumentUploadTime: string) {
    if (this.user) {
      this.user.updateUnitDocumentUploadTime(unitDocumentUploadTime);
    }
  }

  @modelAction
  updateUnitCustomerId(unitCustomerId: string) {
    if (this.user) {
      this.user.updateUnitCustomerId(unitCustomerId);
    }
  }

  @modelAction
  updateUserProfile = (user: User) => {
    const rootStore = getRoot<Store>(this);
    const address =
      Array.from(rootStore.userStore.addresses.values()).find(
        (value) => value.isPrimary,
      ) || '';
    const phone =
      Array.from(rootStore.userStore.phoneNumbers.values()).find(
        (value) => value.isPrimary,
      ) || '';

    const dob = parse(user.dateOfBirth, 'yyyy-MM-dd', new Date());
    const levelStatus = rootStore.statusStore.status?.currentStatus;

    const card = Array.from(rootStore.ugamiCardStore.cards.values())[0];
    const deposit = Array.from(
      rootStore.paymentStore.recurringDeposits.values(),
    )[0];
    const bankAccount = Array.from(
      rootStore.paymentStore.bankAccounts.values(),
    )[0];

    const startDate = subDays(startOfDay(new Date()), 31);

    const transactionList = Array.from(
      rootStore.transactionsStore.transactions.values(),
    ).filter((value) =>
      isAfter(parseISO(value.attributes.createdAt), startDate),
    );

    const traits = {
      user_id: `${user.id}`,
      identity: user.id,
      first_name: user.firstName,
      last_name: user.lastName,
      name: user.fullName,
      dob,
      phone: phone ? phone.number : '',
      gender: user?.gender || 'Others', // Gender is optional
      acquisition_type: 'Ugami Referral', // TODO: Not yet implemented, hardcoded for now
      country: 'United States',
      city: address ? address.city : '',
      plan_type: 'Debit Card', // TODO: Not yet implemented, hardcoded for now
      plan_name: user.unitDepositAccountType,
      registration_start_date: user.dateJoined,
      photo: user.profileImage,
      gamer_tag: user.gamerTag,
      app_version: config.buildVersion,
      platform: Platform.OS,
      email: user.email,
      card_unlocked: `${card !== null}`,
      application_approved: `${card !== null}`,
      card_activated: `${card !== null}`,
      funded_account: `${deposit !== null}`,
      connected_payroll: `${bankAccount !== null}`,
      inactive: `${transactionList.length === 0}`,
      minnow: `${transactionList.length >= 1}`,
      dolphin: `${transactionList.length >= 6}`,
      whale: `${transactionList.length >= 15}`,
      kraken: `${transactionList.length >= 25}`,
      submitted_name_change: `${user.submittedNameChange}`,
      level_status: `${levelStatus}`,
      sign_up_completed: true,
    };

    onUserLogin(traits);
  };

  @modelFlow
  load = _async(function* (this: AuthStore) {
    this.loading = true;

    this.token = yield* _await(
      this.storageService.getItemAsync(config.tokenKey),
    );

    this.loading = false;
  });

  @modelFlow
  removeToken = _async(function* (this: AuthStore) {
    this.token = null;
    yield* _await(this.storageService.removeItemAsync(config.tokenKey));
  });

  @modelFlow
  storeToken = _async(function* (this: AuthStore, token: string) {
    this.token = token;
    yield* _await(this.storageService.setItemAsync(config.tokenKey, token));
  });
  @modelFlow
  _signIn = _async(function* (
    this: AuthStore,
    token: string,
    user: any,
    isSignUp: boolean = false,
  ) {
    yield* _await(this.storeToken(token));
    const newUser = new User(user);
    this.setUser(newUser);
    const rootStore = getRoot<Store>(this);
    const phone =
      Array.from(rootStore.userStore.phoneNumbers.values()).find(
        (value) => value.isPrimary,
      )?.number || '';
    trackWithProperties('User LoggedIn', {
      // 'Acquisition channel': '',
      Phone: phone,
      Platform: Platform.OS,
    });

    // Fetch initial data asynchronously. So don't wait here.
    if (!isSignUp) {
      if (
        this.user &&
        !this.user.hasVerifiedEmail &&
        (!this.user.temporaryEmail || this.user.temporaryEmail === '')
      ) {
        getRoot<Store>(this).setIsSignUpComplete(true);
      } else {
        getRoot<Store>(this).setIsSignInComplete(true);
      }
    }
    getRoot<Store>(this).fetchInitialData();
  });

  @modelFlow
  signIn = _async(function* (
    this: AuthStore,
    data: { email: string; password: string; isBiometrics: boolean },
  ) {
    try {
      const {
        response: { entities },
      } = yield* _await(api.signIn(data));
      yield* _await(this._signIn(entities.token, entities.user));
    } catch (error) {
      console.warn('[DEBUG] sign in error', error);
      return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  signUp = _async(function* (this: AuthStore, data: FormData) {
    try {
      const {
        response: { entities },
      } = yield* _await(api.signUp(data));
      yield* _await(this._signIn(entities.token, entities.user, true));
    } catch (error) {
      console.warn('[DEBUG] sign up error', error);
      return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  saveAppLaunchHistory = _async(function* (this: AuthStore) {
    if (this.token) {
      try {
        yield* _await(api.saveAppLaunchHistory(this.token));
      } catch (error) {
        console.warn('[DEBUG] saving launch history error', error);
        return getError(error);
      }
    }
    return getSuccess();
  });

  @modelFlow
  userExists = _async(function* (this: AuthStore, email: string) {
    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.userExists(email)));
    } catch (error) {
      console.warn('[DEBUG] error checking user', error);
      return getError(error);
    }
    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  logOut = _async(function* (this: AuthStore) {
    if (this.token) {
      try {
        yield* _await(api.logOut(this.token));
      } catch (error) {
        console.warn('[DEBUG] log out error', error);
      }
    }

    const rootStore = getRoot<Store>(this);
    yield* _await(rootStore.reset());
  });

  @modelFlow
  reset = _async(function* (this: AuthStore) {
    yield* _await(this.removeToken());
    this.setUser(null);
  });

  @modelFlow
  forgotPassword = _async(function* (this: AuthStore, data: { email: string }) {
    try {
      yield* _await(api.forgotPassword(data));
    } catch (error) {
      console.warn('[DEBUG] forgot password error', error);
      return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  verifyResetPasswordCode = _async(function* (
    this: AuthStore,
    data: { sixDigitCode: string },
  ) {
    try {
      yield* _await(api.verifyResetPasswordCode(data));
    } catch (error) {
      console.warn('[DEBUG] six digit code error', error);
      return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  passwordReset = _async(function* (
    this: AuthStore,
    data: { sixDigitCode: string; password: string },
  ) {
    try {
      yield* _await(api.resetPasswordWithCode(data));
    } catch (error) {
      console.warn('[DEBUG] reset password error', error);
      return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  changePassword = _async(function* (
    this: AuthStore,
    data: { oldPassword: string; newPassword1: string; newPassword2: string },
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    try {
      yield* _await(api.changePassword(rootStore.authStore.token, data));
    } catch (error) {
      console.warn('[DEBUG] error updating password', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  getAppFeedbackUrl = _async(function* (this: AuthStore) {
    this.loading = true;
    let feedbackUrl;

    try {
      ({
        response: {
          entities: { feedbackUrl },
        },
      } = yield* _await(api.getAppFeedbackUrl()));
    } catch (error) {
      console.warn('[DEBUG] error getting feedback url', error);
      return getError(error);
    }
    this.feedbackUrl = feedbackUrl;
    this.loading = false;
    return getSuccess();
  });
}
