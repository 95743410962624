import React from 'react';
import { Platform } from 'react-native';

const SvgIcon = ({ children }: any) => {
  if (Platform.OS === 'web') {
    return <img src={children.type} {...children.props} />;
  }
  return children;
};

export default SvgIcon;
