import { computed } from 'mobx';
import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import UgamiDepositAccountAttributes from './UgamiDepositAccountAttributes';

@model('ugami-app/DepositAccount')
export default class DepositAccount extends Model({
  id: prop<string>(''),
  attributes: prop<UgamiDepositAccountAttributes>(),
}) {
  @modelAction
  update(data: ModelCreationData<DepositAccount>) {
    Object.assign(this, data);
  }

  @computed
  get hold(): string {
    return ((this.attributes.hold || 0.0) / 100.0).toFixed(2);
  }

  @computed
  get availableBalance(): string {
    return ((this.attributes.available || 0.0) / 100.0).toFixed(2);
  }

  @computed
  get accountLast4Digit(): string {
    return this.attributes.accountNumber.slice(-4);
  }

  @modelAction
  setBalance(newBalance: number, amount: number) {
    this.attributes.balance = newBalance;
    this.attributes.available += amount;
  }

  @modelAction
  setHoldingBalanceAfterSuccessfulTransaction(
    newBalance: number,
    amount: number,
  ) {
    this.attributes.balance = newBalance;
    this.attributes.hold += amount;
  }

  @modelAction
  setHoldingBalance(amount: number) {
    this.attributes.hold += amount;
    this.attributes.available -= amount;
  }
}
