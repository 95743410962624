import { isUndefined } from 'lodash';
import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  getRoot,
  ModelCreationData,
  modelAction,
  prop,
  objectMap,
} from 'mobx-keystone';

import { UgamiCardApplicationStatus } from '../constants/UgamiCardApplicationStatus';
import {
  ApplicationFormData,
  DepositAccountFormData,
  DebitCardFormData,
  VirtualDebitCardFormData,
} from '../constants/UgamiCardFormData';
import { UgamiCardStatus } from '../constants/UgamiCardStatus';
import { UgamiCardType } from '../constants/UgamiCardType';
import Address from '../models/Address';
import CustomerToken from '../models/CustomerToken';
import DepositAccount from '../models/DepositAccount';
import UgamiCard from '../models/UgamiCard';
import UgamiCardApplication from '../models/UgamiCardApplication';
import UgamiCardApplicationDocument from '../models/UgamiCardApplicationDocument';
import UgamiCardPinStatus from '../models/UgamiCardPinStatus';
import * as api from '../services/api';
import { track, logEventAF } from '../utils/analytics';
import { getError, getSuccess } from '../utils/models';
import Store from './Store';

@model('ugami-app/UgamiCardStore')
export default class UgamiCardStore extends Model({
  cards: prop(() => objectMap<UgamiCard>()),
  depositAccounts: prop(() => objectMap<DepositAccount>()),
  customerToken: prop<CustomerToken | null>(null),
  cardApplication: prop<UgamiCardApplication | null>(null),
  cardShippingAddress: prop<Address | null>(null),
  requiredApplicationDocuments: prop(() =>
    objectMap<UgamiCardApplicationDocument>(),
  ),
  cardsPinStatus: prop(() => objectMap<UgamiCardPinStatus>()),
  signedNonce: prop<string | null>(null),
  provisioningState: prop<string | null>(null),
}) {
  @observable
  loading = false;

  isVirtualCardExist = (): boolean => {
    return !isUndefined(
      Array.from(this.cards.values()).find(
        (c) => c.type === UgamiCardType.INDIVIDUAL_VIRTUAL_DEBIT_CARD,
      ),
    );
  };

  isPhysicalCardExist = (): boolean => {
    return !isUndefined(
      Array.from(this.cards.values()).find(
        (c) => c.type === UgamiCardType.INDIVIDUAL_DEBIT_CARD,
      ),
    );
  };

  @modelAction
  awaitingReviewApplication(data: { status: UgamiCardApplicationStatus }) {
    if (this.cardApplication) {
      this.cardApplication.setApplicationStatus(data.status);
    }
  }
  @modelAction
  approveApplication() {
    if (this.cardApplication) {
      this.cardApplication.approveApplication();
    }
  }

  @modelAction
  updateCardAttributes(data: { id: string; status: UgamiCardStatus }) {
    if (data && this.cards.has(data.id)) {
      const card = this.cards.get(data.id)!;
      card.updateAttributes(data.status);
    }
  }

  @modelAction
  createOrUpdateUgamiCard(data: ModelCreationData<UgamiCard>) {
    const id = `${data.id}`;

    let card: UgamiCard;
    if (this.cards.has(id)) {
      card = this.cards.get(id)!;
    } else {
      card = new UgamiCard(data);
      this.cards.set(id, card);
    }
    card.update(data);
  }

  @modelAction
  createOrUpdateUgamiDepositAccount(data: ModelCreationData<DepositAccount>) {
    const id = `${data.id}`;

    let depositAccount: DepositAccount;
    if (this.depositAccounts.has(id)) {
      depositAccount = this.depositAccounts.get(id)!;
    } else {
      depositAccount = new DepositAccount(data);
      this.depositAccounts.set(id, depositAccount);
    }
    depositAccount.update(data);
  }

  @modelAction
  updateDepositAccountBalance = (data: {
    accountId: string;
    balance: number;
    amount: number;
  }) => {
    const accountId = data.accountId;
    const balance = data.balance;
    const amount = data.amount;
    if (
      !isUndefined(accountId) &&
      !isUndefined(balance) &&
      !isUndefined(amount) &&
      this.depositAccounts.has(accountId)
    ) {
      const depositAccount = this.depositAccounts.get(accountId);
      if (depositAccount) {
        depositAccount.setBalance(balance, amount);
        const { attributes, id } = depositAccount;
        depositAccount.update({ attributes, id });
      }
    }
  };

  @modelAction
  updateHoldingBalanceAfterSuccessfulTransaction = (data: {
    accountId: string;
    balance: number;
    amount: number;
  }) => {
    const accountId = data.accountId;
    const balance = data.balance;
    const amount = data.amount;
    if (
      !isUndefined(accountId) &&
      !isUndefined(balance) &&
      !isUndefined(amount) &&
      this.depositAccounts.has(accountId)
    ) {
      const depositAccount = this.depositAccounts.get(accountId);
      if (depositAccount) {
        depositAccount.setHoldingBalanceAfterSuccessfulTransaction(
          balance,
          amount,
        );
        const { attributes, id } = depositAccount;
        depositAccount.update({ attributes, id });
      }
    }
  };

  @modelAction
  setUgamiCardApplication(data: ModelCreationData<UgamiCardApplication>) {
    this.cardApplication = new UgamiCardApplication(data);
  }

  @modelAction
  createOrUpdateUgamiCardApplicationDocument(
    data: ModelCreationData<UgamiCardApplicationDocument>,
  ) {
    const id = `${data.id}`;

    let applicationDocument: UgamiCardApplicationDocument;
    if (this.requiredApplicationDocuments.has(id)) {
      applicationDocument = this.requiredApplicationDocuments.get(id)!;
    } else {
      applicationDocument = new UgamiCardApplicationDocument(data);
      this.requiredApplicationDocuments.set(id, applicationDocument);
    }
    const rootStore = getRoot<Store>(this);
    applicationDocument.update(
      data,
      rootStore.authStore.user?.unitApplicationId || '',
    );
  }

  @modelFlow
  createUgamiCardApplication = _async(function* (
    this: UgamiCardStore,
    data: ApplicationFormData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities = {
      data: { id: '', attributes: {} },
      included: [],
    };

    try {
      const { response } = yield* _await(
        api.createUgamiCardApplication(rootStore.authStore.token, data),
      );
      entities = response.entities;
      rootStore.authStore.updateUserUnitApplicationAccountId(entities.data.id);
      // TODO: check for correct type.
      // @ts-ignore
      this.setUgamiCardApplication(entities.data);
      entities.included.forEach((document) =>
        this.createOrUpdateUgamiCardApplicationDocument(document),
      );
    } catch (error) {
      console.warn('[DEBUG] error creating ugami card application', error);
      return getError(error);
    }

    if (entities.included.length > 0) {
      this.loading = false;
      return getSuccess({ needAdditionalVerification: true });
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  createDepositAccount = _async(function* (
    this: UgamiCardStore,
    depositAccountData: DepositAccountFormData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<DepositAccount>;
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(
        api.createDepositAccount(rootStore.authStore.token, depositAccountData),
      ));
    } catch (error) {
      console.warn('[DEBUG] error creating deposit account', error);
      return getError(error);
    }

    if (data) {
      rootStore.authStore.updateUserUnitAccountId(data?.id || '');
      track('Created Deposit Account');
      logEventAF('Created Deposit Account');
      this.createOrUpdateUgamiDepositAccount(data);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  createDebitCard = _async(function* (
    this: UgamiCardStore,
    debitCardData: DebitCardFormData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<UgamiCard>;

    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(
        api.createDebitCard(rootStore.authStore.token, debitCardData),
      ));
    } catch (error) {
      console.warn('[DEBUG] error creating debit card', error);
      return getError(error);
    }

    if (data) {
      this.createOrUpdateUgamiCard(data);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  createVirtualDebitCard = _async(function* (
    this: UgamiCardStore,
    virtualDebitCardData: VirtualDebitCardFormData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<UgamiCard>;

    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(
        api.createVirtualDebitCard(
          rootStore.authStore.token,
          virtualDebitCardData,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error creating virtual debit card', error);
      return getError(error);
    }

    if (data) {
      this.createOrUpdateUgamiCard(data);
    }

    this.loading = false;
    return getSuccess();
  });
}
