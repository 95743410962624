import {
  Tooltip,
  useStyleSheet,
  StyleService,
  Text,
} from '@ui-kitten/components';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { TooltipInfoVisibleProps } from '../../types/TooltipInfoVisibleProps';
import QuestionMarkIcon from '../Common/Icons/QuestionMarkIcon';

const TooltipInfoVisible = ({
  touchableOpacityStyles = '',
  text,
  testID = '',
}: TooltipInfoVisibleProps) => {
  const styles = useStyleSheet(themedStyles);
  const [gamertagInfoVisible, setGamertagInfoVisible] = useState(false);

  const renderTooltipInfo = () => (
    <TouchableOpacity
      onPress={() => setGamertagInfoVisible(true)}
      style={styles[touchableOpacityStyles]}
      testID={testID ? 'GamertagToolTipButton' : ''}
    >
      <QuestionMarkIcon />
    </TouchableOpacity>
  );
  return (
    <Tooltip
      visible={gamertagInfoVisible}
      anchor={renderTooltipInfo}
      onBackdropPress={() => setGamertagInfoVisible(false)}
      placement="top end"
      testID={testID}
    >
      {(evaProps) => (
        <Text {...evaProps} style={styles.tooltip}>
          {text}
        </Text>
      )}
    </Tooltip>
  );
};

const themedStyles = StyleService.create({
  tooltip: {
    maxWidth: 220,
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    lineHeight: 24,
    color: 'text-dark-primary',
  },
  gamertag: {
    marginTop: 8,
    marginLeft: 16,
  },
  tooltipContainer: {
    marginLeft: 16,
  },
});

export default TooltipInfoVisible;
