import { computed } from 'mobx';
import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { UgamiCardApplicationStatus } from '../constants/UgamiCardApplicationStatus';
import UgamiCardApplicationAttributes from './UgamiCardApplicationAttributes';

@model('ugami-app/UgamiCardApplication')
export default class UgamiCardApplication extends Model({
  id: prop<string>(''),
  attributes: prop<UgamiCardApplicationAttributes>(),
}) {
  @computed
  get mailingAddress(): string {
    const address = this.attributes.address;
    return `${address.street} ${address.street2 ? `${address.street2} ` : ''}${
      address.city
    }, ${address.state} ${address.postalCode} ${address.country}`;
  }

  @modelAction
  update(data: ModelCreationData<UgamiCardApplication>) {
    Object.assign(this, data);
  }

  @modelAction
  approveApplication() {
    if (this.attributes) {
      const attributes = {
        ...this.attributes,
        status: UgamiCardApplicationStatus.APPROVED,
      };
      Object.assign(this.attributes, attributes);
    }
  }

  @modelAction
  setApplicationStatus(status: UgamiCardApplicationStatus) {
    if (this.attributes) {
      const attributes = {
        ...this.attributes,
        status,
      };
      Object.assign(this.attributes, attributes);
    }
  }
}
