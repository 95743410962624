import * as Location from 'expo-location';
import { computed } from 'mobx';
import {
  _async,
  _await,
  model,
  modelFlow,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('ugami-app/Address')
export default class Address extends Model({
  id: prop<number>(),
  title: prop<string>(''),
  street1: prop<string>(''),
  street2: prop<string>(''),
  city: prop<string>(''),
  region: prop<string>(''),
  country: prop<string>(''),
  postalCode: prop<string>(''),
  isPrimary: prop<boolean>(false),
}) {
  @modelAction
  update(data: ModelCreationData<Address>) {
    Object.assign(this, data);
  }

  @computed
  get fullAddress(): string {
    const country = this.country ?? 'United States';
    return `${this.street1} ${this.street2} ${this.city} ${this.region} ${country}`;
  }

  @modelFlow
  geocode = _async(function* (this: Address) {
    const result = yield* _await(Location.geocodeAsync(this.fullAddress));
    if (result.length > 0) {
      return {
        ok: true,
        latitude: result[0].latitude,
        longitude: result[0].longitude,
      };
    }

    return { ok: false };
  });
}
