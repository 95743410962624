import {
  useStyleSheet,
  StyleService,
  Text,
  Button,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import ContainerScrollView from '../../components/Common/ContainerScrollView';
import { AccountType } from '../../constants/AccountType';
import { Props } from '../../types/UgamiCardSelector';
import CardSelector from './CardSelector';
import Table from './Table';

const UgamiCardSelector: React.FC<Props> = ({
  accountType,
  setAccountType,
  onNext,
  selectedIndex,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { t } = useTranslation();

  if (selectedIndex !== 0) {
    return <View />;
  }

  return (
    <ContainerScrollView containerStyle={styles.containerStyle}>
      <View style={styles.headerContainer}>
        <Text style={styles.heading}>
          {t<string>('card_application.card_selector.title')}
        </Text>
      </View>
      <Table accountType={accountType} setAccountType={setAccountType} />
      <Text style={styles.selectedText}>
        {t<string>('card_application.card_selector.you_selected')}
      </Text>
      <Text style={styles.cardType}>
        {accountType === AccountType.DEBIT_BOOSTED
          ? t<string>('card_application.card_selector.debit_type.boosted_debit')
          : t<string>('card_application.card_selector.debit_type.debit')}
      </Text>
      <CardSelector accountType={accountType} setAccountType={setAccountType} />
      <Text style={[styles.bottomText, styles.bottomTextHeading]}>
        {t<string>('card_application.card_selector.thanks')}
      </Text>
      <Text style={styles.bottomText}>
        {t<string>('card_application.card_selector.boosted_free.text_1')}
        <Text style={[styles.bottomText, styles.bottomTextBold]}>
          {t<string>('card_application.card_selector.boosted_free.text_2')}
        </Text>
        {t<string>('card_application.card_selector.boosted_free.text_3')}
        <Text style={[styles.bottomText, styles.bottomTextBold]}>
          {t<string>('card_application.card_selector.boosted_free.text_4')}
        </Text>
        {t<string>('card_application.card_selector.boosted_free.text_5')}
        <Text style={[styles.bottomText, styles.bottomTextBold]}>
          {t<string>('card_application.card_selector.boosted_free.date')}
        </Text>
        !
      </Text>
      <Button
        style={styles.button}
        onPress={onNext}
        size="large"
        testID="UgamiCardSelectorSignUpButton"
      >
        {accountType === AccountType.DEBIT_BOOSTED
          ? t<string>('card_application.card_selector.apply_boosted_button')
          : t<string>('card_application.card_selector.apply_debit_button')}
      </Button>
    </ContainerScrollView>
  );
};

const themedStyles = StyleService.create({
  containerStyle: {
    paddingHorizontal: -16,
  },
  headerContainer: {
    marginHorizontal: 16,
    marginBottom: 24,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  heading: {
    fontSize: 24,
    fontFamily: 'Jura_700Bold',
    textAlign: 'center',
    marginBottom: 12,
  },
  heading2: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
    textAlign: 'center',
    marginHorizontal: 20,
    marginBottom: 8,
  },
  bottomTextHeading: {
    fontSize: 18,
    lineHeight: 22,
    marginTop: 24,
  },
  bottomText: {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: 'SourceSansPro_600SemiBold',
    color: 'text-light-secondary',
    textAlign: 'center',
    marginTop: 12,
    marginHorizontal: 40,
  },
  bottomTextBold: {
    marginLeft: 0,
    marginRight: 0,
    fontFamily: 'SourceSansPro_700Bold',
  },
  selectedText: {
    fontSize: 23,
    lineHeight: 30.2,
    fontFamily: 'Jura_700Bold',
    textAlign: 'center',
    marginTop: 22,
  },
  cardType: {
    fontSize: 18,
    lineHeight: 30.2,
    fontFamily: 'SourceSansPro_600SemiBold',
    textAlign: 'center',
    marginBottom: 16,
  },
  button: {
    marginHorizontal: 16,
    marginTop: 24,
    marginBottom: 24,
  },
});

export default observer(UgamiCardSelector);
