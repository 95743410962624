import React from 'react';

import GooglePlay from '../../../assets/images/GooglePlay.svg';
import SvgIcon from '../SvgIcon/index';

const GooglePlayIcon = ({ style }) => {
  return (
    <SvgIcon>
      <GooglePlay style={style} />
    </SvgIcon>
  );
};

export default GooglePlayIcon;
