import { addDays, getUnixTime } from 'date-fns';
import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  getRoot,
  prop,
  objectMap,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { CirclePaymentAccountStatus } from '../constants/CirclePaymentAccountStatus';
import { CirclePaymentStatus } from '../constants/CirclePaymentStatus';
import { CvvStatus } from '../constants/CvvStatus';
import {
  CardCreationData,
  PaymentData,
  CardUpdateData,
  BookPaymentData,
} from '../constants/PaymentForm';
import BankAccount from '../models/BankAccount';
import Card from '../models/Card';
import Payment from '../models/Payment';
import PublicKey from '../models/PublicKey';
import RecurringDeposit from '../models/RecurringDeposit';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';
import Store from './Store';

@model('ugami-app/PaymentStore')
export default class PaymentStore extends Model({
  // circle data
  publicKey: prop<PublicKey | null>(null),
  cards: prop(() => objectMap<Card>()),
  bankAccounts: prop(() => objectMap<BankAccount>()),
  payments: prop(() => objectMap<Payment>()),
  recurringDeposits: prop(() => objectMap<RecurringDeposit>()),
  authorizationId: prop<string | null>(null),
}) {
  getCard = (id?: string): Card | undefined => {
    if (!id) {
      return undefined;
    }
    return this.cards.get(id);
  };

  getBankAccount = (id?: string): BankAccount | undefined => {
    if (!id) {
      return undefined;
    }
    return this.bankAccounts.get(id);
  };

  getValidCards = (): Card[] => {
    return Array.from(this.cards.values()).filter(
      (c) => c.verification && c.verification.cvv === CvvStatus.PASS,
    );
  };

  getBankAccounts = (): BankAccount[] => {
    return Array.from(this.bankAccounts.values());
  };

  getPrimaryPaymentMethods = (): string => {
    const card = this.getValidCards().find((c) => c.isPrimary === true);

    if (card) {
      return card.id;
    }
    return '';
  };

  getPayment = (id?: string): Payment | undefined => {
    if (!id) {
      return undefined;
    }
    return this.payments.get(id);
  };

  @observable
  loading = false;

  @modelAction
  setAuthorizationId = (id: string) => {
    this.authorizationId = id;
  };

  @modelAction
  createOrUpdateCards(data: ModelCreationData<Card>) {
    if (data.status === CirclePaymentAccountStatus.PENDING) {
      return;
    }
    const id = `${data.id}`;
    let card: Card;
    if (this.cards.has(id)) {
      card = this.cards.get(id)!;
    } else {
      card = new Card(data);
      this.cards.set(id, card);
    }
    card.update(data);
  }

  @modelAction
  createOrUpdateBankAccounts(data: ModelCreationData<BankAccount>) {
    const id = `${data.id}`;
    let bankAccount: BankAccount;
    if (this.bankAccounts.has(id)) {
      bankAccount = this.bankAccounts.get(id)!;
    } else {
      bankAccount = new BankAccount(data);
      this.bankAccounts.set(id, bankAccount);
    }
    bankAccount.update(data);

    return bankAccount;
  }

  @modelAction
  createOrUpdateRecurringDeposits(data: ModelCreationData<RecurringDeposit>) {
    const id = `${data.id}`;
    let recurringDeposit: RecurringDeposit;
    if (this.recurringDeposits.has(id)) {
      recurringDeposit = this.recurringDeposits.get(id)!;
    } else {
      recurringDeposit = new RecurringDeposit(data);
      this.recurringDeposits.set(id, recurringDeposit);
    }
    recurringDeposit.update(data);

    return recurringDeposit;
  }

  @modelAction
  createOrUpdatePayments(data: ModelCreationData<Payment>) {
    if (data.status === CirclePaymentStatus.PENDING) {
      return;
    }

    const id = `${data.id}`;

    let payment: Payment;
    if (this.payments.has(id)) {
      payment = this.payments.get(id)!;
    } else {
      payment = new Payment(data);
      this.payments.set(id, payment);
    }
    payment.update(data);
  }

  @modelAction
  clearPayments() {
    this.payments.clear();
  }

  @modelAction
  deleteCardFromStore(id: string) {
    if (this.cards.has(id)) {
      this.cards.delete(id);
    }
  }

  @modelAction
  deleteBankAccountFromStore(id: string) {
    if (this.bankAccounts.has(id)) {
      this.bankAccounts.delete(id);
      const recurringDeposit = Array.from(this.recurringDeposits.values()).find(
        (r) => r.bank === id,
      );
      if (recurringDeposit) {
        this.recurringDeposits.delete(`${recurringDeposit.id}`);
      }
    }
  }

  @modelFlow
  addCard = _async(function* (this: PaymentStore, cardData: CardCreationData) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let data: ModelCreationData<Card>;
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(api.addCard(rootStore.authStore.token, cardData)));
    } catch (error) {
      console.warn('[DEBUG] error adding card', error);
      return getError(error);
    }

    this.createOrUpdateCards(data);

    this.loading = false;
    return getSuccess(data);
  });

  @modelFlow
  payment = _async(function* (this: PaymentStore, paymentData: PaymentData) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<Payment>;
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(api.payment(rootStore.authStore.token, paymentData)));
    } catch (error) {
      console.warn('[DEBUG] payment error', error);
      return getError(error);
    }

    if (data) {
      this.createOrUpdatePayments(data);
    }

    this.loading = false;
    return getSuccess(data);
  });

  @modelFlow
  bookPayment = _async(function* (
    this: PaymentStore,
    bookPaymentData: BookPaymentData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities: any;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.bookPayment(rootStore.authStore.token, bookPaymentData),
      ));
    } catch (error) {
      console.warn('[DEBUG] book payment error', error);
      return getError(error);
    }
    this.loading = false;
    return getSuccess({ id: entities.data.id });
  });

  @modelFlow
  fetchPublicKey = _async(function* (this: PaymentStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities: any;

    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchPublicKey(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching public key', error);
      return getError(error);
    }

    if (entities) {
      const keyId = entities.data.keyId;
      const publicKey = entities.data.publicKey;
      const expiresIn = getUnixTime(addDays(new Date(), 1));
      this.publicKey = new PublicKey({
        keyId: keyId || '',
        publicKey: publicKey || '',
        expirationDate: expiresIn,
      });
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchCards = _async(function* (this: PaymentStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }
    this.loading = true;

    let results: ModelCreationData<Card>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchCards(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching cards', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateCards(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchLinkToken = _async(function* (this: PaymentStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }
    this.loading = true;

    let entities: { linkToken: string };
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchLinkToken(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching link token', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess({ linkToken: entities.linkToken });
  });

  @modelFlow
  fetchBankAccounts = _async(function* (this: PaymentStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }
    this.loading = true;

    let results: ModelCreationData<BankAccount>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchBankAccounts(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching bank accounts', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateBankAccounts(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchRecurringDeposits = _async(function* (this: PaymentStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }
    this.loading = true;

    let results: ModelCreationData<RecurringDeposit>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchRecurringDeposits(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching recuring deposit', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateRecurringDeposits(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateCard = _async(function* (
    this: PaymentStore,
    id: string,
    data: CardUpdateData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Card>[];
    try {
      yield* _await(api.updateCard(rootStore.authStore.token, id, data));
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchCards(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error updating payment card', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateCards(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  deleteCard = _async(function* (this: PaymentStore, id: string) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    try {
      yield* _await(api.deleteCard(rootStore.authStore.token, id));
    } catch (error) {
      console.warn('[DEBUG] error deleting payment card', error);
      return getError(error);
    }

    this.deleteCardFromStore(id);
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  removeBankAccount = _async(function* (this: PaymentStore, id: string) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    try {
      yield* _await(api.removeBankAccount(rootStore.authStore.token, id));
    } catch (error) {
      console.warn('[DEBUG] error deleting bank account', error);
      return getError(error);
    }

    this.deleteBankAccountFromStore(id);
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateBankAccount = _async(function* (
    this: PaymentStore,
    id: string,
    data: Partial<BankAccount>,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities: BankAccount;

    try {
      ({
        response: { entities },
      } = yield* _await(
        api.updateBankAccount(rootStore.authStore.token, id, data),
      ));
    } catch (error) {
      console.warn('[DEBUG] error updating bank account', error);
      return getError(error);
    }
    this.createOrUpdateBankAccounts(entities);
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  transferFromUgamiToBank = _async(function* (
    this: PaymentStore,
    id: string,
    amount: number,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }
    this.loading = true;

    let entities: any;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.ugamiTransferToBank(rootStore.authStore.token, {
          bank_id: id,
          amount,
        }),
      ));
    } catch (error) {
      console.warn('[DEBUG] error transfer from ugami', error);
      return getError(error);
    }
    this.setAuthorizationId(entities.data.id);
    this.loading = false;
    return getSuccess(entities);
  });
}
