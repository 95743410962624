import { useStyleSheet, StyleService, Text } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import Background from '../../assets/images/Background.svg';
import Glow from '../../assets/images/Glow.svg';
import LogoUgami from '../../assets/images/Logo_ugami.svg';
import UgamiAppandCards from '../../assets/images/Ugami_App_and_Cards.svg';
import { Props } from '../../types/Object';
import SvgIcon from '../Common/SvgIcon';
import PlayStore from '../PlayStore';

const OnboardingDoneDesktop: React.FC<Props> = (_props) => {
  const styles = useStyleSheet(themedStyles);
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.subContainerTwo}>
        <SvgIcon>
          <Glow style={styles.UgamiAppandCards} />
        </SvgIcon>
        <SvgIcon>
          <UgamiAppandCards style={styles.UgamiAppandCards} />
        </SvgIcon>
      </View>
      <View style={styles.subContainerOne}>
        <SvgIcon>
          <LogoUgami style={styles.logoUgami} />
        </SvgIcon>
        <View style={styles.titleBox_1}>
          <Text style={styles.title_1} category="h1">
            {t<string>('sign_up.onboarding_done.title.text_1')}
          </Text>
          <Text style={styles.title_2} category="h1">
            {t<string>('sign_up.onboarding_done.title.text_2')}
          </Text>
        </View>
        <View style={styles.titleBox_2}>
          <Text style={styles.title_3} category="s2">
            {t<string>('sign_up.onboarding_done.subtitle.text_1')}
          </Text>
          <Text style={styles.title_3} category="s2">
            {t<string>('sign_up.onboarding_done.subtitle.text_2')}
          </Text>
          <Text style={styles.title_3} category="s2">
            {t<string>('sign_up.onboarding_done.subtitle.text_3')}
          </Text>
          <View style={styles.playstore}>
            <PlayStore device="desktop" />
          </View>
        </View>
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    background: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  subContainerOne: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    width: '45%',
  },
  subContainerTwo: {
    flexDirection: 'column',
    justifyContent: 'center',
    alingItems: 'center',
    paddingHorizontal: 40,
    width: '55%',
  },
  title_1: {
    fontFamily: 'Rajdhani_SemiBold',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 26,
    lineHeight: 46,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  title_2: {
    fontFamily: 'Rajdhani_SemiBold',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 46,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 20,
    flex: 1,
  },
  title_3: {
    fontFamily: 'Rajdhani_Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 5,
    color: '#979797',
  },
  titleBox_1: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    width: '70%',
    marginBottom: 10,
  },
  titleBox_2: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    marginTop: 10,
    marginBottom: 10,
  },
  playstore: {
    paddingTop: 25,
    alignItems: 'center',
  },
  logoUgami: {
    width: '50%',
    height: 122,
  },
  UgamiAppandCards: {
    position: 'absolute',
    width: '80%',
    height: '80%',
  },
});

export default OnboardingDoneDesktop;
