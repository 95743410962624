import { Jura_600SemiBold, Jura_700Bold } from '@expo-google-fonts/jura';
import {
  SourceSansPro_400Regular,
  SourceSansPro_600SemiBold,
  SourceSansPro_700Bold,
} from '@expo-google-fonts/source-sans-pro';
export const FontMapping = {
  Jura_600SemiBold,
  Jura_700Bold,
  SourceSansPro_400Regular,
  SourceSansPro_600SemiBold,
  SourceSansPro_700Bold,
  Neuropolitical_Ugami: require('../assets/fonts/Neuropolitical_Ugami.ttf'),
  Rajdhani_Medium: require('../assets/fonts/Rajdhani-Medium.ttf'),
  Rajdhani_SemiBold: require('../assets/fonts/Rajdhani-SemiBold.ttf'),
  Rajdhani_Bold: require('../assets/fonts/Rajdhani-Bold.ttf'),
};

export default Object.keys(FontMapping).reduce(
  (obj, k) => ({ ...obj, [k]: k }),
  {},
);
