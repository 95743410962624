import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import React from 'react';

import NotFound from '../../containers/NotFound';
import SignUp from '../../containers/SignUp';

export type AuthStackParamList = {
  'Create Your Account': undefined;
  'Not Found': undefined;
  'External Link': { url: string; title?: string };
};

export type Test = {
  test: undefined;
};
export type AuthStackNavigation = StackNavigationProp<AuthStackParamList>;
type Props = object;

const Stack = createStackNavigator<AuthStackParamList>();

const AuthStack: React.FC<Props> = (props) => {
  return (
    <Stack.Navigator
      screenOptions={{
        cardStyle: { backgroundColor: 'transparent' },
        animationEnabled: false,
        headerShown: false,
        presentation: 'modal',
      }}
    >
      <Stack.Screen name="Create Your Account" component={SignUp} />
      <Stack.Screen name="Not Found" component={NotFound} />
    </Stack.Navigator>
  );
};

export default AuthStack;
