import { useStyleSheet, StyleService } from '@ui-kitten/components';
import React, { useImperativeHandle, useRef } from 'react';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  interpolate,
  withTiming,
  Easing,
  runOnJS,
} from 'react-native-reanimated';
import VerificationInput from 'react-verification-input';

import { Props } from '../../types/OtpInput';
import { OtpInputHandle } from '../../types/OtpInputHandle';

const OtpInput: React.ForwardRefRenderFunction<OtpInputHandle, Props> = (
  props,
  ref,
) => {
  const {
    testID,
    code,
    setCode,
    codeLength = 6,
    cellSize,
    autoFocus = false,
    selectedIndex = 0,
  } = props;
  const styles = useStyleSheet(themedStyles);
  const translateX = useSharedValue(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const cellAnimatedStyles = useAnimatedStyle(() => {
    const positionX = interpolate(
      translateX.value,
      [0, 0.5, 1, 1.5, 2, 2.5, 3],
      [0, -15, 0, 15, 0, -15, 0],
    );

    return {
      transform: [{ translateX: positionX }],
    };
  }, []);

  const resetCode = () => {
    setCode('');
    if (inputRef && inputRef.current && selectedIndex === 3) {
      inputRef.current.value = '';
      inputRef.current.focus();
    }
  };

  const shake = () => {
    translateX.value = 0;
    translateX.value = withTiming(
      3,
      {
        duration: 400,
        easing: Easing.out(Easing.bounce),
      },
      (finished?: boolean) => {
        if (finished) {
          runOnJS(resetCode)();
        }
      },
    );
  };

  const focus = () => {
    if (inputRef && inputRef.current && selectedIndex === 3) {
      inputRef.current.focus();
    }
  };

  useImperativeHandle(ref, () => ({
    shake,
    focus,
  }));

  const onChangeText = (text: string) => {
    if (text.length > codeLength) {
      text = text.substring(0, codeLength);
    }
    const newText = text.replace(/[^0-9]/g, '');
    setCode(newText);
  };

  return (
    <Animated.View
      style={[
        {
          flex: 1,
          height: cellSize,
          overflow: 'hidden',
        },
        cellAnimatedStyles,
      ]}
    >
      <Animated.View
        style={[
          styles.codeContainer,
          {
            height: cellSize,
          },
        ]}
      />
      <VerificationInput
        ref={inputRef}
        validChars="0-9"
        value={code}
        autoFocus={autoFocus}
        onChange={onChangeText}
        classNames={{
          container: 'container',
          character: 'character',
          characterInactive: 'bit--inactive',
          characterSelected: 'character--selected',
        }}
      />
    </Animated.View>
  );
};

const themedStyles = StyleService.create({
  codeContainer: {
    position: 'absolute',
    width: '100%',
    flexDirection: 'row',
  },
  cursor: {
    width: 1,
    height: 18,
    backgroundColor: 'white',
  },
  input: {
    outlineStyle: 'none',
    backgroundColor: 'transparent',
    width: '100%',
    fontSize: 1,
    color: 'transparent',
  },
  text: {
    fontFamily: 'SourceSansPro_600SemiBold',
    fontSize: 18,
    color: 'text-primary',
  },
  cellStyle: {
    borderColor: 'gray-02',
    borderWidth: 1,
    borderRadius: 2,
    backgroundColor: 'brand-field-background',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellStyleFocused: {
    borderColor: 'red-03',
    borderWidth: 1,
    borderRadius: 2,
    backgroundColor: 'brand-field-background',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default React.forwardRef(OtpInput);
