import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { DepositFrequency } from '../constants/Deposit';

@model('ugami-app/RecurringDeposit')
export default class RecurringDeposit extends Model({
  id: prop<number>(),
  bank: prop<string>(''),
  amount: prop<number>(0),
  frequency: prop<DepositFrequency>(),
  dayOfWeek: prop<number>(),
  dayOfMonth: prop<number>(),
  isLastDayOfMonth: prop<boolean>(false),
  isEnabled: prop<boolean>(false),
  createdAt: prop<string>(''),
  previousDepositDate: prop<string>(''),
  nextDepositDate: prop<string>(''),
}) {
  @modelAction
  update(data: ModelCreationData<RecurringDeposit>) {
    Object.assign(this, data);
  }
}
