import {
  Text,
  useStyleSheet,
  StyleService,
  Input,
  CheckBox,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View, TouchableWithoutFeedback } from 'react-native';

import AddressInputs from '../../components/Address/AddressInputs';
import ContainerScrollView from '../../components/Common/ContainerScrollView';
import LabeledInput from '../../components/Common/LabeledInput';
import NextButton from '../../components/UgamiCard/NextButton';
import { CALLING_CODE } from '../../constants/CallingCode';
import { COUNTRY } from '../../constants/Country';
import { useStore } from '../../stores';
import { Props, ContactInfoData } from '../../types/ContactInfo';
import { track } from '../../utils/analytics';
import regexEmail from '../../utils/regexEmail';

const ContactInfo: React.FC<Props> = ({
  onNext,
  onSubmit,
  setIsShippingChanged,
  isShippingChanged,
  selectedIndex,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const { userStore, authStore } = useStore();
  const { register, setValue, getValues, watch, reset, unregister } =
    useForm<ContactInfoData>();
  const [email] = useState<string>(authStore.user?.email || '');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isPhoneNumberExist, setIsPhoneNumberExist] = useState(false);
  const [shippingPostalCodeError, setShippingPostalCodeError] = useState('');
  const phoneRef = useRef<Input>(null);

  useEffect(() => {
    register('street', { required: true });
    register('street2', { required: false });
    register('city', { required: true });
    register('postalCode', { required: true });
    register('country', { required: true });
    register('countryCode', { required: true });
    register('email', {
      required: 'email is required',
      pattern: {
        value: regexEmail,
        message: 'invalid email',
      },
    });
    register('phoneNumber', { required: true });
    register('addressId', { required: false });
    register('phoneId', { required: false });

    setValue('addressId', '');
    setValue('phoneId', '');
    setValue('country', COUNTRY);
    setValue('countryCode', CALLING_CODE);
    setValue('email', authStore.user?.email || '');
  }, [register]);

  useEffect(() => {
    if (isShippingChanged) {
      register('shippingAddress', { required: true });
      register('shippingAddress.street', { required: true });
      register('shippingAddress.street2', { required: true });
      register('shippingAddress.city', { required: true });
      register('shippingAddress.state', { required: true });
      register('shippingAddress.postalCode', { required: true });
      register('shippingAddress.country', { required: true });

      setValue('shippingAddress.country', COUNTRY);
    } else {
      reset({ ...getValues(), shippingAddress: undefined });
      unregister('shippingAddress');
    }
  }, [isShippingChanged]);

  useEffect(() => {
    const address = Array.from(userStore.addresses.values()).find(
      (value) => value.isPrimary,
    );
    if (address) {
      setValue('street', address.street1);
      setValue('street2', address.street2);
      setValue('city', address.city);
      setValue('state', address.region);
      setValue('postalCode', address.postalCode);
      setValue('addressId', `${address.id}`);
    }

    const phone = Array.from(userStore.phoneNumbers.values()).find(
      (value) => value.isPrimary,
    );
    if (phone) {
      const phoneNumber = phone.number
        .replace(`+${CALLING_CODE}`, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      setPhoneNumber(phoneNumber);
      setValue('phoneNumber', phoneNumber);
      setValue('phoneId', `${phone.id}`);
    }
  }, []);

  const onSubmitPressed = async () => {
    const values = getValues();

    // If address values changed, remove address id from values.
    const addressId = values['addressId'];
    if (addressId.length > 0) {
      const address = userStore.addresses.get(addressId);
      if (
        !address ||
        address.street1 !== values['street'] ||
        address.street2 !== values['street2'] ||
        address.city !== values['city'] ||
        address.region !== values['state'] ||
        address.postalCode !== values['postalCode']
      ) {
        values['addressId'] = '';
      }
    }

    // If phone number is different, remove phone number id from values.
    // Clean phone number.
    const phoneId = values['phoneId'];
    const phoneNumber = values['phoneNumber'].replace(/[^\d]/g, '');
    values['phoneNumber'] = phoneNumber;
    if (phoneId.length > 0) {
      const phone = userStore.phoneNumbers.get(phoneId);
      if (!phone || phone.number !== `+${CALLING_CODE}${phoneNumber}`) {
        values['phoneId'] = '';
      }
    }

    const phoneData = {
      title: 'Ugami Card Application Phone Number',
      number: `+1${phoneNumber}`,
      isPrimary: true,
    };
    if (!userStore.phoneNumberExist(phoneData.number)) {
      setLoading(true);
      setIsPhoneNumberExist(false);

      const result = await userStore.phoneNumberExists(phoneData as any);
      setLoading(false);
      if (result.ok) {
        if (result.extra?.exist) {
          setIsPhoneNumberExist(true);
          return;
        }
      }
    }

    track('Completed Contact Information');
    onSubmit(values);
    onNext();
  };

  const renderCallingCode = () => (
    <View style={styles.callingCodeContainer}>
      <Text style={styles.callingCode}>{`+${CALLING_CODE}`}</Text>
    </View>
  );

  const formatNumber = (number: string) => {
    number = number.replace(/[^\d]/g, '');
    if (number) {
      const length = number.length;
      if (length >= 4 && length < 7) {
        number = `${number.slice(0, 3)}-${number.slice(3)}`;
      } else if (length >= 7) {
        number = `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(
          6,
          10,
        )}`;
      }
    }
    return number;
  };

  const onChangePhoneNumber = (text: string) => {
    setIsPhoneNumberExist(false);
    const phoneNumber = formatNumber(text);
    setValue('phoneNumber', phoneNumber);
    setPhoneNumber(phoneNumber);
  };

  const disabledContactInfo =
    !watch('street') ||
    watch('street') === '' ||
    !watch('city') ||
    watch('city') === '' ||
    !watch('state') ||
    watch('state') === '' ||
    !watch('postalCode') ||
    watch('postalCode') === '' ||
    email === '' ||
    phoneNumber === '' ||
    phoneNumber.length < 12 ||
    postalCodeError !== '' ||
    loading;

  const disabledShipping =
    !watch('shippingAddress.street') ||
    watch('shippingAddress.street') === '' ||
    !watch('shippingAddress.city') ||
    watch('shippingAddress.city') === '' ||
    !watch('shippingAddress.state') ||
    watch('shippingAddress.state') === '' ||
    !watch('shippingAddress.postalCode') ||
    watch('shippingAddress.postalCode') === '' ||
    shippingPostalCodeError !== '';

  const disabled = isShippingChanged
    ? disabledContactInfo || disabledShipping
    : disabledContactInfo;

  if (selectedIndex !== 2) {
    return <View />;
  }

  return (
    <ContainerScrollView testID="ContactInformationScrollView">
      <Text style={styles.header}>
        {t<string>('card_application.contact_information.title')}
      </Text>
      <Text style={styles.helperText}>
        {t<string>('card_application.contact_information.subtitle')}
      </Text>
      <View>
        <AddressInputs
          street1={watch('street')}
          setStreet1={(text) => setValue('street', text)}
          street2={watch('street2')}
          setStreet2={(text) => setValue('street2', text)}
          city={watch('city')}
          setCity={(text) => setValue('city', text)}
          state={watch('state')}
          setState={(value: string) => setValue('state', value)}
          postalCode={watch('postalCode')}
          setPostalCode={(text) => setValue('postalCode', text)}
          postalCodeError={postalCodeError}
          setPostalCodeError={setPostalCodeError}
          ugamiCardApp
        />
        <LabeledInput label="Email" value={email || ''} disabled />
        <LabeledInput
          ref={phoneRef}
          value={phoneNumber || ''}
          label={t<string>('card_application.personal_information.phone')}
          placeholder="555-555-5555"
          keyboardType="phone-pad"
          autoCapitalize="words"
          autoCorrect={false}
          returnKeyType="done"
          onChangeText={onChangePhoneNumber}
          maxLength={12}
          accessoryLeft={renderCallingCode}
          error={
            isPhoneNumberExist
              ? { message: 'number already exists' }
              : undefined
          }
          testID="UgamiCardApplicationPhoneNumber"
        />
        <TouchableWithoutFeedback
          onPress={() => setIsShippingChanged(!isShippingChanged)}
        >
          <View style={styles.checkboxRow}>
            <CheckBox
              status="basic"
              checked={isShippingChanged}
              onChange={(checked) => setIsShippingChanged(checked)}
            />
            <View style={styles.checkboxTextContainer}>
              <Text style={styles.checkboxText}>
                {t<string>('card_application.contact_information.check_box')}
              </Text>
            </View>
          </View>
        </TouchableWithoutFeedback>
        {isShippingChanged && (
          <>
            <Text style={styles.header}>
              {t<string>(
                'card_application.contact_information.card_shipping_address',
              )}
            </Text>
            <Text style={styles.helperText}>
              {t<string>(
                'card_application.contact_information.shipping_subtitle',
              )}
            </Text>
            <AddressInputs
              street1={watch('shippingAddress.street')}
              setStreet1={(text) => setValue('shippingAddress.street', text)}
              street2={watch('shippingAddress.street2')}
              setStreet2={(text) => setValue('shippingAddress.street2', text)}
              city={watch('shippingAddress.city')}
              setCity={(text) => setValue('shippingAddress.city', text)}
              state={watch('shippingAddress.state')}
              setState={(value: string) =>
                setValue('shippingAddress.state', value)
              }
              postalCode={watch('shippingAddress.postalCode')}
              setPostalCode={(text) =>
                setValue('shippingAddress.postalCode', text)
              }
              postalCodeError={shippingPostalCodeError}
              setPostalCodeError={setShippingPostalCodeError}
              ugamiCardApp
            />
          </>
        )}

        <View
          style={[
            styles.nextButton,
            isShippingChanged ? { marginTop: 35 } : { marginTop: 0 },
          ]}
        >
          <NextButton
            disabled={disabled}
            onNextPressed={onSubmitPressed}
            loading={loading}
            testID="ContactInformationNextButton"
          />
        </View>
      </View>
    </ContainerScrollView>
  );
};

const themedStyles = StyleService.create({
  row: {
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: 'app-background',
  },
  header: {
    fontSize: 23,
    lineHeight: 32,
    fontFamily: 'Jura_700Bold',
    color: 'text-primary',
    textAlign: 'left',
  },
  imageBg: {
    flex: 1,
  },
  scrollContainer: {
    flex: 1,
  },
  nextButton: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 35,
  },
  callingCodeContainer: {
    padding: 8,
    marginRight: 8,
    marginLeft: -8,
    backgroundColor: 'rgba(196, 196, 196, 0.15)',
    borderRadius: 4,
  },
  callingCode: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
  },
  helperText: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    lineHeight: 24,
    marginTop: 12,
  },
  checkboxText: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    lineHeight: 25,
  },
  checkboxRow: {
    flexDirection: 'row',
    paddingTop: 14,
    paddingBottom: 2,
    marginTop: 24,
    marginBottom: 35,
    alignItems: 'flex-start',
  },
  checkboxTextContainer: {
    flex: 1,
    paddingLeft: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  highlight: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_700Bold',
    lineHeight: 20,
    color: 'red-02',
  },
});

export default observer(ContactInfo);
