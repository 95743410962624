const sign_in = {
  email: 'Email',
  password: 'Password',
  password_placeholder: 'supersecurepassword',
  forgot_password: 'Forgot Password?',
  sign_in: 'SIGN IN',
  get_started: 'GET STARTED',
  ugami: 'Want to know more about Ugami?',
  learn_more: 'Learn More',
};

export { sign_in };
