import { useNavigation } from '@react-navigation/native';
import { useStyleSheet, StyleService } from '@ui-kitten/components';
import { format } from 'date-fns';
import { get, isUndefined } from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Keyboard } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import SecondaryContainerView from '../../components/Common/SecondaryContainerView';
import StepProgressBar from '../../components/Common/StepProgressBar';
import ViewPager from '../../components/Common/ViewPager';
import ConfirmInfo from '../../components/UgamiCardApplication/ConfirmInfo';
import ContactInfo from '../../components/UgamiCardApplication/ContactInfo';
import PersonalInfo from '../../components/UgamiCardApplication/PersonalInfo';
import UgamiCardSelector from '../../components/UgamiCardApplication/UgamiCardSelector';
import VerifyPhoneNumber from '../../components/UgamiCardApplication/VerifyPhoneNumber';
import config from '../../config';
import { AccountType } from '../../constants/AccountType';
import { UgamiCardApplicationStatus } from '../../constants/UgamiCardApplicationStatus';
import {
  ApplicationFormData,
  DepositAccountFormData,
  DebitCardFormData,
  Address,
} from '../../constants/UgamiCardFormData';
import { AppStackNavigation } from '../../navigation';
import { useStore } from '../../stores';
import { ContactInfoData } from '../../types/ContactInfo';
import { PersonalInfoData } from '../../types/PersonalInfo';
import { track } from '../../utils/analytics';
import { getErrors } from '../../utils/errors';
import { createUgamiDepositAccount } from '../../utils/unit';

type Props = object;
const MAX_TAB = 5;
const GREETING_TABS = 2;
const UgamiCardApplication: React.FC<Props> = (_props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation<AppStackNavigation>();
  const store = useStore();
  const { ugamiCardStore, userStore, authStore, showToastMessage } = store;
  const steps = 100;
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState<AccountType>(
    AccountType.DEBIT_BOOSTED,
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoData>({
    firstName: '',
    lastName: '',
    birthday: new Date(),
    securityNumber: '',
  });
  const [contactInfo, setContactInfo] = useState<ContactInfoData>({
    street: '',
    street2: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US',
    countryCode: '1',
    phoneNumber: '',
    addressId: '',
    phoneId: '',
    email: '',
    shippingAddress: {
      street: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'US',
    },
  });
  const [toastVisible, setToastVisible] = useState(
    accountType === AccountType.DEBIT,
  );
  const [isShippingChanged, setIsShippingChanged] = useState(false);

  useEffect(() => {
    Keyboard.dismiss();
    store.setFirstTimeNavigation(false);
  }, []);

  const onClose = () => {
    navigation.goBack();
  };

  const onGoToHome = () => {
    navigation.navigate('OnboardingDone');
  };

  const onNext = (next: number) => {
    Keyboard.dismiss();
    setSelectedIndex(next);
  };

  const onGoBack = () => {
    Keyboard.dismiss();
    if (selectedIndex === 4) {
      setSelectedIndex(selectedIndex - 2);
      return;
    }
    setSelectedIndex(selectedIndex - 1);
  };

  const onGoBackToStep = (index: number) => {
    setSelectedIndex(index);
  };

  const onPersonalInfoEdit = () => {
    onGoBackToStep(1);
  };

  const onContactInfoEdit = () => {
    onGoBackToStep(2);
  };

  const setPhoneId = useCallback((id) => {
    setContactInfo((contactInfo) => ({
      ...contactInfo,
      phoneId: id,
    }));
  }, []);

  const getAddressData = ():
    | { addressId: number }
    | { applicationAddress: Address } => {
    return !contactInfo.addressId || contactInfo.addressId === ''
      ? {
          applicationAddress: {
            title: 'Ugami Card Application Address',
            street1: contactInfo.street,
            street2: contactInfo.street2,
            city: contactInfo.city,
            region: contactInfo.state,
            postalCode: contactInfo.postalCode,
            country: contactInfo.country,
          },
        }
      : { addressId: Number(contactInfo.addressId) };
  };

  const getShippingAddressData = () => {
    return {
      title: 'Ugami Card Shipping Address',
      street1: contactInfo.shippingAddress.street,
      street2: contactInfo.shippingAddress.street2,
      city: contactInfo.shippingAddress.city,
      region: contactInfo.shippingAddress.state,
      postalCode: contactInfo.shippingAddress.postalCode,
      country: contactInfo.shippingAddress.country,
    };
  };

  const onSubmit = useCallback(async () => {
    if (loading) {
      return;
    }
    const depositAccountData: DepositAccountFormData = {
      depositProduct:
        // Accounts are automatically set to Boosted during Beta period
        // TODO: Change to accountType after Beta period
        config.env === 'production' ? AccountType.DEBIT_BOOSTED : 'checking',
    };

    const applicationData: ApplicationFormData = {
      ssn: personalInfo.securityNumber,
      fullName: {
        first: personalInfo.firstName,
        last: personalInfo.lastName,
      },
      dateOfBirth: format(personalInfo.birthday, 'yyyy-MM-dd'),
      ...getAddressData(),
      ...(isShippingChanged &&
        !isUndefined(contactInfo.shippingAddress) && {
          cardShippingAddress: getShippingAddressData(),
        }),
      email: contactInfo.email,
      phone: {
        countryCode: contactInfo.countryCode,
        number: contactInfo.phoneNumber,
      },
      phoneId:
        !contactInfo.phoneId || contactInfo.phoneId === ''
          ? undefined
          : parseInt(contactInfo.phoneId, 10),
      depositProduct: depositAccountData.depositProduct,
      selectedDepositProduct: accountType,
    };

    const debitCardData: DebitCardFormData = {
      ...(isShippingChanged && !isUndefined(contactInfo.shippingAddress)
        ? { applicationAddress: getShippingAddressData() }
        : getAddressData()),
    };

    setErrorMessage('');
    setLoading(true);
    let result;

    if (!ugamiCardStore.cardApplication) {
      track('Application - Submitted');
      result = await ugamiCardStore.createUgamiCardApplication(applicationData);
      if (!result.ok) {
        setErrorMessage(getErrors(result.errors));
        setLoading(false);
        return;
      }

      authStore.updateUserUnitAccountType(depositAccountData.depositProduct);

      if (result.extra?.needAdditionalVerification) {
        track('Application - Documents Required');
        navigation.navigate('OnboardingDone');
        return;
      }

      const status = get(ugamiCardStore.cardApplication, 'attributes.status');
      if (status === UgamiCardApplicationStatus.PENDING) {
        track('Application - In Review');
      }
    }

    // update user full name
    if (authStore.user?.firstName === '' && authStore.user?.firstName === '') {
      await userStore.fetchMe();
    }

    const depositAccount = await createUgamiDepositAccount(
      store,
      depositAccountData,
      debitCardData,
    );

    if (depositAccount) {
      setErrorMessage(depositAccount);
      setLoading(false);
      navigation.navigate('OnboardingDone');
      return;
    }

    setLoading(false);
    navigation.navigate('OnboardingDone');
  }, [loading, personalInfo, contactInfo, accountType, onNext]);

  return (
    <SecondaryContainerView
      title={t<string>('card_application.header_title')}
      onGoBack={selectedIndex === 0 ? undefined : onGoBack}
      isChildStack={selectedIndex !== 0}
      gradientVisible={false}
    >
      <View
        style={
          isMobile
            ? { flex: 1 }
            : [
                styles.container,
                { overflow: selectedIndex === 3 ? 'visible' : 'hidden' },
              ]
        }
      >
        <StepProgressBar
          steps={steps}
          maxTab={MAX_TAB - GREETING_TABS}
          selectedIndex={selectedIndex}
        />
        <View>
          <ViewPager
            style={styles.pager}
            selectedIndex={selectedIndex}
            swipeEnabled={false}
            testID="UgamiCardApplicationViewPager"
          >
            <UgamiCardSelector
              accountType={accountType}
              setAccountType={setAccountType}
              onNext={() => onNext(1)}
              selectedIndex={selectedIndex}
            />
            <PersonalInfo
              onNext={() => onNext(2)}
              onSubmit={setPersonalInfo}
              selectedIndex={selectedIndex}
            />
            <ContactInfo
              onNext={() => onNext(3)}
              onSubmit={setContactInfo}
              setIsShippingChanged={setIsShippingChanged}
              isShippingChanged={isShippingChanged}
              selectedIndex={selectedIndex}
            />
            <VerifyPhoneNumber
              onNext={() => onNext(4)}
              isFocus={selectedIndex === MAX_TAB - GREETING_TABS}
              phoneNumber={contactInfo.phoneNumber}
              setPhoneId={setPhoneId}
              selectedIndex={selectedIndex}
            />
            <ConfirmInfo
              onNext={onSubmit}
              onPersonalInfoEdit={onPersonalInfoEdit}
              onContactInfoEdit={onContactInfoEdit}
              personalInfo={personalInfo}
              contactInfo={contactInfo}
              loading={loading}
              errorMessage={errorMessage}
              isShippingChanged={isShippingChanged}
              selectedIndex={selectedIndex}
            />
          </ViewPager>
        </View>
      </View>
    </SecondaryContainerView>
  );
};

const themedStyles = StyleService.create({
  container: {
    width: 575,
    background: '#292828',
    borderRadius: 5,
    marginBottom: 40,
  },
  pager: {
    flex: 1,
    width: '100%',
  },
  box: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default UgamiCardApplication;
