import { default as snakeCaseKeys } from 'snakecase-keys';

import config from '../../config';
import {
  ApplicationFormData,
  DepositAccountFormData,
  DebitCardFormData,
  CustomerVerificationTokenFormData,
  CustomerTokenFormData,
  VirtualDebitCardFormData,
  ReplacementDebitCardFormData,
  FeeFormData,
} from '../../constants/UgamiCardFormData';
import { callApiWithToken, prepareFileForUpload } from './base';

export const createUgamiCardApplication = async (
  token: string,
  data: Partial<ApplicationFormData>,
) =>
  callApiWithToken(
    config.urls.applications,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const createDepositAccount = async (
  token: string,
  data: Partial<DepositAccountFormData>,
) =>
  callApiWithToken(
    config.urls.accounts,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const createDebitCard = async (
  token: string,
  data: Partial<DebitCardFormData>,
) =>
  callApiWithToken(
    config.urls.ugamiCards,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const createVirtualDebitCard = async (
  token: string,
  data: Partial<VirtualDebitCardFormData>,
) =>
  callApiWithToken(
    config.urls.ugamiVirtualCards,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const createCustomerVerificationToken = async (
  token: string,
  data: Partial<CustomerVerificationTokenFormData>,
) =>
  callApiWithToken(
    config.urls.createCustomerVerificationToken,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const createCustomerToken = async (
  token: string,
  data: Partial<CustomerTokenFormData>,
) =>
  callApiWithToken(
    config.urls.createCustomerToken,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const replaceCard = async (
  token: string,
  id: string,
  data: Partial<ReplacementDebitCardFormData>,
) =>
  callApiWithToken(
    `${config.urls.ugamiCards}${id}/replace-card/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const createFee = async (token: string, data: FeeFormData) =>
  callApiWithToken(
    config.urls.createFee,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchUgamiCards = async (token: string) =>
  callApiWithToken(
    `${config.urls.ugamiCards}?limit=100&offset=0`,
    token,
    'GET',
  );

export const fetchUgamiDepositAccounts = async (token: string) =>
  callApiWithToken(`${config.urls.accounts}?limit=100&offset=0`, token, 'GET');

export const fetchUgamiCardApplication = async (
  token: string,
  applicationId: string,
) =>
  callApiWithToken(
    `${config.urls.applications}${applicationId}/`,
    token,
    'GET',
  );

export const fetchUgamiCardApplicationDocuments = async (
  token: string,
  applicationId: string,
) =>
  callApiWithToken(
    `${config.urls.applications}${applicationId}/documents/`,
    token,
    'GET',
  );

export const fetchPinStatus = async (token: string, id: string) =>
  callApiWithToken(`${config.urls.ugamiCards}${id}/pin-status/`, token, 'GET');

export const uploadCardApplicationDocument = async (
  token: string,
  photo: any,
  application_id: string,
  document_id: string,
  isBackSide: string,
) => {
  const data = new FormData();
  const file = await prepareFileForUpload(photo);
  data.append('file', file);
  data.append('file_type', 'jpeg');
  data.append('is_back_side', isBackSide);
  return callApiWithToken(
    `${config.urls.applications}${application_id}/documents/${document_id}/`,
    token,
    'PUT',
    { 'Content-Type': 'multipart/form-data' },
    data,
  );
};

export const lockUgamiCard = async (token: string, id: string) =>
  callApiWithToken(`${config.urls.ugamiCards}${id}/lock/`, token, 'POST');

export const unlockUgamiCard = async (token: string, id: string) =>
  callApiWithToken(`${config.urls.ugamiCards}${id}/unlock/`, token, 'POST');

export const reportLost = async (token: string, id: string) =>
  callApiWithToken(
    `${config.urls.ugamiCards}${id}/report-lost/`,
    token,
    'POST',
  );

export const reportStolen = async (token: string, id: string) =>
  callApiWithToken(
    `${config.urls.ugamiCards}${id}/report-stolen/`,
    token,
    'POST',
  );

export const close = async (token: string, id: string) =>
  callApiWithToken(`${config.urls.ugamiCards}${id}/close/`, token, 'POST');

export const getMobileWalletPayload = async (
  token: string,
  id: string,
  data: { signedNonce: string },
) =>
  callApiWithToken(
    `${config.urls.ugamiCards}${id}/mobile-wallet-payload/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );
