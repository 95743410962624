import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  getRoot,
  prop,
  modelAction,
} from 'mobx-keystone';

import Status from '../models/Status';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';
import Store from './Store';

@model('ugami-app/StatusStore')
export default class StatusStore extends Model({
  status: prop<Status | null>(null),
}) {
  @observable
  loading = false;

  @modelAction
  setStatus = (entities: any | null) => {
    if (entities) {
      entities.totalEarnedPoints = Math.floor(entities.totalEarnedPoints);
      if (this.status) {
        this.status.update(entities);
      } else {
        this.status = new Status(entities);
      }
    }
  };

  @modelFlow
  fetchStatus = _async(function* (this: StatusStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: any;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchStatus(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching status', error);
      return getError(error);
    }

    this.setStatus(entities);

    this.loading = false;
    return getSuccess();
  });
}
