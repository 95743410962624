import { debounce } from 'lodash';
import { onSnapshot, registerRootStore } from 'mobx-keystone';
import React from 'react';

import { StorageService } from '../services/storage/storageService';
import AuthStore from './AuthStore';
import LanguageStore from './LanguageStore';
import PaymentStore from './PaymentStore';
import StatusStore from './StatusStore';
import Store from './Store';
import TransactionsStore from './TransactionsStore';
import UgamiCardStore from './UgamiCardStore';
import UserStore from './UserStore';

const StoreContext = React.createContext<Store>({} as Store);

const useStore = () => React.useContext(StoreContext);
const { Provider: StoreProvider } = StoreContext;

const createStore = (storageService: StorageService) => {
  const authStore = new AuthStore({});
  const paymentStore = new PaymentStore({});
  const statusStore = new StatusStore({});
  const transactionsStore = new TransactionsStore({});
  const ugamiCardStore = new UgamiCardStore({});
  const userStore = new UserStore({});
  const languageStore = new LanguageStore({});

  const store = new Store({
    authStore,
    paymentStore,
    statusStore,
    transactionsStore,
    ugamiCardStore,
    userStore,
    languageStore,
  });
  authStore.storageService = storageService;
  store.storageService = storageService;

  registerRootStore(store);
  onSnapshot(
    store,
    debounce(() => {
      store.save();
    }, 3000),
  );

  return store;
};

export { Store, StoreProvider, createStore, useStore };
