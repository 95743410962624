import { useStyleSheet, StyleService, Text } from '@ui-kitten/components';
import React from 'react';
import { View } from 'react-native';

import ErrorIcon from '../../assets/images/error-icon.svg';
import { Props } from '../../types/ErrorMessage';
import SvgIcon from '../Common/SvgIcon';

const ErrorMessage: React.FC<Props> = ({ errorMessage, style, testID }) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <>
      {!!errorMessage && (
        <View style={[styles.errorContainer, style]} testID={testID}>
          <SvgIcon>
            <ErrorIcon />
          </SvgIcon>
          <Text style={styles.error}>{errorMessage}</Text>
        </View>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  errorContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    marginTop: 16,
  },
  error: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    paddingLeft: 8,
    color: 'orange-03',
    textTransform: 'capitalize',
  },
});

export default ErrorMessage;
