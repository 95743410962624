const common = {
  buttons: {
    yes_up: 'YES',
    next_up: 'NEXT',
    cancel_up: 'CANCEL',
    submit_up: 'SUBMIT',
  },
  tabs: {
    home: 'HOME',
    friends: 'FRIENDS',
    store: 'STORE',
    ugipoints: 'UGIPOINTS',
  },
  spin: 'Spin!',
  user_messages: {
    email_verified: 'Email successfully verified!',
    email_updated: 'Email successfully updated!',
    email_exists: 'email already exists',
    user_email_exists: 'user with this email already exists',
    email_required: 'email is required',
    email_invalid: 'invalid email',
  },
  messages: {
    invalid_email_password: 'Invalid email or password.',
  },
  request_account_deletion: 'Request Account Deletion',
  join_community: 'Join The Community',
  choose_account: 'Choose Account',
  messages: {
    invalid_email_password: 'Invalid email or password.',
  },
  words: {
    or: 'or',
  },
};

export { common };
