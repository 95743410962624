import * as ImageManipulator from 'expo-image-manipulator';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import * as MediaLibrary from 'expo-media-library';
import { last } from 'lodash';

const ALLOWED_FILE_EXTENSION = [
  'bmp',
  'dib',
  'gif',
  'tif',
  'tiff',
  'jfif',
  'jpe',
  'jpg',
  'jpeg',
  'pbm',
  'pgm',
  'ppm',
  'pnm',
  'png',
  'apng',
  'blp',
  'bufr',
  'cur',
  'pcx',
  'dcx',
  'dds',
  'ps',
  'eps',
  'fit',
  'fits',
  'fli',
  'flc',
  'ftc',
  'ftu',
  'gbr',
  'grib',
  'h5',
  'hdf',
  'jp2',
  'j2k',
  'jpc',
  'jpf',
  'jpx',
  'j2c',
  'icns',
  'ico',
  'im',
  'iim',
  'mpg',
  'mpeg',
  'mpo',
  'msp',
  'palm',
  'pcd',
  'pdf',
  'pxr',
  'psd',
  'bw',
  'rgb',
  'rgba',
  'sgi',
  'ras',
  'tga',
  'icb',
  'vda',
  'vst',
  'webp',
  'wmf',
  'emf',
  'xbm',
  'xpm',
];

const getNameAndType = (uri: string) => {
  if (uri.startsWith('data:image/jpeg;')) {
    return {
      name: 'image.jpeg',
      type: 'jpeg',
    };
  }
  if (uri.startsWith('data:image/png;')) {
    return {
      name: 'image.png',
      type: 'png',
    };
  }

  const name = last(uri.split('?')[0].split('/'));
  const type = name ? last(name.split('.'))?.toLowerCase() : '';

  return {
    name,
    type,
  };
};

export const formatPhoto = (photo: ImageInfo | MediaLibrary.Asset | any) => {
  const uri = 'photo' in photo ? photo.photo.fullSize : photo.uri;
  const { name, type } = getNameAndType(uri);
  return {
    ...photo,
    id: 'id' in photo ? photo.id : uri,
    photo: {
      fullSize: uri,
    },
    name: `Photo-${name}`,
    type: `image/${type}`,
  };
};

export const convertPhoto = async (photo: any) => {
  if (ALLOWED_FILE_EXTENSION.includes(photo.type)) {
    return photo;
  }

  const maxWidth = 1284;
  const { width } = photo;

  const { uri } = await ImageManipulator.manipulateAsync(photo.uri, [
    { resize: { width: width > maxWidth ? maxWidth : width } },
  ]);
  const { name, type } = getNameAndType(uri);
  return {
    ...photo,
    uri,
    photo: {
      fullSize: uri,
    },
    name: `Photo-${name}`,
    type: `image/${type}`,
  };
};
