import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('ugami-app/User')
export default class User extends Model({
  id: prop<number>(),
  firstName: prop<string>(''),
  lastName: prop<string>(''),
  fullName: prop<string>(''),
  email: prop<string>(''),
  temporaryEmail: prop<string>(''),
  hasVerifiedEmail: prop<boolean>(false),
  dateOfBirth: prop<string>(''),
  gender: prop<string>(''),
  gamerTag: prop<string>(''),
  gamerTagNumber: prop<string>(''),
  profileImage: prop<string>(''),
  profileImageThumbnail: prop<string>(''),
  referralCode: prop<string>(''),
  referrerCode: prop<string>(''),
  dateJoined: prop<string>(''),
  weeklyLoginCount: prop<number>(1),
  submittedNameChange: prop<boolean>(false),
  shopifyCustomerId: prop<number>(),
  unitApplicationId: prop<string>(''),
  unitCustomerId: prop<string>(''),
  unitDepositAccountId: prop<string>(''),
  unitDepositAccountType: prop<string>(''),
  selectedDepositProduct: prop<string>(''),
  unitDocumentUploadTime: prop<string>(''),
  pushNotificationOn: prop<boolean>(true),
  ugipoints: prop<number>(0),
}) {
  @modelAction
  update(data: ModelCreationData<User>) {
    Object.assign(this, data);
  }

  @modelAction
  updateHasVerifiedEmail() {
    this.hasVerifiedEmail = true;
    if (this.temporaryEmail && this.temporaryEmail !== '') {
      this.email = this.temporaryEmail;
    }
    this.temporaryEmail = '';
  }

  @modelAction
  updateUnitApplicationAccountId(unitApplicationId: string) {
    this.unitApplicationId = unitApplicationId;
  }

  @modelAction
  updateUnitDepositAccountId(unitDepositAccountId: string) {
    this.unitDepositAccountId = unitDepositAccountId;
  }

  @modelAction
  updateUnitDepositAccountType(unitDepositAccountType: string) {
    this.unitDepositAccountType = unitDepositAccountType;
  }

  @modelAction
  updateUnitDocumentUploadTime(unitDocumentUploadTime: string) {
    this.unitDocumentUploadTime = unitDocumentUploadTime;
  }

  @modelAction
  updateUnitCustomerId(unitCustomerId: string) {
    this.unitCustomerId = unitCustomerId;
  }
}
