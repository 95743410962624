import { format, isSameDay, getHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { isUndefined } from 'lodash';
import { computed } from 'mobx';
import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

type TransactionAttributes = object & {
  createdAt: string;
  amount: number;
  direction: string;
  balance: number;
  description: string;
  summary: string;
  cardLast4Digits: string;
  merchant: {
    name: string;
    type: number;
    category: string;
    location: string;
  };
  coordinates: {
    longitude: number;
    latitude: number;
  };
  recurring: boolean;
  tags: any;
};

type RelationshipType = object & {
  type: string;
  id: string;
};

type TransactionRelationship = object & {
  account: { data: RelationshipType };
  customer: { data: RelationshipType };
  customers: { data: RelationshipType[] };
  org: { data: RelationshipType };
};

@model('ugami-app/Transaction')
export default class Transaction extends Model({
  id: prop<string>(''),
  type: prop<string>(''),
  attributes: prop<TransactionAttributes>(),
  relationships: prop<TransactionRelationship>(),
}) {
  @modelAction
  update(data: ModelCreationData<Transaction>) {
    Object.assign(this, data);
  }

  @computed
  get date(): string {
    return format(new Date(this.attributes.createdAt), 'MMM d, yyyy');
  }

  @computed
  get status(): string {
    // adding of ugipoints from ugipoints logs happened every 2 AM EST
    const estTimeNow = utcToZonedTime(new Date(), 'America/New_York');
    const createdAt = new Date(this.attributes.createdAt);

    return getHours(estTimeNow) >= 2 && !isSameDay(estTimeNow, createdAt)
      ? 'Posted'
      : 'Pending';
  }

  @computed
  get cost(): string {
    return (this.attributes.amount / 100.0).toFixed(2);
  }

  @computed
  get isCredit(): boolean {
    return this.attributes.direction === 'Credit';
  }

  @computed
  get isCardTransaction(): boolean {
    return (
      this.attributes.cardLast4Digits !== '' &&
      !isUndefined(this.attributes.cardLast4Digits)
    );
  }

  @computed
  get earnedUgipoints(): string {
    return !isUndefined(this.attributes.tags) &&
      'earnedUgipoints' in this.attributes.tags
      ? this.attributes.tags.earnedUgipoints
      : '';
  }
}
