import { isUndefined } from 'lodash';
import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  objectMap,
  getRoot,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import Address from '../models/Address';
import Phone from '../models/Phone';
import User from '../models/User';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';
import Store from './Store';

@model('ugami-app/UserStore')
export default class UserStore extends Model({
  users: prop(() => objectMap<User>()),
  phoneNumbers: prop(() => objectMap<Phone>()),
  addresses: prop(() => objectMap<Address>()),
  friends: prop(() => objectMap<User>()),
}) {
  @observable
  loading = false;

  // Required for Android.
  // It looks like Android can't handle geocode requests in parallel.
  @observable
  geocoding = false;

  getAddress = (id?: number): Address | undefined => {
    return this.addresses.get(`${id}`);
  };

  getPhoneNumber = (id?: number): Phone | undefined => {
    return this.phoneNumbers.get(`${id}`);
  };

  getUnitPhoneNumber = (): Phone | undefined => {
    return Array.from(this.phoneNumbers.values()).find(
      (p) => p.isUnitPhoneNumber === true,
    );
  };

  phoneNumberExist = (number: string): boolean => {
    return !isUndefined(
      Array.from(this.phoneNumbers.values()).find((p) => p.number === number),
    );
  };

  @modelAction
  setGeocoding(geocoding: boolean) {
    this.geocoding = geocoding;
  }

  @modelAction
  createOrUpdateAddresses(data: ModelCreationData<Address>) {
    const id = `${data.id}`;
    let address: Address;
    if (this.addresses.has(id)) {
      address = this.addresses.get(id)!;
    } else {
      address = new Address(data);
      this.addresses.set(id, address);
    }
    address.update(data);
    return address;
  }

  @modelAction
  deleteAddresses(id: number) {
    const addressId = `${id}`;
    if (this.addresses.has(addressId)) {
      this.addresses.delete(addressId);
    }
  }

  @modelAction
  createOrUpdatePhoneNumbers(data: ModelCreationData<Phone>) {
    const id = `${data.id}`;
    let phoneNumber: Phone;
    if (this.phoneNumbers.has(id)) {
      phoneNumber = this.phoneNumbers.get(id)!;
    } else {
      phoneNumber = new Phone(data);
      this.phoneNumbers.set(id, phoneNumber);
    }
    phoneNumber.update(data);
    return phoneNumber;
  }

  @modelAction
  deletePhoneNumbers(id: number) {
    const phoneNumberId = `${id}`;
    if (this.phoneNumbers.has(phoneNumberId)) {
      this.phoneNumbers.delete(phoneNumberId);
    }
  }

  @modelAction
  createOrUpdateFriends(data: ModelCreationData<User>) {
    const id = `${data.id}`;
    if (this.friends.has(id)) {
      this.friends.get(id)!.update(data);
    } else {
      const friend = new User(data);
      this.friends.set(id, friend);
      friend.update(data);
    }
  }

  @modelFlow
  addAddress = _async(function* (this: UserStore, data: Address) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Address>[];
    let newAddress: ModelCreationData<Address>;
    try {
      ({
        response: { entities: newAddress },
      } = yield* _await(api.addAddress(rootStore.authStore.token, data)));
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchAddresses(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error adding address', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateAddresses(data));

    this.loading = false;
    return getSuccess({ id: newAddress.id });
  });

  @modelFlow
  addPhoneNumber = _async(function* (
    this: UserStore,
    data: {
      number: string;
      title: string;
      isPrimary: boolean;
      isVerified: boolean;
      isUnitPhoneNumber: boolean;
      token: string;
    },
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let phone: ModelCreationData<Phone>;
    let results: ModelCreationData<Phone>[];
    try {
      ({
        response: { entities: phone },
      } = yield* _await(api.addPhoneNumber(rootStore.authStore.token, data)));
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchPhoneNumbers(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error adding phone number', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdatePhoneNumbers(data));

    this.loading = false;
    return getSuccess({ id: phone.id });
  });

  @modelFlow
  resendEmailVerification = _async(function* (this: UserStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    try {
      yield* _await(api.resendEmailVerification(rootStore.authStore.token));
    } catch (error) {
      console.warn('[DEBUG] error resending email verification', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  removeTemporaryEmail = _async(function* (this: UserStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.removeTemporaryEmail(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error deleting temporary email', error);
      return getError(error);
    }

    if (entities) {
      if (rootStore.authStore.user) {
        rootStore.authStore.user.update(entities);
      } else {
        rootStore.authStore.setUser(new User(entities));
      }
    }

    this.loading = false;
    return getSuccess({ user: rootStore.authStore.user });
  });

  @modelFlow
  fetchMe = _async(function* (this: UserStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchMe(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching user data', error);
      return getError(error);
    }

    if (entities) {
      if (rootStore.authStore.user) {
        rootStore.authStore.user.update(entities);
      } else {
        rootStore.authStore.setUser(new User(entities));
      }
    }

    this.loading = false;
    return getSuccess({ user: rootStore.authStore.user });
  });

  @modelFlow
  submitNameChange = _async(function* (this: UserStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    try {
      yield* _await(api.submitChangeName(rootStore.authStore.token));
    } catch (error) {
      console.warn('[DEBUG] error sending name change submission email', error);
      return getError(error);
    }
    this.fetchMe();
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchAddresses = _async(function* (this: UserStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }
    this.loading = true;

    let results: ModelCreationData<Address>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchAddresses(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching addresses', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateAddresses(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchPhoneNumbers = _async(function* (this: UserStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }
    this.loading = true;

    let results: ModelCreationData<Phone>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchPhoneNumbers(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching phone numbers', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdatePhoneNumbers(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  sendVerificationCode = _async(function* (this: UserStore, number: string) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }
    this.loading = true;
    try {
      yield* _await(
        api.sendPhoneVerification(rootStore.authStore.token, number),
      );
    } catch (error) {
      console.warn('[DEBUG] send verification code numbers', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  verifyPhoneNumber = _async(function* (
    this: UserStore,
    number: string,
    verificationCode: string,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }
    this.loading = true;
    try {
      yield* _await(
        api.verifyPhoneNumber(
          rootStore.authStore.token,
          verificationCode,
          number,
        ),
      );
    } catch (error) {
      console.warn('[DEBUG] error verifying phone number', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  phoneNumberExists = _async(function* (this: UserStore, data: Phone) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.phoneNumberExists(rootStore.authStore.token, data),
      ));
    } catch (error) {
      console.warn('[DEBUG] error checking phone number', error);
      return getError(error);
    }
    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFriends = _async(function* (this: UserStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }
    this.loading = true;

    let results: ModelCreationData<User>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchFriends(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching friends', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateFriends(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateMe = _async(function* (this: UserStore, data: Partial<User>) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateMe(rootStore.authStore.token, data)));
      if (entities) {
        if (rootStore.authStore.user) {
          rootStore.authStore.user.update(entities);
        } else {
          rootStore.authStore.setUser(new User(entities));
        }
      }
    } catch (error) {
      console.warn('[DEBUG] error updating me', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateMePhoto = _async(function* (this: UserStore, photo: any) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateMePhoto(rootStore.authStore.token, photo)));
      if (entities) {
        if (rootStore.authStore.user) {
          rootStore.authStore.user.update(entities);
        } else {
          rootStore.authStore.setUser(new User(entities));
        }
      }
    } catch (error) {
      console.warn('[DEBUG] error updating me photo', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateMePhotoUsingDefaultAvatar = _async(function* (
    this: UserStore,
    avatarName: string,
  ) {
    const rootStore = getRoot<Store>(this);
    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.updateMePhotoUsingDefaultAvatar(
          rootStore.authStore.token,
          avatarName,
        ),
      ));
      if (entities) {
        if (rootStore.authStore.user) {
          rootStore.authStore.user.update(entities);
        } else {
          rootStore.authStore.setUser(new User(entities));
        }
      }
    } catch (error) {
      console.warn('[DEBUG] error updating me photo', error);
      return getError(error);
    }
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateAddress = _async(function* (
    this: UserStore,
    id: number,
    data: Address,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Address>[];
    try {
      yield* _await(api.updateAddress(rootStore.authStore.token, id, data));
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchAddresses(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error updating address', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateAddresses(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  deleteAddress = _async(function* (this: UserStore, id: number) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    try {
      yield* _await(api.deleteAddress(rootStore.authStore.token, id));
    } catch (error) {
      console.warn('[DEBUG] error deleting address', error);
      return getError(error);
    }
    this.deleteAddresses(id);
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updatePhoneNumber = _async(function* (
    this: UserStore,
    id: number,
    data: Partial<{
      number: string;
      title: string;
      isPrimary: boolean;
      isVerified: boolean;
      isUnitPhoneNumber: boolean;
      token: string;
    }>,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Phone>[];
    try {
      yield* _await(api.updatePhoneNumber(rootStore.authStore.token, id, data));
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchPhoneNumbers(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error updating phone number', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdatePhoneNumbers(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  deletePhoneNumber = _async(function* (this: UserStore, id: number) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    try {
      yield* _await(api.deletePhoneNumber(rootStore.authStore.token, id));
    } catch (error) {
      console.warn('[DEBUG] error deleting phone number', error);
      return getError(error);
    }

    this.deletePhoneNumbers(id);
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  sendEmail = _async(function* (this: UserStore, data: object) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    try {
      yield* _await(api.sendEmail(rootStore.authStore.token, data));
    } catch (error) {
      console.warn('[DEBUG] error sending email', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });
}
