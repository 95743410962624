export default {
  meta: {},
  appearances: {
    default: {
      mapping: {
        paddingHorizontal: 20,
        textMarginHorizontal: 0,
        textFontFamily: 'SourceSansPro_400Regular',
      },
      variantGroups: {
        status: {
          basic: {
            borderColor: 'gray-04',
            backgroundColor: 'brand-field-background',
            textColor: 'text-primary',
            placeholderColor: 'text-placeholder',
            state: {
              focused: {
                borderColor: 'transparent',
                backgroundColor: 'brand-field-focused-background',
              },
              disabled: {
                borderColor: 'transparent',
                backgroundColor: 'brand-field-background',
                textColor: 'white',
              },
            },
          },
          primary: {
            borderColor: 'gray-02',
            backgroundColor: 'brand-field-background',
            textColor: 'text-primary',
            placeholderColor: 'text-placeholder',
            state: {
              focused: {
                borderColor: 'transparent',
                backgroundColor: 'brand-field-focused-background',
              },
              hover: {
                borderColor: 'gray-02',
                backgroundColor: 'input-hover',
              },
              disabled: {
                borderColor: 'transparent',
                backgroundColor: 'brand-field-background',
                textColor: 'white',
              },
            },
          },
        },
        size: {
          large: {
            minHeight: 48,
            borderRadius: 2,
            borderWidth: 1,
            paddingVertical: 8,
            textFontSize: 16,
          },
        },
      },
    },
  },
};
