import { useStyleSheet, StyleService } from '@ui-kitten/components';
import React from 'react';
import { View, TouchableOpacity, Platform, Linking } from 'react-native';

import { Props } from '../../types/PlayStore';
import AppStoreIcon from '../Common/Icons/AppStore';
import GooglePlayIcon from '../Common/Icons/GooglePlay';

const PlayStore: React.FC<Props> = ({ device }) => {
  const styles = useStyleSheet(themedStyles);
  const androidUrl =
    'https://play.google.com/store/apps/details?id=com.ugami.app&hl=es_VE&gl=US';
  const appleUrl =
    'https://apps.apple.com/us/app/ugami-debit-card-for-gamers/id1589150330';
  const pressStore = (name) => {
    switch (Platform.OS) {
      case 'web':
        window.open(name === 'android' ? androidUrl : appleUrl, '_blank');
        break;
      case 'android':
        Linking.openURL(name === 'android' ? androidUrl : appleUrl);
        break;
      case 'ios':
        Linking.openURL(name === 'android' ? androidUrl : appleUrl);
        break;
      default:
        window.open(name === 'android' ? androidUrl : appleUrl, '_blank');
        break;
    }
  };

  return (
    <View
      style={
        device === 'mobile'
          ? styles.playStoreBoxMobile
          : styles.playStoreBoxDesktop
      }
    >
      <TouchableOpacity onPress={() => pressStore('ios')}>
        <AppStoreIcon
          style={
            device === 'mobile'
              ? styles.AppStoreIconMobile
              : styles.AppStoreIconDesktop
          }
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => pressStore('android')}>
        <GooglePlayIcon
          style={
            device === 'mobile'
              ? styles.GooglePlayIconMobile
              : styles.GooglePlayIconDesktop
          }
        />
      </TouchableOpacity>
    </View>
  );
};

const themedStyles = StyleService.create({
  playStoreBoxDesktop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 425,
    marginTop: 30,
  },
  playStoreBoxMobile: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    width: 290,
  },
  GooglePlayIconDesktop: {
    width: 204,
  },
  AppStoreIconDesktop: {
    width: 180,
  },
  GooglePlayIconMobile: {
    width: 144,
  },
  AppStoreIconMobile: {
    width: 130,
  },
});

export default PlayStore;
