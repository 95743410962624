import React from 'react';

import QuestionMark from '../../../assets/images/question-mark-filled.svg';
import SvgIcon from '../SvgIcon/index';

const QuestionMarkIcon = () => {
  return (
    <SvgIcon>
      <QuestionMark />
    </SvgIcon>
  );
};

export default QuestionMarkIcon;
