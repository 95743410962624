import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('ugami-app/PublicKey')
export default class PublicKey extends Model({
  keyId: prop<string>(''),
  publicKey: prop<string>(''),
  expirationDate: prop<number>(0),
}) {
  @modelAction
  update(data: ModelCreationData<PublicKey>) {
    Object.assign(this, data);
  }
}
