import { useNavigation } from '@react-navigation/native';
import {
  useStyleSheet,
  Text,
  StyleService,
  Button,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ErrorMessage from '../../components/Common/ErrorMessage';
import LabeledInput from '../../components/Common/LabeledInput';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import SecondaryContainerView from '../../components/Common/SecondaryContainerView';
import { useStore } from '../../stores';
import { Props } from '../../types/Object';
import { FormData } from '../../types/UpdateEmail';
import { getErrors } from '../../utils/errors';
import regexEmail from '../../utils/regexEmail';

const UpdateEmail: React.FC<Props> = (_props) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { authStore, userStore, showToastMessage } = useStore();
  const user = authStore.user;
  const [email, setEmail] = useState(user?.email || '');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    register('email', {
      required: 'email is required',
      pattern: {
        value: regexEmail,
        message: 'invalid email',
      },
    });
  }, [register, user, setValue]);

  useEffect(() => {
    if (regexEmail.test(email)) {
      clearErrors('email');
    }

    setValue('email', email.trim());
  }, [email]);

  const onSubmit = useCallback(
    async (data) => {
      if (loading) {
        return;
      }
      setLoading(true);
      setErrorMessage('');
      const result = await userStore.updateMe(data);
      setLoading(false);

      if (!result.ok) {
        const error = getErrors(result.errors);
        if (
          error.includes('email already exists') ||
          error.includes('user with this email already exists')
        ) {
          setError('email', { message: 'email already exists' });
        } else {
          setErrorMessage(error);
        }
      } else {
        showToastMessage(true, {
          message: 'Email successfully updated',
          success: true,
        });
        navigation.goBack();
      }
    },
    [loading, user],
  );

  const onClose = () => {
    navigation.goBack();
  };

  const onSubmitWrapped = handleSubmit(onSubmit);

  const disabled =
    loading ||
    email.trim().toLowerCase() === user?.email.toLowerCase() ||
    email.trim() === '';

  return (
    <SecondaryContainerView
      title={t<string>('sign_up.update_email.title')}
      titleStyle={styles.titleStyle}
      isChildStack={false}
      headerRightButtonIcon="close"
      headerRightButtonOnPress={onClose}
      scrollEnabled
    >
      <ErrorMessage errorMessage={errorMessage} />
      <Text style={styles.text}>
        {t<string>('sign_up.update_email.description')}
      </Text>
      <LabeledInput
        label="Email"
        placeholder="gamer@website.com"
        keyboardType="email-address"
        autoCapitalize="none"
        autoCorrect={false}
        value={email}
        onChangeText={setEmail}
        onSubmitEditing={disabled ? undefined : onSubmitWrapped}
        error={errors.email}
        testID="ChangeEmailInput"
      />
      <Button
        size="large"
        style={styles.button}
        onPress={onSubmitWrapped}
        accessoryLeft={loading ? LoadingIndicator : undefined}
        disabled={disabled}
        testID="SaveChangesButton"
      >
        {t<string>('sign_up.update_email.save_changes_button')}
      </Button>
    </SecondaryContainerView>
  );
};

const themedStyles = StyleService.create({
  button: {
    alignSelf: 'stretch',
    marginTop: 36,
    marginBottom: 16,
  },
  text: {
    fontSize: 14,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
    marginTop: 24,
  },
  titleStyle: {
    paddingBottom: 0,
  },
});

export default observer(UpdateEmail);
