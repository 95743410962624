import {
  useStyleSheet,
  StyleService,
  Popover,
  Text,
} from '@ui-kitten/components';
import React, { useCallback } from 'react';
import {
  TouchableOpacity,
  FlatList,
  Platform,
  ImageBackground,
} from 'react-native';

import { Props } from '../../types/MenuPopup';

const MenuPopup: React.FC<Props> = (props) => {
  const { data, onHide, PopupTestId, ...rest } = props;
  const styles = useStyleSheet(themedStyles);

  const renderMenuItem = useCallback(
    ({ item }) => (
      <TouchableOpacity
        onPress={() => {
          onHide();
          item.onPress();
        }}
        testID={`MenuItem${item.title}`}
      >
        <Text style={styles.menuItemText}>{item.title}</Text>
      </TouchableOpacity>
    ),
    [],
  );

  return (
    <Popover
      {...rest}
      onBackdropPress={onHide}
      style={styles.popOver}
      testID={PopupTestId}
    >
      <ImageBackground
        style={{
          minHeight: 125,
          marginTop: Platform.OS !== 'ios' ? 20 : 0,
        }}
        resizeMode="stretch"
        source={require('../../assets/images/pop-up-box.png')}
      >
        <FlatList
          style={styles.container}
          keyExtractor={(_, index) => `${index}`}
          data={data || []}
          renderItem={renderMenuItem}
          bounces={false}
        />
      </ImageBackground>
    </Popover>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'transparent',
    maxHeight: 400,
  },
  menuItemText: {
    paddingVertical: 8,
    paddingLeft: 12,
    paddingRight: 24,
    fontSize: 16,
    fontFamily: 'SourceSansPro_600SemiBold',
    color: 'text-primary',
  },
  popOver: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    padding: 0,
  },
});

export default MenuPopup;
