export enum StatusEnum {
  BEGINNER = 'Beginner',
  APPRENTICE = 'Apprentice',
  FREELANCER = 'Freelancer',
  JOURNEYMAN = 'Journeyman',
  ADVENTURER = 'Adventurer',
  VETERAN = 'Veteran',
  LORD = 'Lord',
  MASTER = 'Master',
  GRANDMASTER = 'Grandmaster',
  CARDLORD = 'Cardlord',
  GAMING_GOD = 'Gaming God',
  GAMING_GOD_PRIME = 'Gaming God:Prime',
}

class _Status {
  constructor() {
    this.list = this.keys.map((key) => ({
      key,
      value: key,
    }));
  }

  list: { key: StatusEnum; value: string }[];

  keys = [
    StatusEnum.BEGINNER,
    StatusEnum.APPRENTICE,
    StatusEnum.FREELANCER,
    StatusEnum.JOURNEYMAN,
    StatusEnum.ADVENTURER,
    StatusEnum.VETERAN,
    StatusEnum.LORD,
    StatusEnum.MASTER,
    StatusEnum.GRANDMASTER,
    StatusEnum.CARDLORD,
    StatusEnum.GAMING_GOD,
    StatusEnum.GAMING_GOD_PRIME,
  ];

  colorMapping = {
    [StatusEnum.BEGINNER]: 'badges-beginner-grey',
    [StatusEnum.APPRENTICE]: 'badges-apprentice-white',
    [StatusEnum.FREELANCER]: 'badges-freelancer-green',
    [StatusEnum.JOURNEYMAN]: 'badges-journeyman-cyan',
    [StatusEnum.ADVENTURER]: 'badges-adventurer-blue',
    [StatusEnum.VETERAN]: 'badges-veteran-violet',
    [StatusEnum.LORD]: 'badges-lord-pink',
    [StatusEnum.MASTER]: 'badges-master-yellow',
    [StatusEnum.GRANDMASTER]: 'badges-grandmaster-orange',
    [StatusEnum.CARDLORD]: 'badges-cardlord-red',
    [StatusEnum.GAMING_GOD]: 'badges-cardlord-red',
    [StatusEnum.GAMING_GOD_PRIME]: 'badges-cardlord-red',
  };

  badgeHeightMapping = {
    [StatusEnum.BEGINNER]: 58,
    [StatusEnum.APPRENTICE]: 174,
    [StatusEnum.FREELANCER]: 177,
    [StatusEnum.JOURNEYMAN]: 174,
    [StatusEnum.ADVENTURER]: 182,
    [StatusEnum.VETERAN]: 212,
    [StatusEnum.LORD]: 243,
    [StatusEnum.MASTER]: 197,
    [StatusEnum.GRANDMASTER]: 223,
    [StatusEnum.CARDLORD]: 274,
    [StatusEnum.GAMING_GOD]: 274,
    [StatusEnum.GAMING_GOD_PRIME]: 274,
  };

  backgroundcolorMapping = {
    [StatusEnum.BEGINNER]: '#86868607',
    [StatusEnum.APPRENTICE]: '#FFFFFF07',
    [StatusEnum.FREELANCER]: '#0BFF8A07',
    [StatusEnum.JOURNEYMAN]: '#04F7FF07',
    [StatusEnum.ADVENTURER]: '#2465F007',
    [StatusEnum.VETERAN]: '#7B61FF07',
    [StatusEnum.LORD]: '#FF36D307',
    [StatusEnum.MASTER]: '#FFE56707',
    [StatusEnum.GRANDMASTER]: '#F67C3307',
    [StatusEnum.CARDLORD]: '#FF1E3807',
    [StatusEnum.GAMING_GOD]: '#FF1E3807',
    [StatusEnum.GAMING_GOD_PRIME]: '#FF1E3807',
  };

  getStatusColor = (status?: StatusEnum): string => {
    return this.colorMapping[status ? status : StatusEnum.BEGINNER];
  };

  getBadgeHeight = (status?: StatusEnum): number => {
    return this.badgeHeightMapping[status ? status : StatusEnum.BEGINNER];
  };

  getBackgroundColor = (status?: StatusEnum): string => {
    return this.backgroundcolorMapping[status ? status : StatusEnum.BEGINNER];
  };
}

export const Status = new _Status();
