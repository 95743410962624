import * as Localization from 'expo-localization';
import { isNull, cloneDeep } from 'lodash';
import { observable } from 'mobx';
import {
  applySnapshot,
  getSnapshot,
  Model,
  model,
  modelFlow,
  _async,
  _await,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';
import { Share } from 'react-native';
import appsFlyer from 'react-native-appsflyer';

import config from '../config';
import LinkingCode from '../models/LinkingCode';
import { StorageService } from '../services/storage/storageService';
import { trackWithProperties } from '../utils/analytics';
import AuthStore from './AuthStore';
import LanguageStore from './LanguageStore';
import PaymentStore from './PaymentStore';
import StatusStore from './StatusStore';
import TransactionsStore from './TransactionsStore';
import UgamiCardStore from './UgamiCardStore';
import UserStore from './UserStore';

@model('ugami-app/Store')
export default class Store extends Model({
  authStore: prop<AuthStore>(),
  paymentStore: prop<PaymentStore>(),
  statusStore: prop<StatusStore>(),
  transactionsStore: prop<TransactionsStore>(),
  ugamiCardStore: prop<UgamiCardStore>(),
  userStore: prop<UserStore>(),
  isOnboardingDone: prop<boolean>(false),
  languageStore: prop<LanguageStore>(),

  // For post registration
  isSignUpComplete: prop<boolean>(false),
  currentLang: prop<string>('en'),

  // For changing stack after sign in/ sign up
  isSignInComplete: prop<boolean>(false),
  firstTimeNavigation: prop<boolean>(false),
  linkingCode: prop<LinkingCode | null>(null),
  referrerCode: prop<string>(''),
  deepLink: prop<string>(''),
  selectedStack: prop<string>(''),
  hasBiometricDetails: prop<boolean>(false),
  isBiometricsEnabled: prop<boolean>(false),
  isSocialSignIn: prop<boolean>(false),
  isNewlyOpen: prop<boolean>(false),
  showToast: prop<boolean>(false),
  toastData: prop<{
    message: string;
    success?: boolean;
    error?: boolean;
    duration?: number;
  } | null>(null),
  appsflyerId: prop<string>(''),
}) {
  storageService!: StorageService;
  blacklist = ['cardApplicationStore'];

  @observable
  loading = false;

  @observable
  loaded = false;

  @modelFlow
  setIsOnboardingDone = _async(function* (this: Store) {
    this.isOnboardingDone = true;
  });

  @modelAction
  setIsSignUpComplete = (isSignUpComplete: boolean) => {
    this.isSignUpComplete = isSignUpComplete;
  };

  @modelAction
  setFirstTimeNavigation = (firstTimeNavigation: boolean) => {
    this.firstTimeNavigation = firstTimeNavigation;
  };

  @modelAction
  setIsSignInComplete = (isSignInComplete: boolean) => {
    if (!this.authStore || !this.authStore.token) {
      return;
    }
    this.isSignInComplete = isSignInComplete;
  };

  @modelAction
  setReferrerCode = (code: string) => {
    this.referrerCode = code;
  };

  @modelAction
  setDeepLink = (link: string) => {
    this.deepLink = link;
  };

  @modelAction
  setSelectedStack = (stack: string) => {
    this.selectedStack = stack;
  };

  @modelAction
  setLinkingCode = (linkingCode: ModelCreationData<LinkingCode> | null) => {
    if (linkingCode === null) {
      this.linkingCode = null;
    } else {
      this.linkingCode = new LinkingCode(linkingCode);
    }
  };

  @modelAction
  setHasBiometricDetails = (hasDetails: boolean) => {
    this.hasBiometricDetails = hasDetails;
  };

  @modelAction
  setIsBiometricEnabled = (isEnabled: boolean) => {
    this.isBiometricsEnabled = isEnabled;
  };

  @modelAction
  setIsSocialSignIn = (isSocialSignIn: boolean) => {
    this.isSocialSignIn = isSocialSignIn;
  };

  @modelAction
  setIsNewlyOpen = (isNewlyOpen: boolean) => {
    this.isNewlyOpen = isNewlyOpen;
  };

  @modelAction
  setAppsflyerId = (id: string) => {
    this.appsflyerId = id;
  };

  @modelAction
  showToastMessage = (
    toast: boolean,
    data: {
      message: string;
      success?: boolean;
      error?: boolean;
    } | null,
  ) => {
    this.showToast = toast;
    this.toastData = data;
  };

  @modelAction
  resetToastMessage = () => {
    this.showToast = false;
    this.toastData = null;
  };
  @modelAction
  setCurrentLang = (lang: string) => {
    this.currentLang = lang;
  };

  @modelFlow
  load = _async(function* (this: Store) {
    this.loading = true;

    let data: any | string | null;
    try {
      data = yield* _await(this.storageService.getItemAsync(config.storeKey));
    } catch (e) {
      console.warn('[DEBUG] fetching store failed', e);
      data = null;
    }

    if (data) {
      data = JSON.parse(data);
      this.blacklist.forEach((key) => {
        delete data[key];
      });

      try {
        applySnapshot(this, {
          ...getSnapshot(this),
          ...data,
          $modelId: this.$modelId,
        });
      } catch (error) {
        console.warn('[DEBUG] error while loading from stored data', error);
      }
      // Re-instantiate auth store.
      this.authStore = new AuthStore({});
      this.authStore.storageService = this.storageService;
    }

    try {
      const currentLanguage = yield* _await(
        this.storageService.getItemAsync(config.currentLangKey),
      );
      if (!isNull(currentLanguage)) {
        this.currentLang = currentLanguage;
      } else {
        this.currentLang = Localization.locale; //mobile language
      }
    } catch (e) {
      console.warn('[DEBUG] fetching current language', e);
    }

    // Load auth store separately.
    yield* _await(this.authStore.load());

    // Try to fetch me and force logout if token is invalid.
    const result = yield* _await(this.userStore.fetchMe());
    if (!result.ok) {
      console.warn('[DEBUG] failed to fetch me', result);
      if (result.errors.detail === 'Invalid token.') {
        yield* _await(this.authStore.logOut());
      }
    } else if (result.extra?.user) {
      const user = result.extra?.user;
      if (user.unitApplicationId) {
        yield* _await(this.authStore.logOut());
      } else {
        if (user.hasVerifiedEmail) {
          this.setIsSignInComplete(true);
          this.setIsSignUpComplete(false);
        } else {
          this.setIsSignUpComplete(true);
        }
      }
    }

    this.isNewlyOpen = true;
    this.loading = false;
    this.loaded = true;
  });

  @modelFlow
  fetchInitialData = _async(function* (this: Store) {
    this.loading = true;

    yield* _await(this.authStore.getAppFeedbackUrl());
    yield* _await(this.authStore.saveAppLaunchHistory());
    yield* _await(this.userStore.fetchAddresses());
    yield* _await(this.userStore.fetchPhoneNumbers());
    yield* _await(this.statusStore.fetchStatus());
    yield* _await(this.paymentStore.fetchCards());

    if (this.authStore.token) {
      this.authStore.updateUserProfile(this.authStore.user!);
    }
  });

  @modelFlow
  reset = _async(function* (this: Store) {
    yield* _await(this.authStore.reset());
    yield* _await(this.storageService.removeItemAsync(config.storeKey));
    this.isSignUpComplete = false;
    this.isSignInComplete = false;
    this.isSocialSignIn = false;
    this.isNewlyOpen = false;
    this.firstTimeNavigation = false;
    this.paymentStore = new PaymentStore({});
    this.statusStore = new StatusStore({});
    this.transactionsStore = new TransactionsStore({});
    this.ugamiCardStore = new UgamiCardStore({});
    this.userStore = new UserStore({});
    this.languageStore = new LanguageStore({});
    this.isSignInComplete = false;
    this.isSocialSignIn = false;
    this.referrerCode = '';
    this.isNewlyOpen = false;
    this.linkingCode = null;
    this.selectedStack = '';
  });

  save = async () => {
    const data = cloneDeep(getSnapshot(this));

    // Exclude token from AsyncStorage.
    data.authStore.token = '';
  };

  shareCode = () => {
    if (!this.linkingCode) {
      this.setLinkingCode({ referralCode: this.authStore.user?.referralCode });
    }

    // At this point, linking code is set properly using the above code.
    // So, ignore the tslint or use `!`.
    appsFlyer.generateInviteLink(
      {
        channel: 'mobile_share',
        campaign: 'Refer a Friend',
        referrerName: this.authStore.user?.fullName,
        customerID: this.authStore.user?.id.toString(),
        userParams: {
          deep_link_value: 'referred_signup',
          deep_link_sub1: this.linkingCode!.referralCode,
        },
      },
      (link) => {
        console.log('[APPSFLYER]', link);
        this.linkingCode!.setReferralLink(link);
        Share.share(...this.linkingCode!.share);
        trackWithProperties('Invite Link Generated', {
          link,
          user: this.authStore.user?.email,
        });
      },
      (err) => {
        console.log('[APPSFLYER][ERROR]', err);
      },
    );
  };
}
