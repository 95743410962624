import { orderBy } from 'lodash';
import { observable, computed } from 'mobx';
import {
  Model,
  model,
  prop,
  objectMap,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { TransactionType } from '../constants/TransactionType';
import Transaction from '../models/Transaction';

type Activity = {
  type: TransactionType;
  itemData: Transaction;
};
@model('ugami-app/TransactionsStore')
export default class TransactionsStore extends Model({
  transactions: prop(() => objectMap<Transaction>()),
  // TODO: implement the proper handling of point doubler
  isPointDoublerDone: prop<boolean>(false),
}) {
  @observable
  loading = false;

  @computed
  get postedTransactionList(): Activity[] {
    return orderBy(
      Array.from(this.transactions.values(), (transaction) => {
        return {
          type: TransactionType.TRANSACTIONS,
          itemData: transaction,
        };
      }),
      (t) => t.itemData.attributes.createdAt,
      ['desc'],
    );
  }

  @modelAction
  createOrUpdateTransaction(data: ModelCreationData<Transaction>) {
    const id = `${data.id}`;
    let transaction: Transaction;
    if (this.transactions.has(id)) {
      transaction = this.transactions.get(id)!;
    } else {
      transaction = new Transaction(data);
      this.transactions.set(id, transaction);
    }
    transaction.update(data);
    return transaction;
  }

  @modelAction
  setIsPointDoublerDone = (isPointDoublerDone: boolean) => {
    this.isPointDoublerDone = isPointDoublerDone;
  };

  getDebitTransaction = () => {
    return Array.from(this.transactions.values()).filter((t) => !t.isCredit);
  };
}
