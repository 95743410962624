import React from 'react';

import Camera from '../../../assets/images/camera-icon.svg';
import SvgIcon from '../SvgIcon/index';

const CameraIcon = () => {
  return (
    <SvgIcon>
      <Camera />
    </SvgIcon>
  );
};

export default CameraIcon;
