import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import UgamiCardApplicationDocumentAttributes from './UgamiCardApplicationDocumentAttributes';

@model('ugami-app/UgamiCardApplicationDocument')
export default class UgamiCardApplicationDocument extends Model({
  id: prop<string>(''),
  type: prop<string>(''),
  attributes: prop<UgamiCardApplicationDocumentAttributes>(),
  applicationId: prop<string>(''),
}) {
  @modelAction
  update(
    data: ModelCreationData<UgamiCardApplicationDocument>,
    applicationId: string,
  ) {
    Object.assign(this, data);
    this.applicationId = applicationId;
  }
}
