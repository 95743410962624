import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { CirclePaymentAccountStatus } from '../constants/CirclePaymentAccountStatus';
import RiskEvaluation from './RiskEvaluation';
import Verification from './Verification';

@model('ugami-app/Card')
export default class Card extends Model({
  id: prop<string>(),
  nickname: prop<string>(''),
  expMonth: prop<number>(),
  expYear: prop<number>(),
  fundingType: prop<string>(''),
  last4: prop<string>(''),
  network: prop<string>(''),
  status: prop<CirclePaymentAccountStatus>(CirclePaymentAccountStatus.PENDING),
  errorCode: prop<string>(''),
  verification: prop<Verification>(),
  riskEvaluation: prop<RiskEvaluation>(),
  isPrimary: prop<boolean>(false),
  isUgamiCard: prop<boolean>(false),
}) {
  @modelAction
  update(data: ModelCreationData<Card>) {
    Object.assign(this, data);
  }
}
