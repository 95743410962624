export default {
  meta: {},
  appearances: {
    default: {
      mapping: {},
      variantGroups: {
        status: {
          basic: {
            borderColor: 'app-background',
          },
          info: {
            borderColor: 'text-light-secondary',
          },
        },
        size: {
          small: {
            width: 18,
            height: 18,
            borderRadius: 9,
            borderWidth: 2.8,
          },
        },
      },
    },
  },
};
