import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
// import { useTranslation } from 'react-i18next';

import { useStore } from '../../stores';
import { MessageEventData } from '../../types/MessageEventData';
import { Props } from '../../types/Object';
import WebSocketConnection from './WebSocketConnection';

const Pusher: React.FC<Props> = () => {
  const connection = useRef<WebSocketConnection>();
  const { authStore, paymentStore, ugamiCardStore, showToastMessage } =
    useStore();

  // const { t } = useTranslation();
  const callback = async (data: MessageEventData) => {
    console.log('[DEBUG] receive data', data);
    if (data.type === 'payment.update') {
      paymentStore.createOrUpdatePayments(data.payload);
    } else if (data.type === 'card.update') {
      paymentStore.createOrUpdateCards(data.payload);
    } else if (data.type === 'deposit.account.update') {
      ugamiCardStore.updateDepositAccountBalance(data.payload);
    } else if (data.type === 'authorization.deposit.account.update') {
      ugamiCardStore.updateHoldingBalanceAfterSuccessfulTransaction(
        data.payload,
      );
      // if (data.payload.authorizationId) {
      //   authorizationStore.removeAuthorization(data.payload.authorizationId);
      // }
    } else if (data.type === 'email.confirmed') {
      showToastMessage(true, {
        message: 'Email successfully verified!',
        success: true,
      });
      authStore.updateHasVerifiedEmail();
    } else if (data.type === 'customer.created') {
      authStore.updateUnitCustomerId(data.payload.customerId);
      ugamiCardStore.approveApplication();
    } else if (data.type === 'card.status.changed') {
      ugamiCardStore.updateCardAttributes(data.payload);
    } else if (data.type === 'application.updated') {
      ugamiCardStore.awaitingReviewApplication(data.payload);
    }
  };

  // Connect to websocket
  useEffect(() => {
    if (authStore.token) {
      connection.current = new WebSocketConnection(authStore.token, callback);
    } else if (connection.current) {
      connection.current.close();
      connection.current = undefined;
    }
    return () => {
      if (connection.current) {
        connection.current.close();
        connection.current = undefined;
      }
    };
  }, [authStore.token]);

  return null;
};

export default observer(Pusher);
