import { useNavigation, RouteProp } from '@react-navigation/native';
import { useStyleSheet, StyleService, Text } from '@ui-kitten/components';
import { useAssets } from 'expo-asset';
import { AVPlaybackStatus, ResizeMode, Video } from 'expo-av';
import React, { useRef, useEffect } from 'react';
import {
  Image,
  View,
  ImageStyle,
  useWindowDimensions,
  Platform,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';

import SecondaryContainerView from '../../components/Common/SecondaryContainerView';
import MoreInfo from '../../components/UgamiCard/MoreInfo';
import {
  AuthStackNavigation,
  AuthStackParamList,
  PostRegistrationStackParamList,
} from '../../navigation';

type Props = {
  route: RouteProp<
    AuthStackParamList & PostRegistrationStackParamList,
    'LearnMore'
  >;
};

const BACKGROUND_IMAGE_HEIGHT = 3024;
const BACKGROUND_IMAGE_WIDTH = 1500;
const CARD_WIDTH = 281;
const CARD_HEIGHT = 177;

const LearMore: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation<AuthStackNavigation>();
  const animationRef = useRef<Video | null>(null);
  const [assets, _] = useAssets([
    require('../../assets/videos/Ug-Debit-Anim-1920px.m4v'),
  ]);

  const extraLargeScreen = useMediaQuery({
    minWidth: 1440,
  });
  const largeScreen = useMediaQuery({ maxWidth: 1439, minWidth: 1024 });
  const smallScreen = useMediaQuery({ maxWidth: 767 });

  const { isAccountCreated } = props.route.params;

  const cardMargin = Platform.OS === 'web' ? 100 : 48;
  const fullWidth = useWindowDimensions().width;
  const smallScreenWidth = fullWidth - cardMargin;
  const width =
    Platform.OS !== 'web' || smallScreen
      ? smallScreenWidth
      : extraLargeScreen
      ? 472
      : largeScreen
      ? 380
      : 436;

  // calculate image height based on screen width
  const backgroundImageHeight =
    (fullWidth / BACKGROUND_IMAGE_WIDTH) * BACKGROUND_IMAGE_HEIGHT;
  const cardHeight = (width / CARD_WIDTH) * CARD_HEIGHT;

  useEffect(() => {
    return () => {
      animationRef.current = null;
    };
  }, []);

  const onClose = () => {
    navigation.goBack();
  };

  const handleAnimationRef = async (animation: Video) => {
    if (animation && !animationRef.current && assets) {
      animationRef.current = animation;

      await animationRef.current.loadAsync(assets[0], {
        isLooping: false,
        isMuted: true,
        shouldPlay: true,
      });
    }
  };

  const onAnimationFinish = (status: AVPlaybackStatus) => {
    if (!animationRef || !animationRef.current || !status.isLoaded) {
      return;
    }
    if ('didJustFinish' in status && status.didJustFinish) {
      setTimeout(async () => {
        await animationRef.current?.replayAsync();
      }, 3000);
    }
  };

  return (
    <SecondaryContainerView
      headerRightButtonIcon="close"
      headerRightButtonOnPress={onClose}
      scrollStyle={styles.scrollContainer}
      title="Ugami Account"
      scrollEnabled
    >
      <Text style={styles.header}>Play Like Pros. Pay Like Pros.</Text>
      <View style={styles.cardImageContainer}>
        <Image
          style={[
            styles.cardImageBackground as ImageStyle,
            {
              height: backgroundImageHeight,
              width: fullWidth,
              top: -backgroundImageHeight * 0.35,
            },
          ]}
          source={require('../../assets/images/ugami-card-background-shadow.png')}
        />
        <View
          style={[
            styles.cardImage,
            {
              height: cardHeight,
              width,
              marginHorizontal: Platform.OS === 'web' ? 0 : 24,
              overflow: 'hidden',
            },
          ]}
        >
          {assets && (
            <Video
              ref={handleAnimationRef}
              onPlaybackStatusUpdate={onAnimationFinish}
              resizeMode={ResizeMode.COVER}
              style={{ flex: 1 }}
            />
          )}
        </View>
      </View>
      <MoreInfo isAccountCreated={isAccountCreated} isAuthStack />
    </SecondaryContainerView>
  );
};

const themedStyles = StyleService.create({
  buttonContainer: {
    flex: 1,
    marginHorizontal: 16,
  },
  header: {
    fontSize: 23,
    lineHeight: 32,
    fontFamily: 'Jura_700Bold',
    color: 'text-primary',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 12,
  },
  cardImageBackground: {
    position: 'absolute',
    opacity: 0.8,
  },
  cardImage: {
    borderRadius: 10,
    marginBottom: 4,
  },
  cardImageContainer: {
    marginTop: 16,
    marginBottom: 32,
  },
  scrollContainer: {
    paddingHorizontal: 0,
  },
  textSmall: {
    fontSize: 12,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'text-secondary',
    textAlign: 'center',
  },
  textMedium: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
  },
  textBig: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'text-primary',
  },
  subtitles: {
    textAlign: 'center',
  },
  subtitlesLight: {
    color: 'text-secondary',
  },
  row: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 16,
  },
  redText: {
    fontFamily: 'SourceSansPro_700Bold',
    color: 'red-03',
  },
  disclaimer: {
    fontSize: 14,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'text-light-secondary',
    marginTop: 24,
  },
});

export default LearMore;
