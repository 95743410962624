import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from '@react-navigation/native';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { ColorSchemeName } from 'react-native';
import appsFlyer from 'react-native-appsflyer';

import ActivityResponder from '../components/ActivityResponder';
import ConditionalWrapper from '../components/Common/ConditionalWrapper';
import Toast from '../components/Common/Toast';
import config from '../config';
import ConfirmEmail from '../containers/ConfirmEmail';
import NotFound from '../containers/NotFound';
import OnboardingDone from '../containers/OnboardingDone';
import UgamiCardApplication from '../containers/UgamiCardApplication';
import { useStore } from '../stores';
import { initAppsFlyer, trackWithProperties } from '../utils/analytics';
import LinkingConfiguration from './LinkingConfiguration';
import AuthStack from './stacks/AuthStack';
import PostRegistrationStack from './stacks/PostRegistrationStack';
export type {
  AuthStackNavigation,
  AuthStackParamList,
} from './stacks/AuthStack';
export type {
  PostRegistrationStackNavigation,
  PostRegistrationStackParamList,
} from './stacks/PostRegistrationStack';

export type AppStackParamList = {
  'Apply for Ugami Card': undefined;
  OnboardingDone: undefined;
  ConfirmEmail: undefined;
  'Not Found': undefined;
};
export type AppStackNavigation = StackNavigationProp<AppStackParamList>;

type Props = {
  colorScheme: ColorSchemeName;
};

const Stack = createStackNavigator<AppStackParamList>();

const AppStack: React.FC = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      animationEnabled: false,
      headerShown: false,
      presentation: 'modal',
    }}
  >
    <Stack.Screen
      name="Apply for Ugami Card"
      component={UgamiCardApplication}
    />
    <Stack.Screen name="ConfirmEmail" component={ConfirmEmail} />
    <Stack.Screen name="OnboardingDone" component={OnboardingDone} />
    <Stack.Screen name="Not Found" component={NotFound} />
  </Stack.Navigator>
);

const Stacks = {
  AppStack,
  AuthStack,
  PostRegistrationStack,
};

const AppContainer: React.FC<Props> = ({ colorScheme }) => {
  const store = useStore();
  const {
    isSignInComplete,
    isSignUpComplete,
    toastData,
    showToast,
    showToastMessage,
  } = store;

  const [key, setKey] = useState<keyof typeof Stacks>(
    isSignUpComplete
      ? 'PostRegistrationStack'
      : isSignInComplete
      ? 'AppStack'
      : 'AuthStack',
  );

  const Stack = Stacks[key];

  useEffect(() => {
    setKey(
      isSignUpComplete
        ? 'PostRegistrationStack'
        : isSignInComplete
        ? 'AppStack'
        : 'AuthStack',
    );
  }, [isSignInComplete, isSignUpComplete]);

  useEffect(() => {
    store.setSelectedStack(key);
  }, [Stack]);

  useEffect(() => {
    const appsFlyerUnifiedDeepLinkListener = appsFlyer.onDeepLink((res) => {
      if (res?.deepLinkStatus !== 'NOT_FOUND') {
        const deepLinkValue = res?.data?.deep_link_value;
        if (deepLinkValue === 'referred_signup') {
          const referrerCode = res?.data?.deep_link_sub1;
          store.setReferrerCode(referrerCode!);
          store.setIsNewlyOpen(false);
        }
        trackWithProperties('Deeplinked to App', { deepLinkValue });
        store.setDeepLink(deepLinkValue);
      }
    });
    initAppsFlyer();
    return () => {
      appsFlyerUnifiedDeepLinkListener();
    };
  }, []);

  return (
    <ConditionalWrapper
      condition={isSignInComplete && !isSignUpComplete}
      wrapper={(children: any) => (
        <ActivityResponder>{children}</ActivityResponder>
      )}
    >
      <NavigationContainer
        theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
        linking={{
          prefixes: config.deepLinkPrefixes,
          config: LinkingConfiguration[key],
        }}
      >
        <Stack />
        {showToast && (
          <Toast
            message={toastData?.message || ''}
            visible={showToast}
            setVisible={(_value: boolean) => showToastMessage(false, null)}
            toastDuration={toastData?.duration || 1000}
            {...toastData}
          />
        )}
      </NavigationContainer>
    </ConditionalWrapper>
  );
};

export default observer(AppContainer);
