const common = {
  buttons: {
    yes_up: 'SI',
    next_up: 'SIGUIENTE',
    cancel_up: 'CANCELAR',
    submit_up: 'ENVIAR',
  },
  tabs: {
    home: 'INICIO',
    friends: 'AMIGOS',
    store: 'TIENDA',
    ugipoints: 'UGIPOINTS',
  },
  spin: '¡Girar!',
  user_messages: {
    email_verified: '¡Email verificado satisfactoriamente!',
    email_updated: '¡Email actualizado satisfactoriamente!',
    email_exists: 'el email ya existe',
    user_email_exists: 'el usuario con este email ya existe',
    email_required: 'email requerido',
    email_invalid: 'email inválido',
  },
  messages: {
    invalid_email_password: 'Email o contraseña inválido.',
  },
  request_account_deletion: 'Solicitar eliminación de la cuenta',
  join_community: 'Únete a la comunidad',
  choose_account: 'Seleccionar cuenta',
  words: {
    or: 'o selecciona:',
  },
};

export { common };
