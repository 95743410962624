import { useStyleSheet, StyleService } from '@ui-kitten/components';
import { useAssets } from 'expo-asset';
import { AVPlaybackStatus, ResizeMode, Video } from 'expo-av';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  View,
  useWindowDimensions,
  ImageBackground,
  FlatList,
  Animated,
  Platform,
} from 'react-native';
import {
  FlingGestureHandler,
  Directions,
  State,
} from 'react-native-gesture-handler';
import { useMediaQuery } from 'react-responsive';

import { AccountType } from '../../constants/AccountType';
import {
  CARDS,
  SOURCE_CARD_CONTAINER_WIDTH,
  SOURCE_CARD_CONTAINER_HEIGHT,
  SOURCE_CARD_WIDTH,
  SOURCE_CARD_HEIGHT,
  CARD_CONTAINER_MARGIN,
} from '../../constants/Card';
import { Props } from '../../types/CardSelector';

const CardSelector: React.FC<Props> = ({ accountType, setAccountType }) => {
  const styles = useStyleSheet(themedStyles);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollXIndex = useRef(new Animated.Value(0)).current;
  const scrollXAnimated = useRef(new Animated.Value(0)).current;
  const timeOutRef = useRef<(NodeJS.Timeout | null)[]>([null, null, null]);
  const animationRef = useRef<(Video | null)[]>([null, null, null]);
  const [assets, _] = useAssets([
    require('../../assets/videos/Ug-Debit-Anim-1920px.m4v'),
  ]);
  const extraLargeScreen = useMediaQuery({
    minWidth: 1440,
  });
  const largeScreen = useMediaQuery({ maxWidth: 1439, minWidth: 1024 });
  const smallScreen = useMediaQuery({ maxWidth: 767 });

  const cardMargin = Platform.OS === 'web' ? 100 : 0;
  const smallScreenWidth = useWindowDimensions().width - cardMargin;
  const width =
    Platform.OS !== 'web' || smallScreen
      ? smallScreenWidth
      : extraLargeScreen
      ? 472
      : largeScreen
      ? 380
      : 436;
  const cardContainerWidth = width - CARD_CONTAINER_MARGIN; // minus horizontal margin
  const cardContainerHeight =
    cardContainerWidth /
    (SOURCE_CARD_CONTAINER_WIDTH / SOURCE_CARD_CONTAINER_HEIGHT);
  const cardWidth = cardContainerWidth - 16; // minus horizontal padding
  const cardHeight = cardWidth / (SOURCE_CARD_WIDTH / SOURCE_CARD_HEIGHT);

  useEffect(() => {
    return () => {
      animationRef.current = [null, null, null];
    };
  }, []);

  useEffect(() => {
    Animated.spring(scrollXAnimated, {
      toValue: scrollXIndex,
      useNativeDriver: false,
    }).start();
  }, []);

  useEffect(() => {
    const index = Object.values(AccountType).indexOf(accountType);
    setSelectedIndex(index);
    scrollXIndex.setValue(index);
  }, [accountType]);

  const setActiveIndex = React.useCallback((activeIndex: number) => {
    setAccountType(Object.values(AccountType)[activeIndex]);
  }, []);

  const renderItem = useCallback(
    ({ item, index }) => {
      const inputRange = [index - 1, index, index + 1];

      const translateX = scrollXAnimated.interpolate({
        inputRange,
        outputRange: [cardContainerWidth, 0, -cardContainerWidth],
      });
      const scale = scrollXAnimated.interpolate({
        inputRange,
        outputRange: [0.9, 1, 0.9],
      });

      const opacity = scrollXAnimated.interpolate({
        inputRange,
        outputRange: [0.5, 1, 0.5],
      });

      const handleAnimationRef = async (animation: Video) => {
        if (animation && !animationRef.current[index] && assets) {
          animationRef.current[index] = animation;
          await animationRef.current[index]!.loadAsync(assets[0], {
            isLooping: false,
            isMuted: true,
            shouldPlay: true,
          });
        }
      };

      const onAnimationFinish = (status: AVPlaybackStatus, index: number) => {
        if (!animationRef || !animationRef.current[index] || !status.isLoaded) {
          return;
        }
        if ('didJustFinish' in status && status.didJustFinish) {
          if (timeOutRef.current[index]) {
            clearTimeout(timeOutRef.current[index]!);
          }
          const timerRef = setTimeout(() => {
            animationRef.current[index]?.replayAsync();
          }, 3000);
          timeOutRef.current[index] = timerRef;
        }
      };

      return (
        <Animated.View
          style={{
            position: 'absolute',
            left: -cardWidth / 2,
            opacity,
            transform: [
              {
                translateX,
              },
              { scale },
            ],
          }}
        >
          {item.id !== '2' ? (
            <ImageBackground
              source={item.card}
              style={[
                {
                  width: cardWidth,
                  height: cardHeight,
                  marginTop: (cardContainerHeight - cardHeight) / 2 - 1,
                },
              ]}
              imageStyle={{
                borderRadius: 8,
              }}
            />
          ) : (
            <View
              style={{
                width: cardWidth,
                height: cardHeight,
                marginTop: (cardContainerHeight - cardHeight) / 2,
                overflow: 'hidden',
                borderRadius: 10,
              }}
            >
              {assets && (
                <Video
                  ref={handleAnimationRef}
                  // TODO add onPlaybackStatusUpdate functionality
                  // onPlaybackStatusUpdate={(status) => {
                  //   onAnimationFinish(status, index);
                  // }}
                  source={require('../../assets/videos/Ug-Debit-Anim-1920px.m4v')}
                  shouldPlay
                  isLooping
                  resizeMode={ResizeMode.COVER}
                  style={{ flex: 1 }}
                />
              )}
            </View>
          )}
        </Animated.View>
      );
    },
    [assets],
  );

  return (
    <View style={styles.container}>
      <FlingGestureHandler
        key="left"
        direction={Directions.LEFT}
        onHandlerStateChange={(ev) => {
          if (ev.nativeEvent.state === State.END) {
            if (selectedIndex === CARDS.length - 1) {
              return;
            }
            setActiveIndex(selectedIndex + 1);
          }
        }}
      >
        <FlingGestureHandler
          key="right"
          direction={Directions.RIGHT}
          onHandlerStateChange={(ev) => {
            if (ev.nativeEvent.state === State.END) {
              if (selectedIndex === 0) {
                return;
              }
              setActiveIndex(selectedIndex - 1);
            }
          }}
        >
          <View
            style={[styles.cardsContainer, { height: cardContainerHeight }]}
          >
            <FlatList
              style={styles.container}
              contentContainerStyle={styles.contentContainer}
              data={CARDS}
              horizontal
              inverted
              scrollEnabled={false}
              removeClippedSubviews={false}
              keyExtractor={(_item, index) => `${index}`}
              renderItem={renderItem}
            />
            <View
              style={[
                styles.cardContainer,
                {
                  width: cardContainerWidth,
                  height: cardContainerHeight,
                },
              ]}
            />
          </View>
        </FlingGestureHandler>
      </FlingGestureHandler>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    alignSelf: 'stretch',
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  cardsContainer: {
    justifyContent: 'center',
  },
  cardContainer: {
    borderWidth: 1,
    borderColor: 'white',
    borderRadius: 12,
    position: 'absolute',
    alignSelf: 'center',
  },
  heading: {
    fontSize: 18,
    fontFamily: 'SourceSansPro_600SemiBold',
    marginBottom: 4,
  },
  subheading: {
    fontSize: 16,
    fontFamily: 'SourceSansPro_400Regular',
  },
});

export default CardSelector;
