import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { ProvisioningStatus } from '../constants/UgamiCardProvisioningStatus';
import { UgamiCardStatus } from '../constants/UgamiCardStatus';
import { UgamiCardType } from '../constants/UgamiCardType';
import UgamiCardAttributes from './UgamiCardAttributes';
import UgamiCardRelationships from './UgamiCardRelationships';

@model('ugami-app/UgamiCard')
export default class UgamiCard extends Model({
  id: prop<string>(''),
  attributes: prop<UgamiCardAttributes>(),
  relationships: prop<UgamiCardRelationships>(),
  type: prop<UgamiCardType>(),
  encPayload: prop<string | null>(null),
  isEncPayloadLoading: prop<boolean>(false),
  walletCode: prop<string | null>(null),
  addToWallet: prop<boolean>(false),
  provisioningStatus: prop<ProvisioningStatus | null>(null),
}) {
  @modelAction
  update(data: ModelCreationData<UgamiCard>) {
    Object.assign(this, data);
  }

  @modelAction
  setPayload(data: string) {
    this.encPayload = data;
  }

  @modelAction
  setPayloadLoadingState(state: boolean) {
    this.isEncPayloadLoading = state;
  }

  @modelAction
  updateAttributes(status: UgamiCardStatus) {
    if (this.attributes) {
      const attributes = {
        ...this.attributes,
        status,
      };
      Object.assign(this.attributes, attributes);
    }
  }
}
