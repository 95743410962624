import React from 'react';

import Info from '../../assets/images/info-filled.svg';
import SvgIcon from '../Common/SvgIcon';

const PasswordInfoButton = () => {
  return (
    <SvgIcon>
      <Info />
    </SvgIcon>
  );
};

export default PasswordInfoButton;
