const settings = {
  settings_header: 'Configuración',
  profile_view: {
    edit: 'Editar',
    account_name: 'Nombre de la cuenta',
    account_email: 'Email',
    gamertag: 'Gamertag',
    change_info: 'Cambiar info',
    change_info_form: {
      header: 'Cambiar info de cuenta',
      first_name: 'Nombre',
      last_name: 'Apellido',
      email: 'Email',
      tip: 'Tu Gamertag es tu nombre de usuario publico en Ugami. Sientete libre de elegir el que prefieras',
      save_changes_button: 'GUARDAR CAMBIOS',
    },
    change_avatar: {
      title: 'Cambiar avatar',
      save_avatar_button: 'GUARDAR AVATAR',
    },
    change_name: {
      question: '¿Necesitas cambiar tu nombre?',
      submit: 'Solicitar cambio de nombre',
    },
    submitting: 'Enviando...',
  },
  bug_report: {
    title: '¡Queremos escucharte!',
    text: `Tu feedback es importante para nosotros, apreciaríamos que te tomaras un momento para darnos tu opinión.`,
    submit_button: 'ENVIAR OPINIÓN',
  },
  payment_options: {
    title: 'Tarjetas de pago',
    payment_options: 'Opciones de pago',
    manage_payment_button: 'Administrar tarjetas de pago',
    manage_bank_accounts: 'Administrar cuentas bancarias',
    manage_bank_accounts_header: 'Cuentas bancarias',
    add_bank_account: 'Añadir cuenta bancaria',
    connect_using_plaid: 'Conectar usando Plaid',
    manage_recurring_deposit: 'Administrar depósito recurrente',
    recurring_deposit: {
      header: 'Depósito recurrente',
      enable_button: 'Habilitar depósito recurrente',
      info: 'Agregue fondos regularmente a tu cuenta de Ugami para seguir jugando.',
    },
    manage_direct_deposit: 'Administrar depósito directo',
    direct_deposit: {
      header: 'Depósitos directos',
      add_button: 'Añadir depósito directo',
      info: 'Conecte tu cuenta a tu proveedor de nómina para configurar depósitos directos cada vez que te paguen.',
    },
    atm_locator: 'Localizador de ATM',
    description: {
      text_1: 'Las cuentas de pago se utilizan para comprar artículos en la ',
      text_2: 'Tienda Ugami',
      text_3: ' o pagar tu saldo adeudado.',
    },
    add_payment_card_button: 'Añadir tarjeta de pago',
  },
  personal_info: {
    personal_info: 'Información personal',
    manage_addresses: 'Administrar direcciones',
    manage_phone_numbers: 'Administrar números telefónicos',
    add_new_address: 'Añadir nueva dirección',
    add_address_header: 'Añadir dirección',
    addresses_header: 'Direcciones',
    address_form: {
      delete_button: 'ELIMINAR DIRECCIÓN',
      add_button: 'AÑADIR DIRECCIÓN',
      address_nickname: 'Nombre de dirección',
      address_nickname_placeholder: 'p.ej. Dirección casa',
      address_1: 'Dirección 1',
      placeholder_1: 'Información de dirección primaria',
      address_2: 'Dirección 2',
      placeholder_2: 'Información de dirección secundaria',
      city: 'Ciudad',
      country: 'País',
      state: 'Estado',
      select_state: 'Selecciona un estado',
      zip_code: 'Código postal',
      is_primary: 'Esta es mi dirección de facturación principal',
      confirm_alert: {
        title: 'Confirmar eliminación',
        subtitle: '¿Estás seguro que deseas eliminar esta dirección?',
        cancel_button: 'Cancelar',
        ok: 'OK',
      },
      update_address: 'Actualizar dirección',
      update_primary_address: 'Actualizar dirección primaria',
    },
    keys: {
      update: 'ACTUALIZAR',
      primary: 'PRIMARIA',
      address: 'DIRECCIÓN',
      add_address: 'AÑADIR DIRECCIÓN',
    },
    phone_numbers: {
      add: 'Añadir nuevo número telefónico',
      header: 'Números telefónicos',
      update_form: {
        phone_numbers_header: 'Añadir número telefónico',
        update_phone_numbers_header: 'Actualizar número telefónico',
        phone_nickname: 'Nombre de número telefónico',
        phone_nickname_placeholder: 'p.ej. Teléfono primario',
        phone_number: 'Número telefónico',
        info: 'Este número telefónico esta vinculado a tu cuenta de Ugami',
        country: 'País',
        check: 'Este es mi número telefónico primario',
        add_button: 'AÑADIR NÚMERO TELEFÓNICO',
        update_primary_button: 'ACTUALIZAR NÚMERO TELEFÓNICO PRIMARIO',
        update_button: 'ACTUALIZAR NÚMERO TELEFÓNICO',
        delete_button: 'ELIMINAR NÚMERO TELEFÓNICO',
      },
    },
  },
  privacy_security: {
    header: 'Privacidad y seguridad',
    change_password: 'Cambiar contraseña',
    biometric_security: 'Seguridad Biométrica',
  },
  notifications: {
    header: 'Notificaciones',
    status: 'Recibir notificaciones de la app',
  },
  ugami: {
    report_bug: 'Reportar una falla',
    about: 'Acerca de Ugami',
    contact: 'Contacto',
    policies_terms: 'Políticas y terminos de servicios',
    help: 'Ayuda y preguntas frecuentes',
    account_deletion: 'Solicitar eliminación de la cuenta',
  },
  logout: 'Cerrar sesión',
};

export { settings };
