import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('ugami-app/Phone')
export default class Phone extends Model({
  id: prop<number>(),
  title: prop<string>(''),
  number: prop<string>(''),
  isPrimary: prop<boolean>(false),
  isVerified: prop<boolean>(false),
  isUnitPhoneNumber: prop<boolean>(false),
}) {
  @modelAction
  update(data: ModelCreationData<Phone>) {
    Object.assign(this, data);
  }
}
