import { isPast } from 'date-fns';
import { computed } from 'mobx';
import {
  model,
  modelAction,
  Model,
  prop,
  ModelCreationData,
} from 'mobx-keystone';
import { ShareContent, ShareOptions } from 'react-native';

@model('mantrah-app/LinkingCode')
export default class LinkingCode extends Model({
  referralCode: prop<string>(''),
  expiryDate: prop<number>(0),
  referralLink: prop<string>(''),
}) {
  @modelAction
  update(data: ModelCreationData<LinkingCode>) {
    Object.assign(this, data);
  }

  @modelAction
  setReferralLink(link: string) {
    this.referralLink = link;
  }

  @computed
  get isExpired(): boolean {
    return isPast(this.expiryDate);
  }

  @computed
  get share(): [ShareContent, ShareOptions] {
    const url = this.referralLink;
    const title = 'Check Out Ugami!';
    const message = `Hi,\n\nI just got a new debit card for gamers and I thought you should give it a try. It's called Ugami and you can earn points to redeem for cool stuff in their gaming store. Check it out here and let me know what you think!\n\n${url}`;

    return [
      {
        title,
        message,
      },
      {
        subject: title,
        dialogTitle: title,
      },
    ];
  }
}
