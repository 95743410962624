import i18next from 'i18next';
import { observable } from 'mobx';
import {
  model,
  Model,
  modelAction,
  modelFlow,
  _async,
  getRoot,
  _await,
} from 'mobx-keystone';
import { initReactI18next } from 'react-i18next';

import config from '../config';
import { Language } from '../constants/Language';
import { en } from '../constants/i18n/translations/en/en';
import { es } from '../constants/i18n/translations/es/es';
import Store from './Store';

@model('ugami-app/LanguageStore')
export default class LanguageStore extends Model({}) {
  @observable
  loading = false;

  @modelFlow
  updateAppLanguage = _async(function* (
    this: LanguageStore,
    language: Language,
  ) {
    const rootStore = getRoot<Store>(this);
    yield* _await(i18next.changeLanguage(language));
    yield* _await(
      rootStore.storageService.setItemAsync(config.currentLangKey, language),
    );
    rootStore.setCurrentLang(language);
  });

  @modelAction
  languageData = (lang?: string) => {
    const resources = { en, es };
    i18next.use(initReactI18next).init({
      resources,
      lng: lang,
      fallbackLng: 'en',
      react: {
        useSuspense: false,
      },
    });
  };
}
