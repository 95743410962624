import {
  useStyleSheet,
  StyleService,
  Text,
  Button,
  Spinner,
} from '@ui-kitten/components';
import * as ImagePicker from 'expo-image-picker';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  TouchableOpacity,
  Image,
  StyleProp,
  ImageStyle,
  useWindowDimensions,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import ContainerScrollView from '../../components/Common/ContainerScrollView';
import { BUTTON_HEIGHT } from '../../constants/ButtonHeight';
import { Props } from '../../types/AvatarSelector';
import { track } from '../../utils/analytics';
import avatars from '../../utils/avatars';
import CameraIcon from '../Common/Icons/CameraIcon';
import TooltipInfoVisible from '../TooltipInfoVisible/index';

const AvatarSelector: React.FC<Props> = ({
  onNext,
  photo,
  setPhoto,
  avatar,
  setAvatar,
  onSelectPhoto,
  buttonTitle = 'next',
  loading = false,
  isSignUp = false,
  selectedIndex,
}) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const [selectedAvatarId, setSelectedAvatarId] = useState<
    number | undefined
  >();
  const { t } = useTranslation();

  const width = useWindowDimensions().width;
  const size = Math.floor((width - 128) / 3);
  const containerPadding = (width - (size + 24) * 3 - 32) / 2;
  const tooltipInfoText = t<string>('sign_up.avatar_tooltip');

  const onOpenCameraRoll = useCallback(async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
      alert('Permission to access camera roll is required!');
      return;
    }
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });
    if (!result.cancelled) {
      onSelectPhoto(result as ImageInfo);
      setSelectedAvatarId(undefined);
      setAvatar(null);
    }
  }, []);

  const onSubmit = () => {
    track('Select Profile Image - Step 2 - Completed');
    onNext();
  };

  const LoadingIndicator = (_props: any) => (
    <Spinner status="basic" size="small" />
  );

  const setLocalAvatar = (item: any, index: number) => {
    setAvatar(item);
    setSelectedAvatarId(index);
    setPhoto(null);
  };

  const renderItem = useCallback(
    (item, index) => (
      <TouchableOpacity
        key={index}
        onPress={() => setLocalAvatar(item, index)}
        testID={`SignUpAvatar${index}Button`}
      >
        <Image
          style={
            [
              styles.avatar,
              selectedAvatarId === index && styles.avatarSelected,
              {
                width: size,
                height: size,
                borderRadius: size / 2,
                maxHeight: 95,
                maxWidth: 95,
              },
            ] as StyleProp<ImageStyle>
          }
          source={item.image}
        />
      </TouchableOpacity>
    ),
    [selectedAvatarId, setLocalAvatar],
  );

  let uri: string = '';
  if (photo) {
    uri = photo?.uri || photo?.photo.thumbnail || photo?.photo.fullSize;
  } else {
    uri = '';
  }

  const noImageSelected = uri === '' && avatar === null;
  const disabled = noImageSelected || loading;

  if (selectedIndex !== 1) {
    return <View />;
  }

  return (
    <>
      <ContainerScrollView>
        <View style={styles.row}>
          <Text style={styles.title}>{t<string>('sign_up.create_avatar')}</Text>
          <TooltipInfoVisible text={tooltipInfoText} />
        </View>
        <View
          style={[styles.formContainer, { paddingBottom: BUTTON_HEIGHT / 2 }]}
        >
          <View style={styles.row}>
            <TouchableOpacity
              style={!noImageSelected ? undefined : styles.imagePicker}
              onPress={onOpenCameraRoll}
            >
              {!noImageSelected ? (
                avatar ? (
                  <Image
                    style={styles.imagePicker as StyleProp<ImageStyle>}
                    source={avatar.image}
                  />
                ) : (
                  <Image
                    style={styles.imagePicker as StyleProp<ImageStyle>}
                    source={{ uri }}
                  />
                )
              ) : (
                <CameraIcon />
              )}
            </TouchableOpacity>
            <View>
              <TouchableOpacity onPress={onOpenCameraRoll}>
                <Text style={styles.redText}>
                  {t<string>('sign_up.upload_avatar')}
                </Text>
              </TouchableOpacity>
              <Text style={styles.normal}>
                {t<string>('sign_up.select_one')}
              </Text>
            </View>
          </View>
          <View
            style={[styles.avatars, { paddingHorizontal: containerPadding }]}
          >
            {avatars.map((a, index) => renderItem(a, index))}
          </View>
        </View>
      </ContainerScrollView>
      <View
        style={[
          styles.footer,
          {
            height: BUTTON_HEIGHT - insets.bottom,
            bottom: insets.bottom,
          },
        ]}
      >
        <LinearGradient
          colors={['rgba(0,0,0,0)', 'rgba(0,0,0,0.75)']}
          style={[styles.gradient, { height: BUTTON_HEIGHT }]}
        />
        <Button
          size="large"
          style={[styles.save, disabled && styles.nextDisabled]}
          accessoryLeft={loading ? LoadingIndicator : undefined}
          onPress={disabled ? () => {} : isSignUp ? onSubmit : onNext}
          testID="SignUpAvatarSelectorButton"
        >
          {buttonTitle.toUpperCase()}
        </Button>
      </View>
    </>
  );
};

const themedStyles = StyleService.create({
  avatars: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 60,
  },
  formContainer: {
    flex: 1,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  title: {
    fontSize: 24,
    fontFamily: 'Jura_600SemiBold',
    alignSelf: 'flex-start',
    paddingTop: 8,
    paddingBottom: 16,
    paddingHorizontal: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  imagePicker: {
    width: 124,
    height: 124,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 62,
    borderWidth: 1,
    borderColor: 'gray-02',
    backgroundColor: 'brand-field-background',
    marginRight: 24,
    marginLeft: 12,
  },
  normal: {
    fontSize: 14,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
  },
  redText: {
    fontSize: 14,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_700Bold',
    color: 'red-03',
  },
  avatar: {
    borderWidth: 1,
    borderColor: 'gray-02',
    marginHorizontal: 12,
    marginBottom: 24,
  },
  avatarSelected: {
    borderWidth: 2,
    borderColor: 'blue-gray-01',
  },
  next: {
    height: 40,
    paddingHorizontal: 60,
    alignSelf: 'flex-end',
    marginRight: 16,
    zIndex: 1,
  },
  save: {
    marginTop: 20,
    height: 40,
    alignSelf: 'stretch',
    marginHorizontal: 16,
    zIndex: 1,
  },
  nextDisabled: {
    backgroundColor: 'disabled-primary-button',
  },
  footer: {
    width: '100%',
    position: 'absolute',
  },
  gradient: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    zIndex: 0,
  },
  tooltip: {
    maxWidth: 240,
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    lineHeight: 24,
    color: 'text-dark-primary',
  },
});

export default AvatarSelector;
