import {
  useTheme,
  useStyleSheet,
  StyleService,
  Button,
  Icon,
  Spinner,
} from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from '../../types/NextButton';

const NextButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const { onNextPressed, disabled = false, loading = false, testID } = props;
  const theme = useTheme();

  const ArrowRight = () => (
    <Icon
      name="arrow-forward"
      width={20}
      height={20}
      fill={theme['app-background']}
    />
  );

  const LoadingIndicator = () => <Spinner status="basic" size="small" />;

  return (
    <>
      <Button
        size="large"
        style={styles.nextButton}
        onPress={onNextPressed}
        accessoryRight={ArrowRight}
        accessoryLeft={loading ? LoadingIndicator : undefined}
        disabled={disabled}
        testID={testID ?? 'NextButton'}
      >
        {t<string>('common.buttons.next_up')}
      </Button>
    </>
  );
};

const themedStyles = StyleService.create({
  buttonText: {
    color: 'text-dark-primary',
    textTransform: 'uppercase',
    fontFamily: 'SourceSansPro_600SemiBold',
    paddingRight: 16,
  },
  buttonIcon: {
    color: 'text-dark-primary',
    height: 20,
    width: 20,
  },
  nextButton: {
    marginTop: 16,
    paddingHorizontal: 36,
  },
});

export default NextButton;
