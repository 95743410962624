import { default as snakeCaseKeys } from 'snakecase-keys';

import config from '../../config';
import Address from '../../models/Address';
import Device from '../../models/Device';
import Phone from '../../models/Phone';
import User from '../../models/User';
import { callApiWithToken, prepareFileForUpload } from './base';

export const addAddress = async (token: string, data: Partial<Address>) =>
  callApiWithToken(
    config.urls.addresses,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const addPhoneNumber = async (
  token: string,
  data: {
    number: string;
    title: string;
    isPrimary: boolean;
    isVerified: boolean;
    isUnitPhoneNumber: boolean;
    token: string;
  },
) =>
  callApiWithToken(
    config.urls.phoneNumbers,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchMe = async (token: string) =>
  callApiWithToken(`${config.urls.users}me/`, token, 'GET');

export const fetchAddresses = async (token: string) =>
  callApiWithToken(config.urls.addresses, token, 'GET');

export const fetchPhoneNumbers = async (token: string) =>
  callApiWithToken(config.urls.phoneNumbers, token, 'GET');

export const sendPhoneVerification = async (
  token: string,
  phoneNumber: string,
) =>
  callApiWithToken(
    `${config.urls.phoneNumbers}send-code/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ via: 'sms', number: phoneNumber })),
  );

export const verifyPhoneNumber = async (
  token: string,
  verificationCode: string,
  phoneNumber: string,
) =>
  callApiWithToken(
    `${config.urls.phoneNumbers}verify/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(
      snakeCaseKeys({ token: verificationCode, number: phoneNumber }),
    ),
  );

export const resendEmailVerification = async (token: string) =>
  callApiWithToken(
    `${config.urls.users}resend-email-verification/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
  );

export const removeTemporaryEmail = async (token: string) =>
  callApiWithToken(
    `${config.urls.users}remove-temporary-email/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
  );

export const fetchFriends = async (token: string) =>
  callApiWithToken(config.urls.friends, token, 'GET');

export const submitChangeName = async (token: string) =>
  callApiWithToken(`${config.urls.users}me/name-change/`, token, 'POST');

export const updateMe = async (token: string, data: Partial<User>) =>
  callApiWithToken(
    `${config.urls.users}me/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateMePhoto = async (token: string, photo: any) => {
  const data = new FormData();
  const file = await prepareFileForUpload(photo);
  data.append('profile_image', file);
  return callApiWithToken(`${config.urls.users}me/`, token, 'PATCH', {}, data);
};

export const updateMePhotoUsingDefaultAvatar = async (
  token: string,
  avatarName: string,
) =>
  callApiWithToken(
    `${config.urls.users}me/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ avatarName })),
  );

export const updateAddress = async (
  token: string,
  id: number,
  data: Partial<Address>,
) =>
  callApiWithToken(
    `${config.urls.addresses}${id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const deleteAddress = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.addresses}${id}/`, token, 'DELETE', {
    'Content-Type': 'application/json',
  });

export const updatePhoneNumber = async (
  token: string,
  id: number,
  data: Partial<{
    number: string;
    title: string;
    isPrimary: boolean;
    isVerified: boolean;
    isUnitPhoneNumber: boolean;
    token: string;
  }>,
) =>
  callApiWithToken(
    `${config.urls.phoneNumbers}${id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const deletePhoneNumber = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.phoneNumbers}${id}/`, token, 'DELETE', {
    'Content-Type': 'application/json',
  });

export const registerDevice = async (token: string, data: Partial<Device>) =>
  callApiWithToken(
    config.urls.registerDevice,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const phoneNumberExists = async (token: string, data: Partial<Phone>) =>
  callApiWithToken(
    config.urls.phoneNumberExist,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const sendEmail = async (token: string, data: object) =>
  callApiWithToken(
    config.urls.sendEmail,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );
