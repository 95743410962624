const sign_in = {
  email: 'Email',
  password: 'Contraseña',
  password_placeholder: 'contraseñasupersegura',
  forgot_password: '¿Olvidaste tu contraseña?',
  sign_in: 'INICIAR SESIÓN',
  get_started: 'REGISTRARSE',
  ugami: '¿Quieres saber más acerca de Ugami?',
  learn_more: 'Ver más',
};

export { sign_in };
