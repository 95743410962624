import React, { useState, useRef, useCallback, useEffect } from 'react';
import { PanResponder, View } from 'react-native';

import { AUTO_LOGOUT_TIME } from '../../constants/AutoLogoutTime';
import { INACTIVITY_CHECK_INTERVAL_MS } from '../../constants/InactivityCheckIntervalMs';
import { useStore } from '../../stores';
import { Props } from '../../types/Object';

const ActivityResponder: React.FC<Props> = ({ children }) => {
  const lastInteraction = useRef(new Date());
  const [timeWentInactive, setTimeWentInactive] = useState<Date | null>(null);
  const inactivityTimer = useRef<any>(false);
  const waitForInactivity = useRef<number>(0);
  const store = useStore();
  const { authStore, isSignInComplete } = store;

  // useEffect(() => {
  //   return () => {
  //     clearInterval(inactivityTimer.current as number);
  //   };
  // }, []);

  useEffect(() => {
    if (!authStore.token || !authStore.user || !isSignInComplete) {
      return;
    }
    checkInactive();
  }, [isSignInComplete]);

  useEffect(() => {
    if (!authStore.token || !authStore.user || !isSignInComplete) {
      return;
    }

    waitForInactivity.current = AUTO_LOGOUT_TIME * 1000;
  }, [
    isSignInComplete,
    authStore.token,
    authStore.user,
    waitForInactivity.current,
  ]);

  const checkInactive = useCallback(() => {
    if (inactivityTimer.current) {
      return;
    }

    inactivityTimer.current = setInterval(() => {
      if (
        Math.abs(new Date().valueOf() - lastInteraction.current.valueOf()) >=
        waitForInactivity.current
      ) {
        setIsInactive();
      }
    }, INACTIVITY_CHECK_INTERVAL_MS);
  }, []);

  useEffect(() => {
    if (!authStore.token || !authStore.user || !isSignInComplete) {
      return;
    }
    checkInactive();
  }, [checkInactive]);

  const setIsActive = useCallback(() => {
    lastInteraction.current = new Date();
    if (timeWentInactive) {
      setTimeWentInactive(null);
    }

    if (!authStore.token || !authStore.user || !isSignInComplete) {
      return;
    }

    checkInactive();
  }, []);

  const setIsInactive = () => {
    setTimeWentInactive(new Date());
    clearInterval(inactivityTimer.current as number);
    inactivityTimer.current = false;
  };

  const handleMoveShouldSetPanResponder = useCallback(() => {
    setIsActive();
    return false;
  }, [setIsActive]);

  const handleStartShouldSetPanResponder = useCallback(() => {
    setIsActive();
    return false;
  }, [setIsActive]);

  const onStartShouldSetPanResponderCapture = useCallback(() => {
    setIsActive();
    return false;
  }, [setIsActive]);

  const [panResponder, _] = useState(
    PanResponder.create({
      onStartShouldSetPanResponder: handleStartShouldSetPanResponder,
      onMoveShouldSetPanResponder: handleMoveShouldSetPanResponder,
      onStartShouldSetPanResponderCapture,
      onMoveShouldSetPanResponderCapture: () => false,
      onPanResponderTerminationRequest: () => true,
      onShouldBlockNativeResponder: () => false,
    }),
  );

  return (
    <View style={{ flex: 1 }} {...panResponder.panHandlers}>
      {children}
    </View>
  );
};

export default ActivityResponder;
