const sign_up = {
  header_title: "Crea Tu Cuenta",
  account_information: "Información de la cuenta",
  email: "Email",
  first_name: "Nombre",
  last_name: "Apellido",
  gamertag: "Gamertag",
  gamertag_info:
    "Tu Gamertag es tu nombre de usuario publico en Ugami. Sientete libre de elegir el que prefieras",
  date_of_birth: "Fecha de Nacimiento",
  referrer_code: "Código de referido (Opcional)",
  gender: "Género",
  genders: ["Masculino", "Femenino", "Prefiero no decirlo"],
  gender_placeholder: "Selecciona tu identidad",
  password: "Contraseña",
  confirm_password: "Confirmar contraseña",
  password_strength: "Seguridad de la contraseña",
  password_details:
    "Tu contraseña debe tener al menos 8 caracteres. Una mayúscula, una minúscula, un número y un carácter especial",
  checking_box: {
    text_1:
      "Seleccionando esta casilla de verificación estas de acuerdo a nuestros ",
    terms_of_service: "Terminos de servicio",
    and: " y ",
    privacy_policy: "Politicas de privacidad",
    text_2: ". También confirmas que tienes 13 años o más",
  },
  next_button: "SIGUIENTE",

  create_avatar: "Crea tu Avatar",
  upload_avatar: "Carga tu Avatar",
  select_one: "o selecciona uno",
  avatar_tooltip:
    "Tu foto de perfil va a ser publica a todos los usuarios de la aplicación de Ugami.",
  what_do_you_play: "¿Que juegas?",
  mostly_play: "Lo que juegas principalmente",
  often_play: "Lo que juegas a menudo",
  sometimes_play: "Lo que juegas a veces",
  where_do_you_play: "¿Donde juegas?",
  others: "Otros",
  specify: "(Por favor especificar)",
  skip_button: "SALTAR",
  create_account_button: "CREAR CUENTA",
  verify_email: {
    title: "Verifica tu email",
    description: {
      text_1: "Se ha enviado un email a ",
      text_2:
        ". Por favor verifica la bandeja de entrada de tu email electrónico para continuar.",
    },
    check_email_button: "COMPROBAR TU EMAIL",
    close_button: "CERRAR",
    not_received: "¿No recibiste un email?",
    resend: "Reenviar el email",
  },
  update_email: {
    title: "Actualizar email",
    description:
      "Un link de verificación será enviado a este email. Por favor verifica tu bandeja de entrada despues de confirmar la actualización.",
    save_changes_button: "GUARDAR CAMBIOS",
  },
  account_created: {
    title: "Ya estas casi listo",
    second_title: "¡Estás listo!",
    message: {
      text_1: "Tu email ",
      text_2: "ha sido verificado satisfactoriamente.",
    },
    description:
      "Ahora que has creado tu cuenta es momento de seleccionar y solicitar un nivel de Tarjeta de Débito Ugami. Esta aplicación no afectará tu puntaje de crédito.",
    next_button: "SIGUIENTE",
    home_button: "IR A INICIO",
    more_info: "¿Necesitas más información?",
    learn_more_button: "Ver más",
  },
  accepted: {
    title: "¡Tu solicitud ha sido aprobada!",
    subtitle:
      "Aquí está tu tarjeta física. Actualmente se está enviando, y puede esperar que llegue proximamente de 5 a 7 días hábiles. ¡Sin embargo, tu tarjeta virtual ha sido activada y está lista para usar!",
    next_button: "SIGUIENTE",
  },
  submitted: {
    title: "¡Tu solicitud ha sido enviada!",
    subtitle: {
      text_1:
        "Revisaremos tu información y nos pondremos en contacto con usted pronto. Se ha enviado un email de confirmación a ",
      text_2:
        ". Ahora puede revisar el estado de tu solicitud en la pantalla de inicio.",
    },
    next_button: "SIGUIENTE",
    go_home_button: "IR A INICIO",
  },
  onboarding_done: {
    title: {
      text_1: "La tarjeta de débito #1 para Gamers",
      text_2: "Su solicitud está bajo revisión",
    },
    subtitle: {
      text_1: "Descarga la aplicación de Ugami",
      text_2: "Inicie sesión con tu correo email y contraseña",
      text_3: "Consulta el estado de tu solicitud",
    },
    footer: {
      text_1: "Contactar con ",
      text_2: "support@ugami.com ",
      text_3: "si tienes algún problema.",
    }
  },
};

export { sign_up };
