import Debit from '../assets/images/ugami-debit-card.png';

export const CARDS = [
  {
    id: '1',
    card: Debit,
  },
  {
    id: '2',
    card: Debit,
  },
];

export const SOURCE_CARD_CONTAINER_WIDTH = 260;
export const SOURCE_CARD_CONTAINER_HEIGHT = 168;
export const SOURCE_CARD_WIDTH = 281;
export const SOURCE_CARD_HEIGHT = 177;
export const CARD_CONTAINER_MARGIN = 116;
