const settings = {
  settings_header: 'Settings',
  profile_view: {
    edit: 'Edit',
    account_name: 'Account Name',
    account_email: 'Account Email',
    gamertag: 'Gamertag',
    change_info: 'Change Info',
    change_info_form: {
      header: 'Change Account Info',
      first_name: 'First Name',
      last_name: 'Last Name',
      email: 'Email',
      tip: 'Your Gamertag is your publicly visible username for Ugami. Feel free to choose whatever you like.',
      save_changes_button: 'SAVE CHANGES',
    },
    change_avatar: {
      title: 'Change Avatar',
      save_avatar_button: 'SAVE AVATAR',
    },
    change_name: {
      question: 'Need to change your name?',
      submit: 'Submit A Name Change',
    },
    submitting: 'Submitting...',
  },
  bug_report: {
    title: 'We Want To Hear From You!',
    text: `Your feedback is important to us, we'd appreciate you taking a moment to provide your thoughts.`,
    submit_button: 'SUBMIT FEEDBACK',
  },
  payment_options: {
    title: 'Payment Cards',
    payment_options: 'Payment Options',
    manage_payment_button: 'Manage Payment Cards',
    add_bank_account: 'Add Bank Account',
    connect_using_plaid: 'Connect Using Plaid',
    manage_bank_accounts: 'Manage Bank Accounts',
    manage_bank_accounts_header: 'Bank Accounts',
    manage_recurring_deposit: 'Manage Recurring Deposit',
    recurring_deposit: {
      header: 'Recurring Deposit',
      enable_button: 'Enable Recurring Deposit',
      info: 'Regularly add funds to your Ugami account to keep you playing.',
    },
    manage_direct_deposit: 'Manage Direct Deposit',
    direct_deposit: {
      header: 'Direct Deposits',
      add_button: 'Add Direct Deposit',
      info: 'Connect your account to your payroll provider to set up Direct Deposits every time you get paid.',
    },
    atm_locator: 'ATM Locator',
    description: {
      text_1: 'Payment accounts are used to buy things in the ',
      text_2: 'Ugami Store',
      text_3: ' or pay down your due balance.',
    },
    add_payment_card_button: 'Add Payment Card',
  },
  personal_info: {
    personal_info: 'Personal Info',
    manage_addresses: 'Manage Addresses',
    manage_phone_numbers: 'Manage Phone Numbers',
    addresses_header: 'Addresses',
    add_address_header: 'Add Address',
    add_new_address: 'Add New Address',
    address_form: {
      delete_button: 'DELETE ADDRESS',
      add_button: 'ADD ADDRESS',
      address_nickname: 'Address Nickname',
      address_nickname_placeholder: 'e.g. Home Address',
      address_1: 'Street Address Line 1',
      placeholder_1: 'Primary Address Information',
      address_2: 'Street Address Line 2',
      placeholder_2: 'Secondary Address Information',
      city: 'City',
      state: 'State',
      select_state: 'Select a State',
      zip_code: 'Zip Code',
      country: 'Country',
      is_primary: 'This Is My Primary Billing Address',
      confirm_alert: {
        title: 'Confirm Delete',
        subtitle: 'Are you sure you want to delete this address?',
        cancel_button: 'Cancel',
        ok: 'OK',
      },
      update_address: 'Update Address',
      update_primary_address: 'Update Primary Address',
    },
    keys: {
      update: 'UPDATE',
      primary: 'PRIMARY',
      address: 'ADDRESS',
      add_address: 'ADD ADDRESS',
    },
    phone_numbers: {
      add: 'Add New Phone Number',
      header: 'Phone Numbers',
      update_form: {
        phone_numbers_header: 'Add Phone Number',
        update_phone_numbers_header: 'Update Phone Number',
        phone_nickname: 'Phone Number Nickname',
        phone_nickname_placeholder: 'e.g. Primary Phone',
        phone_number: 'Phone Number',
        info: 'This phone number is connected to your ugami checking account',
        country: 'Country',
        check: 'This Is My Primary Phone Number',
        add_button: 'ADD PHONE NUMBER',
        update_button: 'UPDATE PHONE NUMBER',
        delete_button: 'DELETE PHONE NUMBER',
        update_primary_button: 'UPDATE PRIMARY PHONE NUMBER',
      },
    },
  },
  privacy_security: {
    header: 'Privacy & Security',
    change_password: 'Change Password',
    biometric_security: 'Biometric Security',
  },
  notifications: {
    header: 'Notifications',
    status: 'Receive App Notifications',
  },
  ugami: {
    report_bug: 'Report a Bug',
    about: 'About',
    contact: 'Contact Us',
    policies_terms: 'Policies & Terms Of Service',
    help: 'Help & FAQs',
    account_deletion: 'Request Account Deletion',
  },
  logout: 'Logout',
};

export { settings };
