import AsyncStorage from '@react-native-async-storage/async-storage';
// import * as SecureStore from 'expo-secure-store';

import { StorageService } from './storageService';

export class NativeStorageService extends StorageService {
  getItemAsync = (key: string) => {
    return AsyncStorage.getItem(key);
  };

  setItemAsync = (key: string, item: string) => {
    return AsyncStorage.setItem(key, item);
  };

  removeItemAsync = (key: string) => {
    return AsyncStorage.removeItem(key);
  };
}
