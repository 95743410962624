import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { CirclePaymentStatus } from '../constants/CirclePaymentStatus';
import RequiredAction from './RequiredAction';
import RiskEvaluation from './RiskEvaluation';
import Verification from './Verification';

@model('ugami-app/Payment')
export default class Payment extends Model({
  id: prop<string>(),
  status: prop<CirclePaymentStatus>(CirclePaymentStatus.PENDING),
  errorCode: prop<string>(''),
  riskEvaluation: prop<RiskEvaluation>(),
  verification: prop<Verification>(),
  requiredAction: prop<RequiredAction>(),
}) {
  @modelAction
  update(data: ModelCreationData<Payment>) {
    Object.assign(this, data);
  }
}
