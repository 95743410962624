import { light, dark } from '@eva-design/eva';

export default {
  light: {
    ...light,
    'text-primary': 'rgb(255, 255, 255)',
    'text-secondary': 'rgba(255, 255, 255, 0.5)',
    'text-placeholder': 'rgba(255, 255, 255, 0.15)',
  },
  dark: {
    ...dark,
    'app-background': '#050505',
    'apprentice-blue': '#38B7D0',
    'apple-pay-background': '#1D1E1E',
    'apple-pay-border': '#4C4D4D',
    'blue-01': '#04F7FF',
    'blue-gray-01': '#F8F9FA',
    'blue-gray-02': '#E9ECEF',
    'blue-gray-05': '#AEB5BC',
    'blue-gray-07': '#4A5056',
    'blue-gray-08': '#353A3F',
    'blue-gray-10': '#1C1E21',
    'badges-beginner-grey': '#868686',
    'badges-apprentice-white': '#FFFFFF',
    'badges-freelancer-green': '#0BFF8A',
    'badges-journeyman-cyan': '#04F7FF',
    'badges-adventurer-blue': '#2465F0',
    'badges-veteran-violet': '#7B61FF',
    'badges-lord-pink': '#FF36D3',
    'badges-master-yellow': '#FFE567',
    'badges-grandmaster-orange': '#F67C33',
    'badges-cardlord-red': '#FF1E38',
    'brand-field-background': 'rgba(28, 30, 33, 0.5)',
    'brand-field-focused-background': '#1C1E21',
    'bottom-navbar-background': '#161719',
    'button-disabled': 'rgba(174, 181, 188, 0.5)',
    'disabled-brand-field-background': 'rgba(28, 30, 33, 0.25)',
    'disabled-primary-button': '#5C5F62',
    'expanded-accordion-background': 'rgba(5, 5, 5, 0.5)',
    'focused-background': '#101010',
    'gray-01': '#979797',
    'gray-02': '#616161',
    'gray-03': '#2C2C2C',
    'gray-04': '#252525',
    'gray-05': 'rgba(28, 30, 33, 0.5)',
    'green-02': '#0BFF8A',
    'green-03': '#0FAB4E',
    'input-hover': 'rgba(28, 30, 33, 0.7)',
    'outline-button-border-hover': 'rgba(74, 80, 86, 0.7)',
    'outline-button-border-disabled': 'rgba(74, 80, 86, 0.5)',
    'orange-01': '#FF4C00',
    'purple-01': '#A008E8',
    'red-02': '#FF1E38',
    'red-03': '#FF364E',
    'selected-stroke': '#242424',
    'text-dark-secondary': 'rgba(16, 16, 16, 0.5)',
    'text-dark-primary': '#101010',
    'text-light-secondary': 'rgba(255, 255, 255, 0.5)',
    'text-primary': 'rgb(255, 255, 255)',
    'text-placeholder': 'rgba(255, 255, 255, 0.15)',
    'text-secondary': 'rgba(255, 255, 255, 0.5)',
    'orange-03': '#EF872C',
    'yellow-03': '#E0DE40',
    'yellow-04': '#ffc22b',
    'app-and-notifications-border-color': 'rgba(11, 255, 138, 0.25)',
    'payment-options-border-color': 'rgba(4, 247, 255, 0.25)',
    'personal-info-border-color': 'rgba(255, 184, 0, 0.25)',
    'privacy-security-border-color': 'rgba(255, 76, 0, 0.25)',
    'ugami-border-color': 'rgba(255, 30, 56, 0.25)',
    'black-5': 'rgba(0, 0, 0, 0.5)',
    'modal-card-background': '#1C1B1E',
  },
};
