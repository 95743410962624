import { common } from "./common";
import { sign_in } from "./sign_in";
import { sign_up } from "./sign_up";
import { not_found } from "./not_found";
import { card_application } from "./card_application";
import { settings } from "./settings";

const es = {
  translation: {
    common,
    sign_in,
    sign_up,
    not_found,
    card_application,
    settings,
  },
};
export { es };
